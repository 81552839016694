import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';
import { vehicleModifyRequest } from '../modifyRequest';

export  const CallAPI  = (data) => {
    let _data ={};
    _data.vehicle = vehicleModifyRequest(data.vehicle);
    _data.customerId = data.customerId;
    _data.vehicleId = data.vehicleId;

    return apiService.makeRequest({
        method: 'PUT',
        url:'vehicles',
        data: _data
    });
}

// SET_ADD_CUSTOMER
function* doInit(action) {
    yield put({type: type.SET_UPDATE_VEHICLE_LOADING, payload: true });
    try {
        let request = action.payload;
        let response = yield call(CallAPI, action.payload);
        yield put({type: type.SET_UPDATE_VEHICLE_LOADING, payload: false });

        if( response && response.success   === true ){
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: 'Vehicle details has been successfully updated'} });
            yield put({type: type.DO_GET_CUSTOMER_DETAILS, payload: { mobile: request.mobile }});
            yield put({type: type.SET_UPDATEED_VEHICLE_INFO, payload: true});
        } else {
            yield put({type: type.SET_UPDATEED_VEHICLE_INFO, payload: null   });
        }

       } catch (error) {
        yield put({type: type.SET_UPDATE_VEHICLE_LOADING, payload: false });
        yield put({type: type.SET_UPDATEED_VEHICLE_INFO, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_UPDATE_VEHICLE, doInit);
}
