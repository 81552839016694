export const Photos = [
    {   image: 'images/gallery/g1.jpg',
    title: "Safety Inspection",
    text: ["Since July 1st, 2016, the Ministry of Transportation (MTO) introduced a new safety standard inspection program. This new program was well over due to be updated. The previous standards were for pre 1975 vehicles. Imagine that we used to safety newer front wheel drive vehicles with over 40 year old rear wheel drive technology standards. The MTO has stated it will be a work in progress and promises that they will not put it on the shelf for another 40 years."]
},
{   image: 'images/gallery/g2.png',
    title: "MIL Diagnostics",
    text: ["We at Bob Nurse Motors rely on only the best training to help diagnose your vehicles. Today’s vehicles are very difficult to diagnose and require both time and knowledge to get to the route of the problem. As highly trained technicians we are constantly thinking three to four steps ahead of our actions at all times, and daily distractions are devastating to our efficiency. We want to get your vehicles’ fixed properly and quickly the first time. To help with our diagnostics we have numerous factory scan tools to do more testing capabilities than aftermarket scan tools or code readers are capable of providing."]
},
{   image: 'images/gallery/g3.png',
    title: "Module Programming",
    text: ["Many vehicles’ today require this service to repair common problems on certain makes and models. More and more vehicles require new parts to be programmed so they can communicate with the other modules of the vehicles network, before that component will operate."]
},
{   image: 'images/gallery/g4.png',
    title: "Oil Change Intervals",
    text: ["Post-2000 vehicles are equipped with more and more oil controlled components; oil controlled timing chain tensioners, VVT Variable Valve Timing actuators and solenoids, DOD Displacement on Demand or cylinder deactivation, GDI Gasoline Direct Injection and Turbocharging systems. Clean and proper proprietary rating oils are crucial for the life expectancy of these expensive components."]
},
{   image: 'images/gallery/g5.png',
    title: "Gasoline Direct Injection",
    text: ["Gasoline Direct Injection is a fuel injection system with the injector nozzles located in the cylinders combustion chambers, rather than in the intake manifolds. The manufactures introduced this system to better atomize the fuel for emissions. When the injectors were located above the valves in the intake manifold, the fuel spray kept the valves clean from positive crankcase vapors (PCV’s). Now that the injection of fuel is below the valves right inside the combustion chambers, the PCV vapors are accumulating causing excessive carbon build up on these valves. There is no recommended routine maintenance for this issue. The only proper service for drivability issues from this condition is time consuming walnut blasting."]
},
{   image: 'images/gallery/g6.jpg',
    title: "Cooling System Repair",
    text: ["Periodic cooling system flushes may prolong the life of expensive components. Newer HOATS type coolant is recommended to be replaced every 5 years or 100,000 km. Here is an example of an expensive repair that may or may not have been prevented with regular coolant flushes (A difficult heater core job, needing heater box removed from behind dash board to be replaced.)"]
},
{   image: 'images/gallery/alignment.jpg', 
    title: "Alignment",
    text: [
        "Alignments includes inspection of front and rear suspension, adjust toe and center steering wheel.",
        "Extra charges for:",
        "Rear toe adjustment",
        "Front and rear camber and caster adjustments",
        "Steering wheel sensor calibration",
        "Newer vehicles with adaptive cruise, lane change assist, blind spot detection and lane departure warning systems require additional calibrations after any alignment."
    ]

    },
{   image: 'images/gallery/g8.jpg',
    title: "Engine Repair",
    text: [
        "Modern day vehicles have less and less room in the engine compartment making it increasingly more difficult and time consuming for technicians to work on. More and more vehicles are using timing chains than belts. When performing these repairs the technician needs zero distractions aligning timing marks. The slightest error can cause drivability issues or even expensive engine damage."    ]

    },
    {   image: 'images/gallery/g9.png',
    title: "Transmission Service",
    text: [
        "Transmission Services are just as important as oil changes and should be done between 60,000 -100,000 Km intervals, to help provide vehicle longevity."    ]

    }
]