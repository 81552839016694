import React  from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Lock, ArrowRightAlt } from '@material-ui/icons';
import { PasswordField, validation , textField } from '../../../formFields';

// pristine, submitting
let VerifyOtpFormResetPassword = props => {
    const { handleSubmit , loading, onClose , resendOtp} = props;
    return (
      <form onSubmit = { handleSubmit } noValidate >
                <DialogTitle style= {{ cursor: 'move' }} id="draggable-dialog-title">
                <div  className ="info">
                        <Lock/>
                        <h2> Update Password </h2>
                    </div>
                </DialogTitle>
                <DialogContent> 
                <p>OTP has been sent to on your mobile phone and registered email id </p>
                    <div className ="resent-otp">
                    <Field 
                        name="otp"  
                        validate={ [validation.required] }  
                        component={ textField } 
                        label="Enter OTP" />
                        
                        <Button size="small"  onClick ={ resendOtp } color="primary" >
                          { loading ? 'Processing...': 'Resend OTP'}
                        </Button>
                    
                    </div>
                   
                  
                    <Field 
                      name='password'
                      validate={[validation.required]}  
                      component={PasswordField} 
                      label="New Password" />
                      
                    <Field 
                      name='confirmPassword'
                      validate={[validation.required]}  
                      component={PasswordField} 
                      label="Confirm Password" />
                </DialogContent>
                
                <DialogActions>
                    <Button   className="loading-button" disabled={loading}  variant="contained" color="primary" type="submit">
                    {!loading && <> Update Password  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                    </Button>
                    <Button autoFocus onClick={ onClose } color="primary" variant="contained"> Close </Button>
                </DialogActions>
            </form>
    )
  }
  
  VerifyOtpFormResetPassword = reduxForm({
    form: 'VerifyOtpFormResetPassword',
  })(VerifyOtpFormResetPassword);
  

export default  VerifyOtpFormResetPassword;
