import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Routes } from './routing';
import {  Header , Sidebar, Breadcrumbs} from './shared';
import './style.scss';


class AdminApp extends  Component {
  constructor(props) {
    super(props);
    this.state = { isSidenav : false};
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar(){
    let { isSidenav } = this.state;
    this.setState({ isSidenav: !isSidenav})
  }

  render(){
    const { isSidenav } = this.state;

    return(
    <>
          <main >
        <Breadcrumbs/>
        <div className ="route-content"> <Routes/> </div>
      </main>
    <Header toggleSidebar = { this.toggleSidebar} />
    
      <nav aria-label="sidebar" className ="sidenav">
        <Hidden smUp implementation="css">
            <Drawer className ="drawer-lg" variant="temporary" open={isSidenav} onClose={this.toggleSidebar}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              <Sidebar/>
            </Drawer>
          </Hidden>

          <Hidden xsDown implementation="css">
            <Drawer variant="permanent" open className ="drawer-lg">
              <div ><Sidebar/> </div>
            </Drawer>
          </Hidden>
      </nav>

    </>
    )
  }
}

export default AdminApp ;
