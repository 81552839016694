import React, { Component } from 'react';
import { Banner } from '../../shared';
import  { ListCard } from './listCard';
import { abouts} from './about';

class Abouts extends  Component {
  render() {
    return (
    <>
      <Banner data = {{image: '/images/bob-banner.jpg', text: 'About Us'}}/>
      <div>
        {abouts && abouts.map((e,i) =>  <ListCard key ={i} data ={e}/>) }
      </div>
    </>)
  }
}

export default Abouts;
