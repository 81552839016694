import React from "react";
import { Switch, Route } from "react-router-dom";
// import { Login } from '../components/user-account/login';
// import { Signup } from '../components/user-account/signup';
// import { UserProfile } from '../components/user-profile';
// import { UserInvoice } from '../components/user-order';
// import  ViewRCPACustomer  from '../components/pages/view-rcpa/viewRCPA';
import { 
    // ServicesWeOffers, 
    HomePage,
    Aboutus,
    Services,
    History,
    Gallery,
    Contracts } 
    from '../components/pages';

    
export const Routes = (props) =>(<Switch>
    {/* <Route exact path ='/services-we-offer'      history = { props.history }  component = { ServicesWeOffers } /> */}
    <Route exact path ='/about-us'  history = { props.history }  component = { Aboutus } />
    <Route exact path ='/contact-us'  history = { props.history }  component = { Contracts } />
    <Route exact path ='/services'  history = { props.history }  component = { Services } />
    <Route exact path ='/'      history = { props.history }  component = { HomePage } />
    {/* <Route exact path ='/login' history = { props.history }  component = { Login } /> */}
    {/* <Route exact path ='/signup' history = { props.history }  component = { Signup } /> */}
    <Route exact path ='/photo-gallery' history = { props.history }  component = { Gallery } />
    <Route exact path ='/restoration-project' history = { props.history }  component = { History } />
    {/* <Route exact path ='/my-profile' history = { props.history }  component = { UserProfile } /> */}
    {/* <Route exact path ='/my-vehicle' history = { props.history }  component = { UserProfile } /> */}
    {/* <Route exact path ='/my-order' history = { props.history }  component = { UserInvoice } /> */}
    {/* <Route exact path ='/RCPA/:mobile/:id' history = { props.history }  component = { ViewRCPACustomer } /> */}
</Switch>)
