import type from '../actionsType';

const doAddInvoice = (payload) => ({
    type: type.DO_ADD_INVOICE,
    payload
});

const doGetInvoice = (payload) => ({
    type: type.DO_GET_INVOICE_DATA,
    payload
});

const doGetOilChange = (payload) => ({
    type: type.DO_GET_OIL_CHANGE,
    payload
});

const doResetInvoice = (payload) => ({
    type: type.SET_LIST_INVOICE_DATA,
    payload
});


const onResetAddInvoice = () => ({
    type: type.SET_ADD_INVOICE_RESET,
    payload: false
});
const onResetInvoiceData = () => ({
    type: type.SET_INVOICE_DATA,
    payload: false
});




export {
    doAddInvoice,
    doGetOilChange,
    onResetInvoiceData,
    doGetInvoice,
    doResetInvoice,
    onResetAddInvoice
}