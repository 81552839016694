import React from 'react';
import Row from '@material-ui/core/Grid';
import UserImage from '@material-ui/core/Avatar';
import { getAvatarText } from '../../helpers';


export const Avatar = (props) =>
    (<UserImage className ="avatar">{getAvatarText(props.first, props.last)}</UserImage>)

export const UserInfo = (props) => (
    <Row container direction="column" justify="flex-start" alignItems="flex-start">
        <strong>{ props.user.firstName + ' ' + (props.user.lastName ? props.user.lastName : '')  }</strong>
        <span className="text-elipsis"> { props.user.emailAddress } </span>
    </Row>
)