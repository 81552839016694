import React, { Component} from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Row from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { withRouter }  from 'react-router-dom';
import { 
    AccountCircle,
    Close,
    Home,
    ContactPhone,
    SupervisorAccount,
    SupervisedUserCircle,
    Stars
} from '@material-ui/icons';
import { MenuItems } from '../../../helpers';

class MobileMenu extends Component {
    constructor( props) {
        super( props);
        this.state = { 
            isOpen: props.isOpen
        }
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.onRedirect = this.onRedirect.bind(this);
    }

    toggleDrawer() {
        this.props.onCloseMenu();
    }
    /* Redirect to given URL */
    onRedirect = (url) =>  {
        this.props.onCloseMenu();
        this.props.history.push( url || '/');
    }

    static getDerivedStateFromProps ( props ){
        return { ...props };
    }

    render() {
        let { isOpen } = this.state;

        return (
            <SwipeableDrawer className ="drawer-root" open={isOpen} onOpen = { () => this.toggleDrawer()} onClose={ () => this.toggleDrawer()}>
                <AppBar position="static">
                    <Toolbar variant="dense" >
                        <Row container direction="row" justify="space-between" alignItems="center">
                            <Row item> Menu </Row>
                            <Row item>
                                <IconButton onClick={ () => this.toggleDrawer()}  color="inherit" aria-label="menu"> <Close /> </IconButton>
                            </Row>
                        </Row>
                    </Toolbar>
                </AppBar>

                <div className ="menu-content-root">
                    <List  component="nav">
                        <ListItem button onClick = {()=> this.onRedirect(MenuItems.home.url )}>
                            <ListItemAvatar><Avatar><Home/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.home.text} />
                        </ListItem>

                        <Divider/> 
                        <ListItem button onClick = {()=> this.onRedirect(MenuItems.about.url )}>
                             <ListItemAvatar><Avatar><SupervisorAccount/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.about.text} />
                        </ListItem>

                        <Divider/>
                        <ListItem button onClick = {()=> this.onRedirect( MenuItems.services.url)}>
                             <ListItemAvatar><Avatar><SupervisedUserCircle/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.services.text} />
                        </ListItem>

                        <Divider/>

                        <Divider/>
                        <ListItem button onClick = {()=> this.onRedirect( MenuItems.photoGallery.url)}>
                             <ListItemAvatar><Avatar><SupervisedUserCircle/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.photoGallery.text} />
                        </ListItem>

                        <Divider/>

                        
                        <ListItem button onClick = {()=> this.onRedirect( MenuItems.ourHistory.url)}>
                             <ListItemAvatar><Avatar><AccountCircle/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.ourHistory.text} />
                        </ListItem>

                        <Divider/>

                        <ListItem button onClick = {()=> this.onRedirect(MenuItems.contacts.url)}>
                             <ListItemAvatar><Avatar><ContactPhone/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.contacts.text} />
                        </ListItem>


                        {/* <Divider/>
                        <ListItem button  onClick = {()=> this.onRedirect( MenuItems.servicesWeOffer.url)}>
                             <ListItemAvatar><Avatar><Stars/></Avatar></ListItemAvatar>
                            <ListItemText primary= { MenuItems.servicesWeOffer.text} />
                        </ListItem> */}
                    </List>
                </div>
            </SwipeableDrawer>
        )
    }
}

export default withRouter(MobileMenu);