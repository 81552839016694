import React , { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Visibility from '@material-ui/icons/Visibility';
import FormHelperText from '@material-ui/core/FormHelperText';

export class PasswordField extends Component {
    state = {
        showPassword: false,
        password: ''
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    render() {
        // warning
       const  { input, label, meta: { touched, error } } = this.props;
    
        return (<FormControl error ={ (touched && error ) ? true : false } className={ "field-with-icon " + ((touched && error ) ? 'error' : '')} fullWidth>
          <Grid container spacing={1} alignItems="flex-end" >
            <Grid item xs>
                <TextField label= { label } fullWidth error ={ (touched && error ) ? true : false }
                    {...input}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    type={ 'password' }
                    />

            </Grid>
          </Grid>
          <FormHelperText className={ "error-field " + ((touched && error) ? 'show': 'hide')}>{error}</FormHelperText>

      </FormControl>)
    }
}
