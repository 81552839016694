import React , { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';


const  NumberFormatCustom = (props) => {
  const { inputRef, format, mask, onChange, ...other} = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      format= { format}
      mask = {mask}
      thousandSeparator = { false }
    />
  );
}

NumberFormatCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
onChange: PropTypes.func.isRequired,
};

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export class InputFieldNumber extends Component {
    render() {
       const  { input, label,helperText, validation, meta: { touched, error } } = this.props;
        return (
        <FormControl error = { (touched && error ) ? true : false } className= { "field-with-icon " + ((touched && error ) ? 'error' : '')} fullWidth>
            <TextField label= { label } fullWidth error = { (touched && error ) ? true : false }
                { ...input }
                helperText ={ helperText }
                InputProps={{
                  inputComponent: (props) => <NumberFormatCustom {...props} {...validation}/>,
                }}
                value={ input.value}/>
            
          <FormHelperText className={ "error-field " + ((touched && error) ? 'show': 'hide')}> {label} is required </FormHelperText>
      </FormControl>)
    }
}
