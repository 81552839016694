import React , { useState, useEffect }  from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import { textField,  validation ,ReactSelect } from '../../formFields';
import { getProvinces , provinces,  getCites } from '../../data-set/canada-provinces';
// import { customerTypeList } from '../../data-set/customerType';
const ProvinceSuggestions = getProvinces();


let AddCustomerForm = props => {
    const { handleSubmit , loading , data} = props;
    const [ CitySuggestions, setCitySuggestions ] = useState( []);
    
    useEffect((e) => {
        // Update the document title using the browser API
        if( data && data.companyName && data.email && data.mobileNumber) {
            if( props.initialized === false) {
                props.initialize(data);
            }
        }
      });

    const onSelectedState = (val) => {
        
        if( val && val.value) {
            let filter = [];
            provinces.forEach( list => {
                if( list.abbreviations === val.value) {
                    filter = list;
                }
            });
            let city = getCites(filter);
            setCitySuggestions(city);
        }
    }

    return (
      <form onSubmit= { handleSubmit }  noValidate>
        <Row  container direction="column" justify="center" alignItems="center" className ="heading-small">
            <Row item> 
                 Add Details for { data.mobileNumber } &nbsp; 
                <Button size= "medium" onClick = {()=> props.onBack(0)}> Change </Button> 
            </Row>
            <Row item >
                <small>This primary mobile number, username will be same at the login time</small>
            </Row>
        </Row>


        <Row  spacing = { 5 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs>
                <Field 
                    name="companyName"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Company Name" />
            </Row>
            <Row xs item>
                <Field 
                    name="email"  
                    validate={[validation.required, validation.email]}  
                    component={textField} 
                    label="Email address" />
            </Row>
           
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs>
                    <Field 
                        name="firstName"  
                        validate={[validation.required]}  
                        component={textField} 
                        label="First Name" />
                </Row>
            <Row item xs>
                <Field 
                    name="lastName"  
                    component={textField} 
                    label="Last Name" />
                </Row>
        </Row>
        
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs>
                <Field 
                    name="street"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Street" />
            </Row>

            <Row item xs>
                <Field 
                    name="state"  
                    id ="state"
                    validate={[validation.required]}  
                    component={ReactSelect} 
                    suggestions ={ ProvinceSuggestions}
                    onChange = { onSelectedState }
                    label="Province" />
            </Row>
        </Row>

        <Row  spacing = { 5 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs>
                <Field 
                    name="city"
                    id= "city"  
                    validate= {[validation.required]}  
                    component = { ReactSelect } 
                    suggestions ={ CitySuggestions}
                    label="City" />
            </Row>
            <Row item xs>
                <Field 
                    name="postalCode"
                    id = "code" 
                    // validation= {{format : "######" , mask :"_" }}
                    component ={ textField}
                    validate={[validation.required]}  
                    label="Postal Code" />
            </Row>
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs>
                {/* fill this mob automatic number */}
                <Field 
                    name="mobile"
                    id= "phone" 
                    component ={ textField}
                    validate={[validation.required]}  
                    label="Phone Number" />
            </Row>
            <Row item xs>
                <Field 
                    name="otherNumber"
                    id= "otherNumber" 
                    component ={ textField}
                    label="Other Phone" />
            </Row>
        </Row>



        <Row container  direction="row" spacing = { 1 } justify="center"  alignItems="center">
            <Row item>
                <Button  onClick = {()=> props.onBack(0)} 
                    className="loading-button" disabled = { loading }  variant="contained" color="primary"> 
                   <KeyboardBackspace/> Back 
                </Button>
            </Row>
            <Row item>
                <Button className="loading-button" disabled = { loading }  variant="contained" color="primary" type="submit">
                    {!loading && <> Save &amp; Next  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                </Button>
            </Row>
        </Row>
        
      </form>
    )
  }
  
  AddCustomerForm = reduxForm({
    form: 'AddCustomerForm',
  })(AddCustomerForm);

export default  AddCustomerForm;
