import React, { Component } from 'react';
import { Banner } from '../../shared';
import Typography from '@material-ui/core/Typography';

class ServicesWeOffers extends  Component {
  render() {
    return (
    <>
      <Banner data = {{image: '/images/bob-banner.jpg', text: 'Services We Offer'}}/>
      <div className ="container">
      <div className =" section" >
        <Typography variant="h2" className ="heading"> Services We Offer </Typography>
        <p>
          Today's vehicles are very difficult to diagnose and require both time and knowledge to get to the route of
          the problem. We as highly trained technicians have to be constantly thinking 3-4 steps ahead of our
          actions and daily distractions are devastating to our efficiency.
          <br/><br/>
          We want to get your vehicle fixed properly and quickly the first time. We feel our Diagnostic charge rates are fare,
          while understanding the consumers financial situation at hand; therefore we frequently give the consumer a break with the
          diagnostic charges. If it takes us 10 hours to Diagnose your car you will only be charged a maximum of
          5 hours. Due to the complexity and aging conditions of vehicles today occasionally we have to rely on
          trial and error diagnostics, meaning we may have to resort to replacing components to get to the route of
          the problem. We assure you we try to refrain from this type of diagnostics as much as we can and will
          notify your before any repair steps are taken.
          <br/><br/>
          Unfortunatly we do not give sugestions or advise on how
          to fix your own cars, and do not install peoples own parts. Please, let us do our job, We will not let you
          down!
        </p>

        <ul className = "list-view">
          <li> <b> Current Labour rate:</b> $90.00 /hr </li>
          <li> <b>Safety Inspection:</b> (1.5 hr charge @ $135.00/ larger trucks &amp; trailers 2 hr charge @$180.00)</li>
          <li> <b>Inspections:</b> (Minimum .5 hr hoist charge @ $45.00)</li>
          <li> <b>Advanced Malfunction Indicator lights (MIL) Diagnostics:</b> Minimum &#x2039; 1.5 hrs @ $125.00 - Maximum 5 hrs @ $450.00 charge
            
            <ul className = "list-view">
              <li>Engine Control Management</li>
              <li>SRS (Safety Restraint System)</li>
              <li>ABS , Track and Stability Control Systems</li>
            </ul>

          </li>
          <li>Electronic Accesories Diagnostics &amp; Repair</li>
          <li> Drive Line Vibrations, Rattles , Clunks and Noise Diagnostics: (Minimum &#x2039; 1.5 hrs @ $125.00 - Maximum 5 hrs @ $450.00 charge</li>
          <li>Some Module Reprogramming, Re flashing and Updating Available (Some vehicles need to return to dealer for this service)</li>
          <li> Fluid Changes:
            <ul className = "list-view">
              <li>Oil</li>
              <li>Transmission</li>
              <li>Coolant</li>
              <li>Differential and Transfer</li>
            </ul>
          </li>
          <li>Gasoline Direct Injection Service (GDI) (Not recomended as regular maintenance service)</li>
          <li>Variable Valve Timing Service (VVT) (Crankcase clean for poorly maintained vehicles)</li>
          <li>Cooling system repair:
            <ul className = "list-view">
              <li>Radiator</li>
              <li>Heater core</li>
              <li>Water pump</li>
              <li>Thermostats</li>
              <li>Frost Plugs &amp; Block Heaters</li>
              <li>Head Gaskets</li>
              <li> Cooling Fan Operation Diagnostics</li>
            </ul>
          </li>
        </ul>
        </div>
      </div>
    </>)
  }
}

export default ServicesWeOffers;
