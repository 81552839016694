import type from '../actionsType';

const doGetCustomerList = (payload) => ({
    type: type.DO_GET_CUSTOMER,
    payload
});


const doGetCustomerDetails = (payload) => ({
    type: type.DO_GET_CUSTOMER_DETAILS,
    payload
});

const onResetCustomerList = () => ({
    type: type.DO_GET_CUSTOMER_LOADING,
    payload: false
});


export {
    doGetCustomerList,
    onResetCustomerList,
    doGetCustomerDetails
}