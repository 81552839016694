import React, { Component } from 'react';
import { connect} from 'react-redux';
import { onBreadcrumbButton , doGetCustomerList } from '../../../stores/actions';
import { ROUTER_INFO } from '../../routing/routerList';
import { config} from '../../../config/config';
import { ActionMenu, CustomerCard , NoRecords} from './components'
import { Loading } from '../../shared/loading';
import './style.scss';

class Customers extends  Component {
  constructor (props){
    super( props );
    this.state = {
      isMenuOpen: null,
      onElement: null,
      isLoading: true,
      customers: null,
      activeCustomer: null,
    };

    this.onMenuActionClose = this.onMenuActionClose.bind(this);
    this.onActionMenuOpen = this.onActionMenuOpen.bind(this);
  }

  componentDidMount(){
    this.props.onBreadcrumbButton( ROUTER_INFO.addcustomer );
    this.props.doGetCustomerList( { limit: config.pageLimit } );
  }

  componentWillUnmount() {
    this.props.onBreadcrumbButton( null );
  }
  
  onMenuActionClose() {
    this.setState( {isMenuOpen: false} );
  }

  onActionMenuOpen(e, activeCustomer) {
    this.setState({ onElement: e.currentTarget, isMenuOpen: true, activeCustomer});
  }
  
  static getDerivedStateFromProps = ( props) => ({...props});

  render() {
    let { isMenuOpen, onElement, activeCustomer,  customers, isLoading } = this.state;

    return(
    <>  
      { isLoading && <Loading/> }
      {(!isLoading &&  customers && customers.length >= 0 ) && <>
        <ActionMenu isMenuOpen = { isMenuOpen}  onElement = { onElement} activeCustomer = { activeCustomer } onMenuClose ={ this.onMenuActionClose} />
        { customers && customers.map((item, i) => <CustomerCard data = { item } key = { i } onActionMenuOpen = { this.onActionMenuOpen} />)}
      </>}
      {(!isLoading &&  ((customers && customers.length === 0) || !customers) ) && <NoRecords/>}

    </>
    )
  }
}

const mapStateToProps = state => {
  return {
    customers: state.viewCustomer.customers,
    isLoading: state.viewCustomer.isLoading,
  };
}

export default connect(mapStateToProps, {onBreadcrumbButton, doGetCustomerList})(Customers);