
const validateEmail = (email) => {
    let EMAIL_REGEXP = /^\w+([\\.-\]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return EMAIL_REGEXP.test(String(email).toLowerCase());
}

export const validation = {
    // required 
    required : value => (value ? undefined : 'This is required'),
    //is valid email address
    email: (value) =>  validateEmail(value) ? undefined : 'Email address is invalid',
    equal :(value) => {
        console.log(value);
    }
}
 