import React from 'react';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import { config } from '../../../helpers';
import './social-icons.scss';

export const SocialIcons = props => (
    <Row container direction= { (props.type === 'list') ? "column": "row" } justify="flex-start"  alignItems="center" className ="social-icons">

        { config.socialProfile && Object.keys(config.socialProfile).map( (key, i) => (
            <Row item key ={i}>
                <Button target ="_blank" 
                    href = { config.socialProfile[key].url } 
                    component = { 'a' }> 
                    <img src = {config.socialProfile[key].image} alt ={ config.socialProfile[key].title}/>
                   &nbsp; { config.socialProfile[key].title } 
                </Button>
            </Row>
        ))}

        </Row>
)