import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';

export  const doSignup  =(request) => {
    delete request.isResendOtp;
    request.name = { firstName: request.firstName, lastName: request.lastName};
    delete request.firstName;
    delete request.lastName;

    return apiService.makeRequest({
        method: 'POST',
        url:'users/register',
        data: request
    });
}

function* doSignupWatch(action){
    yield put({type: type.SET_SIGNUP_LOADING, payload: true });

    try {
    
        let req = {};
        Object.assign(req, action.payload);

        let response = yield call(doSignup, req);
        yield put({type: type.SET_SIGNUP_LOADING, payload: false });

        if( response && response.success === false) {
            let error = response.errors || 'The mobile number you have entered is already registered';
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: error } });

        } else {

            yield put({type: type.SET_SIGNUP_USER, payload: action.payload});

            if( action && action.payload && action.payload.isResendOtp) {
                let message = "A new otp has been sent to your mobile phone";
                yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message } });
            } else {
                yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: response.message } });
                
            }

        }

       } catch (error) {
            yield put({type: type.SET_SIGNUP_LOADING, payload: false });
       }
}

export default function* watchDoSignup() {
    yield takeLatest(type.DO_SIGNUP_USER, doSignupWatch);
}
