export const clientTestimonials =[
    {
        text: 'I highly recommend these mechanics. They are very knowledgeable, respectful and willing to help you keep repair cost down, while not jeopardizing reliability and safety!!! Thanks.',
        image: '/images/user.png',
        name: 'Robert Godkin',
    },
    {
        text: 'Fair, honest and reliable. I have been taking my vehicles to Rob for the last 3-4 years. I can always count on Rob and his technicians to correctly diagnose the problem and get me back on the road quickly. Excellent service at a fair price.',
        image: '/images/user.png',
        name: 'Tom sangster ',
    },
    {
        text: 'Great service. Great work. Great price. I have an older car, they just keep fixing me up.',
        image: '/images/user.png',
        name: 'Stacey Lawrence',
    },
    {
        text: 'Glad to have a trust worthy shop to go to! They know what their doing and treat you right.',
        image: '/images/user.png',
        name: 'Ben Macdonald',
    }
]