import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { theme } from './config/themes';
import { ThemeProvider } from '@material-ui/styles';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from './stores';
import history  from './config/history';
/* Import stylesheet */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SCSS/style.scss';
import './SCSS/mobile.scss';

ReactDOM.render(
    <ThemeProvider theme = { theme } >
        <Provider store = { store }>
            <Router history = { history } > 
                <App />
            </Router>
        </Provider>
    </ThemeProvider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
