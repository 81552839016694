import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Row from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';
import { doGetInvoice } from '../../../../stores/actions';

const style = {    
    borderBottom: '1px #c5c5c5 solid',
    paddingBottom: '10px',
    backgroundColor: '#ececec',
    cursor: 'move'
}

function PaperComponent(props) {
  return (
    <Draggable handle="#updateDetail" >
      <Paper {...props} />
    </Draggable>
  );
}

class  ViewInvoiceSummary extends Component {

    constructor(props) {
       super(props);

       this.state = { 
            isLoading: false
        }

       this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        // let { match , customer, loginUser, type} = this.props;
        // let customerId =  ( type && type.isImport ) ? loginUser._id : match.params.customerId;
        // this.props.doGetInvoice(`customerId=${customerId}&grouped=year&vehicleId=${e.vehicle._id}`);
    }

    static getDerivedStateFromProps( props) {
        return {...props};
    }

    render() {

    const { isOpen, onClose , customer } = this.props; 
    let data = null, total;
    return (
        <Dialog className ="update-details" open = { isOpen } 
            PaperComponent={ PaperComponent } aria-labelledby="updateDetail">
            
            <DialogTitle style = { style } id = "updateDetail">
                <Row container  direction="row" justify="space-between"  alignItems="center">
                    <Row item >  Generate Invoice for Customer { customer.name.firstName } </Row>
                    <Row item > 
                        <IconButton aria-label="delete" onClick = { onClose } >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Row > 
                </Row>
                    
                </DialogTitle>
                
                <DialogContent>
                <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell> Years</TableCell>
                                <TableCell align="right"> Total Purchase BNM </TableCell>
                                <TableCell align="right"> BNM RCPA </TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                { data && data.map( (e, i) => (<TableRow key = { i }>
                                    <TableCell>{e.year}</TableCell>
                                    <TableCell align="right"> ${e.bnm.toFixed(2)}</TableCell>
                                    <TableCell align="right"> {e.RCPA ? `$${e.RCPA}`  : '-'} </TableCell>
                                </TableRow>))}

                                <TableRow className ="table-footer">
                                    <TableCell> Total: </TableCell>
                                    <TableCell align="right">${total.toFixed(2)}</TableCell>
                                    <TableCell align="right"> &nbsp; </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer>
                </DialogContent>
                         
        </Dialog>
    )
    }
}

const mapDispatchToProps = { doGetInvoice};

const mapStateToProps= (state) => {
    return({
        isLoading: state.addInvoice.isLoading,
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoiceSummary);
