import React  from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
// import { config }  from '../../../config/config'
import { textField , validation } from '../../../formFields';

let VerifyCustomerForm = props => {
    const { handleSubmit , loading } = props;

    return (
      <form onSubmit= { handleSubmit }  noValidate className ="verify-form">
        <Row  spacing ={ 1 } container direction="row" justify="space-between" alignItems="center">
            <Row item xs ={12}>
              <div className ="text-center">Enter primary mobile number. This mobile number will be used as login. Duplicate mobile number is not allowed. </div>
            </Row>
            <Row item xs>
                <Field 
                    name="mobileNumber"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Mobile Number" />

            </Row>
        </Row>

        <Row container  direction="row" spacing = { 1 } justify="center"  alignItems="center">
            <Row item>
                <Button  size="small" className="loading-button" disabled = { loading }  variant="contained" color="primary" type="submit">
                    {!loading && <> Verify &amp; Next  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                </Button>
            </Row>
        </Row>
      </form>
    )
  }
  
  VerifyCustomerForm = reduxForm({
    form: 'VerifyCustomerForm',
  })(VerifyCustomerForm);
  

export default  VerifyCustomerForm;
