import type from '../actionsType';

const defaultState = {
    emailVerification_Loading: false,
    user: null,
    isLoading: false,
    isUpdated: false
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_EMAIL_VERIFICATION_LOADING:
            return { 
                ...state, 
                emailVerification_Loading: action.payload
            }
        case type.SET_USER_PROFILE_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        case type.SET_USER_PROFILE_UPADTED:
            return { 
                ...state, 
                isUpdated: action.payload
            }
        case type.SET_USER_PROFILE_DATA:
            return { 
                ...state, 
                user: action.payload
            }

        default :
        return state;
    }
}