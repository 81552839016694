import Cookies from './cookies';

export default class AuthService {
    
    async getAccessToken() {

        let data = Cookies.get();
        let token =  null;
        if( data) {
            token = {token: (data.token || null ), id: data._id};
        }
       return token;
    }
    
}