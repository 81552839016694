import type from '../actionsType';

const defaultState = {
    isLoading : false,
    customer: false,
    isExist: false
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_CHECK_EXISTING_CUSTOMER:
            return { 
                ...state, 
                isExist: action.payload
            }
        case type.SET_ADD_CUSTOMER:
            console.log("SET_ADD_CUSTOMER", action.payload );
            return { 
                ...state, 
                customer: action.payload
            }
        case type.SET_ADD_CUSTOMER_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        default :
        return state;
    }
}