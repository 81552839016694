import type from '../actionsType';

const doSendEmailVerification = (payload) => ({
    type: type.DO_SEND_EMAIL_VERIFICATION,
    payload
});

const doGetUser = (payload) => ({
    type: type.DO_GET_USER_PROFILE,
    payload
});
const doUpdateUser = (payload) => ({
    type: type.DO_UPDATE_USER_PROFILE,
    payload
});

const doResetUserUpdate = (payload) => ({
    type: type.SET_USER_PROFILE_UPADTED,
    payload
});



export {
    doSendEmailVerification,
    doGetUser,
    doResetUserUpdate,
    doUpdateUser
}