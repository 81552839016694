import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Row from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { reduxForm, Field } from 'redux-form';
import { validation , textField ,ReactSelect} from '../../../../formFields';
import {  doUpdateUser} from '../../../../stores/actions';
import { getProvinces , provinces,  getCites, getSelectedState, getSelectedCityList, getSlectedCity } from '../../../../data-set/canada-provinces';
import { getCustomerTypeObjByValue } from '../../../../data-set/customerType';

const ProvinceSuggestions = getProvinces();

function PaperComponent(props) {
  return (
    <Draggable handle="#updateDetail" >
      <Paper {...props} />
    </Draggable>
  );
}

class  UpdateDetails extends Component {

   constructor(props) {
       super(props);
       this.state ={ 
            autoFill: null,
           CitySuggestions:[],
           loginUser: false,
           isLoading: false
        }

       this.handleSubmit = this.handleSubmit.bind(this);
       this.onSelectedState = this.onSelectedState.bind(this);
   }

    handleSubmit(values) {
        let { autoFill } = this.state;
        let _val = {...values , customerId: autoFill._id };
        this.props.doUpdateUser(_val);
        
    }
    componentDidMount() {
        let { autoFill, loginUser } = this.state;
        if( autoFill && Object.keys( autoFill).length ) {
            autoFill = {...autoFill, ...autoFill.address, ...autoFill.name};
            autoFill.state = getSelectedState(autoFill.state);
            autoFill.state =autoFill.state[0];
            autoFill.city = getSlectedCity(autoFill.state,autoFill.city) ;
            autoFill.customerType = getCustomerTypeObjByValue(autoFill.customerType);
            if( autoFill.state && autoFill.state.value ) {
                let result = getSelectedCityList(autoFill.state.value);
                if( result) {
                    result = result[0];
                    if( result.city_list) {
                        let citites =   getCites(result) ;
                        this.setState({CitySuggestions: citites});
                    }
                }
            }

            if( loginUser && loginUser.role ) {
                autoFill.role = loginUser.role;
            }
            this.props.initialize(autoFill);
        }  
    }
    onSelectedState(val) {
        if( val && val.value) {
            let filter = [];
            provinces.forEach( list => {
                if( list.abbreviations === val.value) {
                    filter = list;
                }
            });
            let city = getCites(filter);
            this.setState({CitySuggestions: city});
        }
    }

    static getDerivedStateFromProps( props) {
        return {...props};
    }
    render() {
    const { title, isOpen, onClose, handleSubmit,loginUser } = this.props;
    let { isLoading } = this.state;
 
    let isAdmin = loginUser && loginUser.role === 'admin' ? true : false;
    return (
        <Dialog className ="update-details" open = { isOpen } onClose = {  onClose } PaperComponent={ PaperComponent } aria-labelledby="updateDetail">
           <form  onSubmit= { handleSubmit(this.handleSubmit) }  noValidate>
            <DialogTitle style={ { cursor: 'move' } } id="updateDetail"> { title } </DialogTitle>
            <DialogContent> 

                <Row  spacing ={ 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs>
                            <Field 
                                name="firstName"  
                                validate={[validation.required]}  
                                component={textField} 
                                label="First Name" />
                        </Row>
                    <Row item xs>
                        <Field 
                            name="lastName"  
                            component={textField} 
                            label="Last Name" />
                        </Row>
                </Row>
                
        { isAdmin && 
            <Row  spacing ={ 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs className ="primary-text">
                        <Field 
                            name="mobile"  
                            component={textField} 
                            label="Mobile Number" />                    
                        </Row>

                    <Row item xs>
                    <Field 
                    name="email"  
                    validate={[validation.required, validation.email]}  
                    component={textField} 
                    label="Email address" />
                </Row>
                </Row>
            }
                <Row  spacing ={ 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs>
                        <Field 
                            name="street"  
                            validate={[validation.required]}  
                            component={textField} 
                            label="Street" />
                    </Row>

                    <Row item xs>
                        <Field 
                            name="state"  
                            id ="state"
                            validate={[validation.required]}  
                            component={ReactSelect} 
                            suggestions ={ ProvinceSuggestions}
                            onChange = { this.onSelectedState }
                            label="Province" />
                    </Row>
                </Row>

                <Row  spacing = { 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs>
                        <Field 
                            name="city"
                            id= "city"  
                            validate={[validation.required]}  
                            component = { ReactSelect } 
                            suggestions ={ this.state.CitySuggestions}
                            label="City" />
                    </Row>
                    <Row item xs>
                        <Field 
                            name="pincode"
                            id= "code" 
                            component ={ textField}
                            validate={[validation.required]}  
                            label="Postal Code" />
                    </Row>
                </Row>

                <Row  spacing ={ 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs>
                        {/* fill this mob automatic number */}
                        <Field 
                            name="phoneNumber"
                            id= "phoneNumber" 
                            // validation= {{format : "##########" , mask :"_" }}
                            component ={ textField}
                            // validate={[validation.required]}  
                            label="Phone Number" />
                    </Row>
                    <Row item xs>
                        <Field 
                            name="otherNumber"
                            id= "otherNumber" 
                            // validation= {{format : "##########" , mask :"_" }}
                            component ={ textField}
                            label="Other Phone" />
                    </Row>
                </Row>

                <Row  spacing = { 2 } container direction="row" justify="space-between" alignItems="center">
                    <Row item xs ={12}>
                        <Field 
                            name="company"  
                            validate={[validation.required]}  
                            component={textField} 
                            label="Company Name" />
                    </Row>
                
                </Row>

            </DialogContent>
            
            <DialogActions>
                <Button  type ="submit" disabled = { isLoading } autoFocus color="primary" variant="contained"> 
                    { !isLoading ? 'Update Detail': 'Loading..'} </Button>
                <Button  onClick = { onClose }  disabled = { isLoading }   color="primary" variant="contained"> Close </Button>
            </DialogActions>
            <br/>
            </form>
        </Dialog>
    )
}
}

const UpdateDetailForm = reduxForm({
    form: 'UpdateDetails',
})(UpdateDetails);

const mapDispatchToProps = { doUpdateUser };

const mapStateToProps= (state) => ({
    loginUser: state.loginUser.loginUser,
    isLoading: state.userProfile.isLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDetailForm);
