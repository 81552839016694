import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
//   const [open, setOpen] = React.useState(false);
    
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

  return (
      <Dialog
        open={props.isOpen}
        disableBackdropClick ={ true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Customer has been successfully added What you want to do next ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=> props.handleClose('reset')} color="primary">
            Add More Customer
          </Button>
          <Button variant="contained" onClick={()=> props.handleClose('redirect')} color="primary" autoFocus>
            View  Added Customer
          </Button>
        </DialogActions>
      </Dialog>
  );
}