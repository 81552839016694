import React from 'react';
import Typography from '@material-ui/core/Typography';
import Row from '@material-ui/core/Grid';

export const  ListCard = props =>  {
    let { data } = props;
    let text = [], text1;
    if ( data.isParagrap) {
        text = data.text.slice(0,1);
        text1 = data.text.slice(1,data.text.length );
    }
        return (
            <div className ="section border-bottom">
                <div className ="container">
                    <Row container  direction="row"  justify="space-between" alignItems="flex-start">
                        <Row item xs = { 12 } md= { 4}>
                            <div className ="image-box" style= {{ backgroundImage: `url(${data.image1})`, ...data.style}}> </div>
                        </Row>
                        <Row item xs = { 12 } md>
                            <Row container spacing ={ 2} direction="column" justify="space-between" alignItems="flex-start">
                                <Row item className='banner-heading'> <Typography variant="h3" className ="heading"> {data.heading} </Typography> </Row>
                             { !data.isParagrap  &&  <Row item> {data && data.text && data.text.map( (e,i) => <p key ={i}> {e}</p>)}</Row>}
                             {  data.isParagrap &&  <Row item> { text.map( (e,i) => <p key ={i}> {e}</p>)}</Row> }
                            </Row>
                        </Row>
                        <Row item xs = { 12 } md= { 4}>
                            <div className ="image-box" style= {{ backgroundImage: `url(${data.image2})`}}> </div>
                        </Row>

                        { data.isParagrap && <Row item xs ={12} >
                           { text1.map( (e,i) => <p key ={i}> {e}</p>)}
                        </Row> } 
                    </Row>
                </div>
            </div>
        );
    }
