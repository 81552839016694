export const customerTypeList = [
    { label : 'No Oil change', value:'0'},
    { label : '1 Oil Change',  value:'1'},
    { label : '2 Oil Change',  value:'2'},
    { label : '3 Oil Change',  value:'3'},
    { label : '4 Oil Change',  value:'4'},
    { label : 'Free Oil',     value: '5' },
];

export const getCustomerTypeByValue = (value ) => {
    let obj;
    customerTypeList.forEach( e=> {
        if( value === e.value) {
            obj = e.label;
        }
    });
    return obj;
}
export const getCustomerTypeObjByValue = (value ) => {
    let obj;
    customerTypeList.forEach( e=> {
        if( value === e.value) {
            obj = e
        }
    });
    return obj;
}
export const OilCountPerCustomer = ( data) => {
    let count = data && Array.isArray(data) && data.length;
    if( count === 0 ) {
        return getCustomerTypeObjByValue('0');
    } else {
       
        let mod;
        mod =  count % 5;
        
        if( mod === 0) {
            return getCustomerTypeObjByValue('0');
        } else {
            return  getCustomerTypeObjByValue(mod.toString());
        }
        
       
    }

}

export const customerTypeListCheck = (value) => {
    value = value.toString();
    switch(value) {
        case '0':
            let data = [...customerTypeList];
            data[0].disabled = false;
            data[1].disabled = false;
            data[2].disabled = true;
            data[3].disabled = true;
            data[4].disabled = true;
            data[5].disabled = true;
            return data;
            
        case '1':
            let _data =[...customerTypeList];
            _data[0].disabled = false;
            _data[1].disabled = true;
            _data[2].disabled = false;
            _data[3].disabled = true;
            _data[4].disabled = true;
            _data[5].disabled = true;
            return _data;
            
        case '2':
            let __data =[...customerTypeList];
            __data[0].disabled = false;
            __data[1].disabled = true;
            __data[2].disabled = true;
            __data[3].disabled = false;
            __data[4].disabled = true;
            __data[5].disabled = true;
            return __data;
            
        case '3':
            let ___data =[...customerTypeList];
            ___data[0].disabled = false;
            ___data[1].disabled = true;
            ___data[2].disabled = true;
            ___data[3].disabled = true;
            ___data[4].disabled = false;
            ___data[5].disabled = true;
            return ___data;
        case '4':
            let _____data =[...customerTypeList];
            _____data[0].disabled = false;
            _____data[1].disabled = true;
            _____data[2].disabled = true;
            _____data[3].disabled = true;
            _____data[4].disabled = true;
            _____data[5].disabled = false;
            return _____data;
        case '5':
            let ______data =[...customerTypeList];
            ______data[0].disabled = false;
            ______data[1].disabled = true;
            ______data[2].disabled = true;
            ______data[3].disabled = true;
            ______data[4].disabled = true;
            ______data[5].disabled = false;
            return ______data;
            
            default:
    }
}