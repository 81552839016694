import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';
import  { orderBy } from 'lodash';
import { Total , TotalDiscount , TotalFreeOil, fillYears} from "../../../../admin/components/view-RCPA/helpers";

export  const CallAPI  = (params) => {
    let url ='invoice';
    if( params) {
        url = `${url}?${params}`
    }
    return apiService.makeRequest({
        method: 'GET',
        url
        // url:`invoice?grouped=year&vehicleId=5ed9292c6d3c8d105cd444bb`
    });
}
// SET_INVOICE_DATA
function* doInit(action) {
    yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: true });
    try {
        let response = yield call(CallAPI, action.payload);
        
        yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: false });

        if( response && response.success  === true ){
            let records = response.data[0].records;
            
            if( records && records.length > 0) {
                records =  orderBy(records, '_id', 'asc');
                // console.log(records, "records");
                // records = records.map( e=> {
                //     e.records= orderBy(e.records, 'year', 'desc');
                //     return {...e };
                // });

                /* convert data for final rcpa report  */
                let isCheck = records[0];
                if( isCheck && !isCheck.records) {
                    /* need to update data */
                    let byYear = records.reduce((ac, a) => {
                        let temp = ac.find(x => x.year === a.year);
                        if (!temp) ac.push({ ...a,
                            records: [a]
                        })
                        else temp.records.push(a)
                        return ac;
                        }, []);

                    byYear = byYear.map( e=> {
                        let sum = 0, discount= 0, freeOil = 0;
                        sum = sum + Total(e.records);
                        discount = discount +  TotalDiscount(e.records);
                        freeOil = freeOil +  TotalFreeOil(e.records);
                        return { ...e, Total: sum, discount, freeOil}
                    });
                   


                    let res = records.reduce((ac, a) => {
                        let temp = ac.find(x => x.vehicleId === a.vehicleId);
                        if (!temp) ac.push({ ...a,
                            records: [a]
                        })
                        else temp.records.push(a)
                        return ac;
                        }, []);
                        
                        let _result = res.filter( e => {
                            let _r = e.records
                            let _res = _r.reduce((ac, a) => {
                            let temp1 = ac.find(x => x.year === a.year);
                            if (!temp1) ac.push({ ...a,
                                records: [a]
                            })
                            else temp1.records.push(a)
                            return ac;
                            }, []);

                            e.records =  _res;
                            return e;  
                        });

                        _result = _result.map( e=> {
                            let sum= 0, discount = 0, freeOil = 0;
                            e.records.forEach ( j=> {
                                sum = sum +  Total(j.records);
                                discount = discount +  TotalDiscount(j.records);
                                freeOil = freeOil +  TotalFreeOil(j.records);
                            });
                            return { ...e, total:sum, discount, freeOil};
                        });

                        records = { totalByVehicle:_result, totalByYear: byYear }
                } else {
                    records =  fillYears(records);
                }
                

            yield put({type: type.SET_LIST_INVOICE_DATA, payload: records });
        }
        else {
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: "No invoice details found!" } }); 
        }
            
        } else {
            let error = (response.errors && Array.isArray(response.errors)) ? response.errors[0] : response.errors;
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: error } });
            yield put({type: type.SET_LIST_INVOICE_DATA, payload: null   });
        }


       } catch (error) {
        yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: false });
        yield put({type: type.SET_INVOICE_DATA, payload: false   });
       }
}

export default function* watchGetUserInfo() {
    yield takeLatest(type.DO_GET_INVOICE_DATA, doInit);
}
