import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { ErrorOutline } from '@material-ui/icons';
import './alert.scss';

class Alert extends  Component {
    constructor(props){
        super(props);
        this.state = { 
            alert:props.alert
        }
        this.onClose = this.onClose.bind(this);
    }
    onClose () {
        this.setState({alert: null}); 
    }

    static getDerivedStateFromProps = ( props) => ({...props});
  render() {
      let { alert} = this.state;
      
    return(
        <> { ( alert && Object.keys(alert).length) && 
        <div className = {alert.type === 'error' ? 'alert alert-danger' : 'alert alert-success'}>
            <Row container  direction="row"  justify="flex-start"  alignItems="center">
                {alert.type === 'error' &&  <Row item xs ={ 1}><ErrorOutline/> </Row>}
                <Row item xs ={ alert.type === 'error' ? 9 : 10}>
                    {alert.message} </Row>
                <Row item xs ={ 2} className ="text-right">
                    {/* <IconButton  onClick ={ this.onClose} aria-label="close" size="medium"><Close fontSize="inherit" /></IconButton> */}
                </Row>

            </Row>
        </div>
    }
        </>
    )
  }
}

export default Alert ;
