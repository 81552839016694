import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';
import { doLogout  } from '../../../stores/actions';
import { ExitToApp , ArrowDropUp } from '@material-ui/icons';
// import { UserInfo } from '../../../components/shared';

class UserDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUser: props.loginUser,
        }
       this.doLogout = this.doLogout.bind(this);
       this.toRedirect = this.toRedirect.bind(this);
    }

    // User logout and close session 
   doLogout() {
       this.props.doLogout();
        // history.push('/');

   }
   
   toRedirect(url) {
    if( url ) {
        this.props.history.push(url);
        this.props.onClose();
    }
   }

    render() {
        return (
        <>
        <Typography component ="div" className ="popover-content user-dropdown">
            <ArrowDropUp className ="arrowUp"/>
            <Row container direction="column" justify="center" alignItems="center">
                <Row item className ="menu-list">
                    <Row container spacing= { 1 }  direction="column" justify="center" alignItems="stretch">
                        <Row  item className ="list-item">
                            <List component="nav" aria-label="list-menu" dense = { true }>
                                {/* <ListItem button onClick = { ()=> this.toRedirect('my-profile')}>
                                    <ListItemIcon><AccountCircle/></ListItemIcon>
                                    <ListItemText primary="My Profile" />
                                </ListItem>
                                <Divider/> */}
                              
                                <ListItem button  onClick = { ()=> this.doLogout()}>
                                    <ListItemIcon><ExitToApp/></ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItem>
                            </List>
                        </Row>
                    </Row>
                </Row>
            </Row>
        </Typography>
        </>)
    }
}

const mapDispatchToProps = { doLogout};

export default  connect(null, mapDispatchToProps)(withRouter(UserDropDown));