import React from 'react';
import Row from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { Edit} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

export const VehicleCard = props => {
let { data } = props;
let province = (data.province && data.province.label) || (data && data.address && data.address.state);
let city = (data.city && data.city.label) || (data && data.address && data.address.city);
let year = (data.year && data.year.label) || ( data && data.attrs && data.attrs.year);
let isEdit = props.onEdit ? true : false;
data = { ...data, ...data.attrs };
return (
<>{ data &&
<ExpansionPanel className ="view-vehicle">
<ExpansionPanelSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls= {`Panel-${props.row}-content`}
  id = {`Panel-${props.row}`}>
   
  { data.plateNumber && 
    <Typography className ="title"> 
      {  data.make && <span> {data.make} </span>} {data.model && <span> - {data.model}  </span>}
      &nbsp;({data.plateNumber})
    </Typography> }
  <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
            <>
            {/* <Tooltip title="Delete">
              <IconButton disabled ={props.isLoading} size= "small" onClick ={()=>  props.onRemoveVehicle(props.row)}> <Delete/></IconButton>
            </Tooltip> */}
            { isEdit && <Tooltip title="Edit">
              <IconButton disabled ={props.isLoading}  size= "small" onClick ={()=>  props.onEdit(props.row)}> <Edit/></IconButton>
            </Tooltip> }
            </>
          }
    />

</ExpansionPanelSummary>
<ExpansionPanelDetails>
  <Row  spacing ={ 1 } container direction="column" justify="flex-start" alignItems="stretch">
    <Row item>
      <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
        <Row item xs>
          <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
            

            <Row item xs ={ 5}> Plate No:</Row>
            <Row item xs> {data.plateNumber|| 'N/A'}</Row>
          </Row>
        </Row>

        <Row item xs>
          <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
            <Row item xs ={ 5}> Lab Rate:</Row>
        <Row item xs> {data.labRate || 'N/A'}</Row>
          </Row>
        </Row>

        <Row item xs>
          <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
            <Row item xs ={ 5}> Province/City:</Row>
            <Row item xs>{city} ({province || 'N/A'}) </Row>
          </Row>
        </Row>
        
      </Row>
    </Row>

      <Row item>
        <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Odometer:</Row>
              <Row item xs> {data.odometer || 'N/A'}</Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> V.I.N:</Row>
              <Row item xs> {data.vin || 'N/A'}</Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Year:</Row>
              <Row item xs> { year|| 'N/A' }</Row>
            </Row>
          </Row>

        </Row>
      </Row>
      <Row item>
        <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Make:</Row>
              <Row item xs> {data.make || 'N/A'}</Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Body:</Row>
              <Row item xs> {data.body || 'N/A'}</Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Color:</Row>
              <Row item xs> {data.color || 'N/A'}</Row>
            </Row>
          </Row>

        </Row>
      </Row>
      <Row item>
        <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Engine:</Row>
              <Row item xs> {data.engine || 'N/A'}</Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> Trans:</Row>
              <Row item xs> {data.tarns || 'N/A'} </Row>
            </Row>
          </Row>

          <Row item xs>
            <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
              <Row item xs ={ 5}> A/C:</Row>
              <Row item xs> {data.ac || 'N/A'}</Row>
            </Row>
          </Row>

        </Row>
      </Row>
      <Row item>

      <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
      <Row item xs>
        <Row  spacing = { 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs ={ 5}> Unit:</Row>
          <Row item xs> {data.unit || 'N/A'}</Row>
        </Row>
      </Row>

      <Row item xs>
        <Row  spacing ={ 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs ={ 5 }> Driver:</Row>
          <Row item xs> { data.driver || 'N/A'} </Row>
        </Row>
      </Row>

      <Row item xs>
        <Row  spacing = { 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item xs = { 5 }> Model:</Row>
          <Row item xs> { data.model || 'N/A'}</Row>
        </Row>
      </Row>

      <Row item xs = { 12 }>
        <Row  spacing = { 1 } container direction="row" justify="flex-start" alignItems="center">
          <Row item  > Notes:</Row>
          <Row item> { data.notes || 'N/A'}</Row>
        </Row>
      </Row>

      </Row>
      </Row>
    
    </Row>
  </ExpansionPanelDetails>
  </ExpansionPanel>}</>)
}