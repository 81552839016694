import { createStore, applyMiddleware, compose  } from 'redux';
// import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer  from './reducers';
import rootSagas from './saga';

const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware); 

const rootStore = createStore(rootReducer,  compose(middleware));
// then run the saga
sagaMiddleware.run(rootSagas);

export default rootStore;

