import React from 'react';
import Row from '@material-ui/core/Grid';
import { CheckCircleOutline } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

export const ResetpasswordSuccess = (props) =>(
    <Row container  direction="column" spacing = { 1} className ="info-success"  justify="center"  alignItems="center">
        <Row item> <CheckCircleOutline/></Row>
        <Row item>Password has been successfully changed!</Row>
        <Row item>
        <Button autoFocus onClick={ props.onClose } color="primary" variant="contained"> Close </Button>
        </Row>
    </Row>
)