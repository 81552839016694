import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import './card.scss';

class PhotoCard extends  Component {
  render() {
   const { data } = this.props;
   let text = (data && data.text) || [];  
    return(
      <Card className = "image-card">
      { data.date && <div className ="date-tag">{data.date}</div>}
        <img src = { data.image}  alt= "gallery" style ={{width: "100%"}}/>

      <CardContent>
        <Typography component="h3"> { data.title } </Typography>
        { text && text.map((e, i) =>  <Typography variant="body" component="p" key= { i}> {e} </Typography>)}
      </CardContent>
    </Card>
    )
  }
}

export default PhotoCard ;
