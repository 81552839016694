import React, { Component } from 'react';
import CustomerApp from './customer.app';
import AdminApp from './admin/admin.app';
import { withRouter }  from 'react-router-dom';
import { connect } from 'react-redux';
import { Snackbar} from './components/shared';
// import  AdminLogin  from './admin/admin-login';

class App extends  Component {
  constructor(props){
    super( props );
    this.state = {
      loginUser: null,
      isAdmin: false,
      // isAdminLogin : false,
    };
    // this.setRoute = this.setRoute.bind(this);
  }
  // setRoute() {
  //   let { location} = this.props;
  //   let { isAdmin } = this.state;
  //   let isAdminLogin = false;

  //   if( location && location.pathname === '/admin') {
  //     if ( !isAdmin || isAdmin === false ) {
  //       isAdminLogin = true;
  //     } else {
  //       isAdminLogin = false;
  //     }
  //     this.setState( {isAdminLogin});
  //   } 
  // }

  componentDidMount() {
    // let { history } = this.props; 
    // history.listen( e => this.setRoute());
    // this.setRoute();
  }

  /* Check props and update state */
  static getDerivedStateFromProps = ( props) => ({...props});
  
  render(){
    let { loginUser } = this.state;
    let isAdmin  = loginUser && loginUser.role ==='admin' ? true: null;
    return (<> 
      <Snackbar/>
    { isAdmin ? <AdminApp/>: <div className ="customer-app"><CustomerApp/></div> } 
    </> )
  }
}

const mapStateToProps = (state) => {
  
  return { 
    loginUser: state.loginUser.loginUser
  }
}
export default connect(mapStateToProps)(withRouter(App)) ;
