import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Lato',
      '"sans-serif"'
    ].join(','),
    fontSize: 12
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#228B22',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#3f51b5',
      main: '#3f51b5',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  
    // error: will use the default color
  },
});