import type from '../actionsType';

const defaultState = {
    user : null,
    isLoading : false,
    alert: null,
    isOtpVerify: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_SIGNUP_USER:
            return { 
                ...state, 
                user: action.payload
            }
        case type.SET_SIGNUP_MESSAGE:
            return { 
                ...state, 
                alert: action.payload
            }
        case type.SET_SIGNUP_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        case type.SET_IS_CONFIRM_OTP:
            return { 
                ...state, 
                isOtpVerify: action.payload
            }
        default :
        return state;
    }
}