import React, { Component } from 'react';
import { connect}  from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import ResetPasswordForm from './ResetPasswordForm';
import UPdatePassword from './updatePassword';
import { sendOTPForResetPassword, updateForResetPassword, clearResetPasswordData } from '../../../stores/actions';
import {  ResetpasswordSuccess} from './ResetpasswordSuccess';
import './style.scss';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" >
      <Paper {...props} />
    </Draggable>
  );
}

class  ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = { 
            loading: false,
            resetPasswordData: null 
        };
        this.resendOtp = this.resendOtp.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    handleSubmitResetForm (value) {
        this.props.sendOTPForResetPassword(value);
    }

    resendOtp() {
      let { resetPasswordData } = this.state;
      if( resetPasswordData ) {
        this.props.sendOTPForResetPassword({mobile: resetPasswordData.mobile});
      }
    }

    componentDidMount() {
      this.props.clearResetPasswordData(null);
    }
    
    handleSubmitUpdatePassword (value){
        let { resetPasswordData } = this.state;
        let isLogout = this.props.isLogout;
        let req = {...value, ...resetPasswordData, isLogout};
        this.props.updateForResetPassword(req);
    }

    onClose () {
      this.props.clearResetPasswordData();
      this.props.onClose();
    }
    static getDerivedStateFromProps( props) {
      return {...props};
    }

    render() {

    let { isOpen } = this.props;
    let { loading , resetPasswordData} = this.state;
    let isSuccessOtp = (resetPasswordData && resetPasswordData && resetPasswordData.mobile) ? true: false;
    let isSuccess = (resetPasswordData && resetPasswordData.isSuccess) ? true : false;
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown className ="reset-password" open = { isOpen } onClose = {  this.onClose } PaperComponent = { PaperComponent } aria-labelledby = "draggable-dialog-title">
            { !isSuccess && <>
            { !isSuccessOtp && <ResetPasswordForm onSubmit ={ this.handleSubmitResetForm.bind(this)} onClose ={ this.onClose } loading = { loading}/>}
            { isSuccessOtp && <UPdatePassword resendOtp ={ this.resendOtp } onSubmit = { this.handleSubmitUpdatePassword.bind(this)} onClose = { this.onClose } loading = { loading}/>}
            </>}
            { isSuccess && <ResetpasswordSuccess onClose = { this.onClose }/>}
        </Dialog>
    )
    }
} 

const mapStateToProps= state => {
  return ({
    resetPasswordData: state.resetPassword.request,
    loading:state.resetPassword.isLoading
  })
};

export default connect(mapStateToProps, {sendOTPForResetPassword, clearResetPasswordData, updateForResetPassword })(ResetPassword) 