export const History_data = [
{
    image: 'images/gallery/old1.jpg',
    // heading: '1st pic of old car May',
    date: "May 1988",
    text:[ "Linda the Lincoln named after Linda Blair from the movie The Exorcist was found in a field outside of Millbrook. Robert II and Robert III brought her home to start a father and son restoration project."]
},
{   image: 'images/gallery/old2.jpg',
    // heading: '2nds pic of old car ',
    date: "May 1988",
    text:[ "This rare 1956 Lincoln Premier has a 24 carrot gold plated knights head emblem on the trunk. Lifting on the helmet's shield gives access to the trunk key lock. this vehicle also came with automatic headlight dimming, power seats and windows, ivory dash knobs and steering wheel, and automatic steering component greasing system."]
},
{   image: 'images/gallery/old3.jpg',
    // heading: '3rd pic of old car ',
    date: "Oct 1990",
    text:[ "Vehicle is disassembled, and parts labeled. Engine is removed to wait to be rebuilt."]
},
{   image: 'images/gallery/old4.jpg',
    // heading: '4th pic of old car ',
    date: "Aug 1995",
    text:[ "We had to resource and locate parts including trip to Albany New York to retrieve parts car, and to Boonton New Jersey just outside New York City to purchase new parts from Kanter Auto Supply. Parts were all sand blasted and painted."]
},

{   image: 'images/gallery/old5.jpg',
    // heading: '5th pic of old car',
    date: "July 1996",
    text:[ "Hard to find panels were cut out of good parts car and refitted to Linda's body."]
},
{   image: 'images/gallery/old6.jpg',
    // heading: '6th pic of old car',
    date: "June 2005",
    text:[ "Linda rested in barn for almost 10 years while Robert III implemented emissions into family business and prepared to take over Bob Nurse Motors. During this time Robert II worked on the body and undercarriage of Linda."]
},
{   image: 'images/gallery/old7.jpg',
    // heading: 'Pic 7 of old car pic',
    date: "June 2005",
    text:[ "In June 2005 Linda's undercarriage re unites with her body and she sees sunlight for the first time in 17 years."]
},
{   image: 'images/gallery/old8.jpg',
    // heading: 'Pic 8 of old car pic',
    date: "Feb 2008",
    text:[ "Mark and Kate at Peterborough Machine rebuilds 368 ci. engine and Linda receives heart transplant."]
},
{   image: 'images/gallery/old9.jpg',
    // heading: 'Pic 9 of old car ',
    date: "Sep 2019",
    text:[ "Linda returns from beauty parlor in January 2019. Petes Autobody paints Linda and chrome begins to be reinstalled."]
},
{   image: 'images/gallery/old10.jpg',
    date: "September 2019",
    // heading: 'Pic 10 of old car pic',
    text:[ "Linda waits for more chroming and interior to finally meet her groom Jay Leno."]
}
]