import type from '../actionsType';

const doAddCustomer = (payload) => ({
    type: type.Do_ADD_CUSTOMER,
    payload
});

const onResetAddCustomer = () => ({
    type: type.SET_ADD_CUSTOMER,
    payload: false
});

const doCheckExistingCustomer = (payload) => ({
    type: type.DO_CHECK_EXISTING_CUSTOMER,
    payload
});


export {
    doAddCustomer,
    onResetAddCustomer,
    doCheckExistingCustomer
}