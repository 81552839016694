import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Row from '@material-ui/core/Grid';
import servicesImage from '../../../../assets/images/services.jpg';

class OurServices extends Component {
    render(){
        const style = { backgroundImage: `url(${servicesImage})`, backgroundPositionY: 'center'}
        return (
            <div className ="section border-bottom">
                <div className ="container">
                    <Row container  direction="row"  justify="space-between" alignItems="flex-start">
                        <Row item xs = { 12 } md= { 5}>
                            <div className ="image-box" style= {style}>

                            </div>
                            {/* <img className ="services-image" src= { servicesImage} alt= "servicesImage"/> */}
                        </Row>

                        <Row item xs = { 12 } md>
                            <Row container spacing ={ 2} direction="column" justify="space-between" alignItems="flex-start">
                                <Row item> <Typography variant="h3" className ="heading">Hours of Operation</Typography> </Row>
                                <Row item> <b>
                                    
                                We are conveniently open from 8:00 AM to 5:00 PM Monday to Friday.<br/>
A family tradition, providing honest reliable transportation needs for the motoring public of the Peterborough area since 1890.
</b> </Row>
                                {/* <Row item> 
                                    You're more than welcome to drop your car off before 
                                    your appointment. If dropping it off isn't an option we 
                                    would be happy to shuttle you to where ever you need 
                                    to go!

                                </Row> */}
                                {/* <Row item>
                                    We can also do small jobs (such as oil changes and Emission tests) while you wait!
                                </Row> */}
                            </Row>
                        </Row>
                        
                    </Row>
                    {/* <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <Row container  direction="column"  justify="flex-start"  alignItems="center" className ="text-center">
                        <Row item><Typography variant="h3" className ="heading">The Importance of Maintenance</Typography> </Row>
                        <Row item> Improper maintenance on vehicles can lead to costly repairs, we recommend you keep up-to-date with your vehicles needs and maintain proper maintenance schedules. Below are a few examples of what can go wrong with engines that are not properly maintained. </Row>
                    </Row> */}
                </div>
            </div>
        );
    }
}

export default OurServices;