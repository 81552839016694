import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { SvgIcon } from '@material-ui/core';
import BreadcrumbList from './breadcrumbList.jsx';

class Breadcrumbs extends  Component {
  constructor(props){
    super( props );
    this.state = {
      loginUser: null,
      linkButton: null
    };
  }

  /* Check props and update state */
  static getDerivedStateFromProps = ( props) => ({...props});

  render() {
    const { linkButton } = this.state;
    return(
        <Row className ="nav-list" container direction="row" justify="space-between" alignItems="center">
            <Row item> <BreadcrumbList/></Row>
            { (linkButton && linkButton.path) && <Row item className ="btn-link">
                <Button size ="small" component = { Link } to= {linkButton.path} color="primary" >
                    { linkButton.icon && <SvgIcon component= {linkButton.icon}/> } &nbsp;&nbsp;{linkButton.title}
                </Button>
            </Row>
            }
        </Row>
    )
  }
}

const mapStateToProps = ( state ) => {
  return {
      linkButton: state.breadcrumb.linkButton,
      loginUser: state.loginUser.loginUser
    }
}

export default connect(mapStateToProps)(Breadcrumbs);
