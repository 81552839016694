import axios from 'axios';
import './axios.interceptors';
  
export default class ApiService {
    constructor(baseUrl, authService) {
        this.baseUrl = baseUrl;
        this.authService = authService;
    }
    
    getURL(requestPath){
        let baseUrl = this.baseUrl;
        let PathName = `${baseUrl}/${requestPath}`;

        let URL = baseUrl.substring(baseUrl.length -1 ,baseUrl.length );
        if( URL === '/') {
            PathName = `${baseUrl}${requestPath}`;
        }
        return PathName;
    }
    
    async makeRequest(request) {
        try {
            const Xtoken = await this.authService.getAccessToken();
            let _request = request;
            _request.url = this.getURL(request.url)
            
            if( Xtoken) {
                _request.headers = { 
                    Authorization: `Bearer ${Xtoken.token}`,
                    'x-session': `${Xtoken.id}`,
                };
            }
            const req = await axios(_request);
            return req.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

}