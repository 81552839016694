import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { Banner, PhotoCard } from '../../shared';
import './gallery.scss';
import { Photos } from './gallery_data';
import Masonry from 'react-masonry-css'

class Gallery extends  Component {

  render () {

    return (
    <>
      <Banner data = {{image: '/images/bob-banner.jpg', text: 'Photo Gallery'}}/>
      <div className ="container">
              <div className ="image-gallery" >
              <Masonry breakpointCols={{default: 2  , 800: 2, 400: 1}} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
              {/* array of JSX items */}
              { Photos && Photos.map( (photo, i) => (
                    <PhotoCard data = { photo } key= { i}/>
              ))}
              </Masonry>
        </div>
      </div>
    </>)
  }
}

export default Gallery;
