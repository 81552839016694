
export const MenuItems ={
    home: { text :'Home', url:'/'},
    about: { text :'About Us', url:'/about-us'},
    photoGallery: { text :'Photo Gallery', url:'/photo-gallery'},
    ourHistory: { text :'1956 Lincoln', url:'/restoration-project'},
    services: { text :'Services', url:'/services'},
    contacts: { text :'Contact us', url:'/contact-us'},
    login:{ text: 'Login / Signup', url :'login'},
    // servicesWeOffer: { text: 'Services we Offer', url:'/services-we-offer'}
};