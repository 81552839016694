import { call, put, takeLatest } from "redux-saga/effects";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { apiService } from '../../../../services';
import type from '../../../actionsType';
import { modifyAddInvoiceRequest } from '../modifyRequest';

export  const CallAPI  = (request) => {
    let data = modifyAddInvoiceRequest (request);
    return apiService.makeRequest({
        method: 'POST',
        url:'invoice',
        data
    });
}
// SET_INVOICE_DATA
function* doInit(action) {
    let request = action.payload;
    if( request) {
        let invoiceDate = request.invoiceDate;
        if ( invoiceDate ) {
            let today = moment();
            invoiceDate = moment(invoiceDate);
            if ( invoiceDate.diff(today) >= 0)  {
                yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: 'You cannot enter a date in the future!.' } });
            } else {
                yield put({type: type.SET_ADD_INVOICE_LOADING, payload: true });
                try {
                    let response = yield call(CallAPI, action.payload);
                    yield put({type: type.SET_ADD_INVOICE_LOADING, payload: false });
                    if( response && response.success  === true ){
                        yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: response.message } });
                        yield put({type: type.SET_INVOICE_DATA, payload: true   });
                    } else {
                        let error = (response.errors && Array.isArray(response.errors)) ? response.errors[0] : response.errors;
                        if( error.indexOf('duplicate key error') >=0 ) {
                            error = 'Invoice id already exists please try and other id '
                        }
                        yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: error } });
                        yield put({type: type.SET_INVOICE_DATA, payload: false   });
                }


                } catch (error) {
                    yield put({type: type.SET_ADD_INVOICE_LOADING, payload: false });
                    yield put({type: type.SET_INVOICE_DATA, payload: false   });

                    yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: "invalid invoice information enter proper values" } });
                }
            }
        }
    }
  
    
}

export default function* watchGetUserInfo() {
    yield takeLatest(type.DO_ADD_INVOICE, doInit);
}
