import type from '../actionsType';

const sendOTPForResetPassword = (payload) => ({type: type.DO_SEND_OTP_FOR_RESET, payload : payload});
const updateForResetPassword = (payload) => ({type: type.DO_UPADTE_RESET_PASSWORD, payload : payload});
const clearResetPasswordData = (payload) => ({type: type.Do_CLEAR_DATA_FOR_RESETPASSWORD, payload : payload});

export {
    sendOTPForResetPassword,
    updateForResetPassword,
    clearResetPasswordData
}