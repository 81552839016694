import type from '../actionsType';

const defaultState = {
    request : null,
    isLoading : false,
    
}

export default (state = defaultState, action ) => {
    
    switch ( action.type) {
        case type.SET_DATA_FOR_RESETPASSWORD:
            return {
                ...state,
                request: action.payload
            }
        case type.SET_CLEAR_DATA_FOR_RESETPASSWORD:
            return {
                ...state,
                request: action.payload
            }

        case type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        default :
        return state;
    }
}