import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';

export  const onCallAPI  =(request) => {
    return apiService.makeRequest({
        method: 'POST',
        url:'users/forget-password',
        data: request
    });
}

function* onInit(action){
    yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: true });

    try {
    
        let response = yield call(onCallAPI, action.payload );
        
        yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: false });

        if( response && response.success === false) {
            let message = 'The mobile number you have entered is not registered with us';
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message } });

        } else {
            
            yield put({type: type.SET_DATA_FOR_RESETPASSWORD, payload: {mobile: action.payload.mobile }});
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: "OTP has been sent to on your mobile phone and registered email id" } });

        }

       } catch (error) {
            yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: false });
       }
}

export default function* watchDoResetpassword() {
    yield takeLatest(type.DO_SEND_OTP_FOR_RESET, onInit);
}
