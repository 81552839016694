import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';

export  const CallAPI  = (req) => {

    return apiService.makeRequest({
        method: 'GET',
        url:`customers/${req.mobile}`
    });

}

// SET_ADD_CUSTOMER
function* doInit(action) {
    yield put({type: type.SET_CUSTOMER_LOADING, payload: true });
    try {

        let response = yield call(CallAPI, action.payload);

        yield put({type: type.SET_CUSTOMER_LOADING, payload: false });

        if( response && response.success   === true ){
            let data = response && response.data; 
            yield put({type: type.SET_CUSTOMER_DEATILS, payload: data });
        } else {
            yield put({type: type.SET_CUSTOMER_DEATILS, payload: null   });
        }


       } catch (error) {
        yield put({type: type.SET_CUSTOMER_LOADING, payload: false });
        yield put({type: type.SET_CUSTOMER_DEATILS, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_GET_CUSTOMER_DETAILS, doInit);
}
