import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Row from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { reduxForm } from 'redux-form';
import { doAddVehicle } from '../../../stores/actions';
import AddVehicleForm from '../../forms/add-vehicle-form';

const style = {    
    borderBottom: '1px #c5c5c5 solid',
    paddingBottom: '10px',
    backgroundColor: '#ececec',
    cursor: 'move'
}

function PaperComponent(props) {
  return (
    <Draggable handle="#updateDetail" >
      <Paper {...props} />
    </Draggable>
  );
}

class  addVehicle extends Component {

    constructor(props) {
       super(props);
       this.state = { 
            isAddedVihicle: false,
            isLoading: false
        }
       this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        let { customer} = this.props;
        this.props.doAddVehicle({ customerId: customer._id, vehicle: e, page: customer.page, currentCustomer: customer.mobile } );
    }

    static getDerivedStateFromProps( props) {
        return {...props};
    }

    render() {

    const { isOpen, onClose  } = this.props;
    let { isLoading } = this.state;

    return (
        <Dialog className = "update-details" open = { isOpen } 
            PaperComponent = { PaperComponent } aria-labelledby="updateDetail">
            
                <DialogTitle style = { style } id = "updateDetail">
                <Row container  direction="row" justify="space-between"  alignItems="center">
                    <Row item >  Add Vehicle </Row>
                    <Row item > 
                        <IconButton aria-label="delete" onClick = { onClose } >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Row > 
                </Row>
                    
                </DialogTitle>
                
                <DialogContent> 
                    <AddVehicleForm  onSubmit = { this.onSubmit } loading = { isLoading } />
                </DialogContent>
        </Dialog>
    )
    }
}

const AddVeicleDaiglog = reduxForm({
    form: 'addVehicle',
})(addVehicle);

const mapDispatchToProps = { doAddVehicle };

const mapStateToProps= (state) => ({
    isLoading: state.addVehicle.isLoading,
    isAddedVihicle: state.addVehicle.addedVehicle
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVeicleDaiglog);
