import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ROUTER_INFO } from '../../routing/routerList';
import { connect } from 'react-redux';
import { onBreadcrumbButton , doSnakbar, doAddCustomer, doCheckExistingCustomer } from '../../../stores/actions';
import { reset } from 'redux-form';
import AddCustomerForm from '../../forms/addCustomerForm';
import VerifyCustomerForm from './verify-customer';
import AddVehicle from './add-vehicle';
import './add-customer.scss';

class AddCustomer extends  Component {

  constructor(props) {
    super( props );
    this.state = {
      activeStep: 0,
      isCustomer: false,
      hideVehicleForm: false,
      userData: {}
    };
    
    this.onSubmit = this.onSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onRemoveVehicle = this.onRemoveVehicle.bind(this);
    this.onFinalSubmit = this.onFinalSubmit.bind(this);
  }
  
  onSubmit(value) {
    let { userData, activeStep } = this.state;
    
    /* step 1 */
    if( value && Object.keys(value).length ===1 && value.mobileNumber) {
      activeStep = 1;
      // this.props.doCheckExistingCustomer(value);
      userData = {...userData, ...value};
    }
    /* Step 2 */
    if( value && value.companyName && value.email){
      activeStep = 2;
      userData = {...userData, ...value};
    }

    /* Step 3 */
    if( value && value.plateNumber && value.province && value.odometer){
      // activeStep = 2;
      // let vehicles = userData.vehicles || [];
      let vehicle= [];
      vehicle.push(value)
      userData.vehicles = vehicle;
      // userData.vehicles = [ ...vehicles, value];
    }
  
    /* Update State */
    this.setState({userData, activeStep});
  }

  onFinalSubmit() {
    let { userData } = this.state;
    this.props.doAddCustomer(userData);

  }

  onRemoveVehicle( index) {
    let { userData } = this.state;
    let vehicles = userData.vehicles;
    vehicles.splice(index, 1);
    this.setState({userData});
  }

  onBack( activeStep) {
    if( activeStep === 0 ) {
      this.setState({activeStep, userData: {}});
    } 
    if( activeStep === 1 ) {
      // let { userData } = this.state;
      this.setState({activeStep});
      // if( userData.vehicles) {
      //   this.setState({hideVehicleForm: true});
      // } else {
      //   this.setState({hideVehicleForm: false});
      // }
     
    } else {
      this.setState({activeStep});
    }
  }

  componentDidMount(){
    this.props.onBreadcrumbButton(ROUTER_INFO.customers);
  }

  componentWillUnmount() {
    this.props.onBreadcrumbButton(null);
  }
  static getDerivedStateFromProps = ( props) => ({...props});

  render () {
    let { activeStep, isLoading, userData , isCustomer} = this.state;
    if( activeStep === 0 && isCustomer === true ) {

      activeStep = 1;
    }
    console.log(activeStep , "activeStep")
    return(
    <Card className ="add-customer">
       <Stepper activeStep = { activeStep } completed="false" alternativeLabel>
          <Step>
            <StepLabel> Verify Customer </StepLabel>
          </Step>
          <Step>
            <StepLabel> Add Customer</StepLabel>
          </Step>

          <Step>
            <StepLabel> Add Vehicle</StepLabel>
          </Step>

        </Stepper>

      <CardContent className ="padding">
          { activeStep === 0 && <VerifyCustomerForm data ={ userData} onSubmit = { this.onSubmit } loading = { isLoading } />} 
          { activeStep === 1 && <AddCustomerForm onBack = { this.onBack } data ={ userData} onSubmit = { this.onSubmit } loading = { isLoading } />} 
          { activeStep === 2 && <AddVehicle 
            onFinalSubmit = {this.onFinalSubmit } 
            onRemoveVehicle = { this.onRemoveVehicle } 
            user = { userData } 
            
            onBack = { this.onBack } 
            onSubmit = { this.onSubmit } 
            loading = { isLoading } 
            />} 
      </CardContent>
    </Card>
    )
  }
}

const mapStateToProps = state => {
  // console.log(state);
  return { 
    isLoading: state.addCustomer.isLoading,
    isCustomer: state.addCustomer.isExist
  };
}
export default connect(mapStateToProps, { onBreadcrumbButton, doSnakbar, doAddCustomer, doCheckExistingCustomer,  reset})(AddCustomer);

