import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import Row from '@material-ui/core/Grid';
import offerImage from '../../../../assets/images/what-we-offer.png';

class WeOffer extends Component {
    render(){
        return (
            <div className ="section gray">
                <div className ="container">
                    <Row container  direction="row"  justify="space-between" alignItems="flex-start">
                        <Row item xs = { 12 }  md>
                            <Row container spacing = { 2 } direction="column" justify="space-between" alignItems="flex-start">
                                <Row item> <Typography variant="h3" className ="heading">What we Offer</Typography> </Row>
                                <Row item> <b>We offer a wide variety of services for all makes and models</b> </Row>
                                <Row item> 
                                    Fluid changes (oil, transmission, coolant) <br/>
                                    Tires and Alignments <br/>
                                    Advanced Check Engine Light Diagnostics<br/>
                                    Brakes and Brake Servicing<br/>
                                    General Repairs and Maintenance<br/>
                                </Row>
                                <Row item> <b>Check out our <Link to= '/services'>services</Link> page for more information</b></Row>
                            </Row>
                        </Row>
                        <Row item xs ={ 12 }  md= { 4 }>
                            <img className ="service" src= { offerImage} alt= "offerImage"/>
                        </Row>
                    </Row>
                </div>
            </div>
        );
    }
}

export default WeOffer;