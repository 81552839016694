import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';
import { vehicleModifyRequest } from '../modifyRequest';
import { config } from '../../../../config/config';
export  const CallAPI  = (data) => {
    data.vehicle = vehicleModifyRequest(data.vehicle);

    let _data = { };
    Object.assign(_data, data);
    delete _data.page;
    delete _data.currentCustomer;

    return apiService.makeRequest({
        method: 'POST',
        url:'vehicles',
        data: _data
    });
}

// SET_ADD_CUSTOMER
function* doInit(action) {
    yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: true });
    try {
        
        let response = yield call(CallAPI, action.payload);
        let request = action.payload;
        console.log(request, "slfkasl");
        yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: false });

        if( response && response.success   === true ){
            yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: true });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: response.message } });
            /* Reload Page after addeed */
            if( request.page  === 'listing') {
                yield put({type: type.DO_GET_CUSTOMER, payload: { limit: config.pageLimit }});
            }

            if( request.page  === 'detail') {
                yield put({type: type.DO_GET_CUSTOMER_DETAILS, payload: { mobile: request.currentCustomer }});
            }
        } else {
            yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: null   });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: response.errors } });
        }


       } catch (error) {
        yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: false });
        yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_ADD_VEHICLE, doInit);
}
