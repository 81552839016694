import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Routes } from './routing';
import {  Header , Footer} from './components/shared';

class CustomerApp extends  Component {
  constructor( props) {
    super( props );
    
    this.state = {
      loginUser: null
    }
  }
  static getDerivedStateFromProps(props){
    let update = {
      loginUser: props.loginUser
    };
    return update;
  }

  render(){
    return(
    <>
      <Header/>
      <div className ="wrapper">
        <Routes/>
        <Footer/>
      </div>
    </>
    )
  }
}
const mapStateToProps = state =>({
  loginUser: state.loginUser
});

export default connect(mapStateToProps, null)(CustomerApp);
