import React, {useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import {  validation ,ReactSelect, textField } from '../../formFields';
import { getProvinces , provinces, getCites, getSelectedCityList} from '../../data-set/canada-provinces';
import { getVehicleRequest } from './helpers';
const ProvinceSuggestions = getProvinces();

const years = () => {
    let d= new Date();
    let year= [];
    let y = d.getFullYear();
    for ( let i = 1930 ; i<= y; i++){
        year.push({label : i ,  value: i})
    };
    
    return year.reverse();
}

let AddVehicleForm = props => {
    const { handleSubmit , loading , userVehicles} = props;
    let [ CitySuggestions, setCitySuggestions ] = useState( []);
    
    const onSelectedState = (val) => {
        if( val && val.value) {
            let filter = [];
            provinces.forEach( list => {
                if( list.abbreviations === val.value) {
                    filter = list;
                }
            });
            let city = getCites(filter);
            setCitySuggestions(city);
        }
    }
    if( props.isPlain ) {
        if( props.submitting === false && props.dirty === false ) {
            props.initialize(userVehicles);
        }

    } else {
        if( userVehicles ) {
            // if( props.initialized === false )
            if( props.submitting === false && props.dirty === false ) {
            
                let __data = getVehicleRequest(userVehicles);
                let result = getSelectedCityList(__data.province.value);
                if( result) {
                    result = result[0];
                    if( result.city_list) {
                        CitySuggestions =  getCites(result) ;
                    }
                }
                
                props.initialize(__data);
            }
        }
    }

    return (
      <form onSubmit= { handleSubmit }  noValidate className ="border-box">
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="plateNumber"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Plate Number" />
            </Row>
            <Row xs item>
                <Field 
                    name="labRate"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Labour Rate" />
            </Row>
           
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
            <Field 
                    name="province"  
                    id ="state"
                    validate={[validation.required]}  
                    component={ReactSelect} 
                    suggestions ={ ProvinceSuggestions}
                    onChange = { onSelectedState }
                    label="Province" />

                </Row>
            <Row item xs>
                <Field 
                    name="city"
                    id= "city"  
                    validate={[validation.required]}  
                    component = { ReactSelect } 
                    suggestions ={ CitySuggestions}
                    label="City" />
               
                </Row>
        </Row>
        
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="vin"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="V.I.N" />
            </Row>

            <Row item xs>
            <Field 
                    name="odometer"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Odometer" />
            </Row>
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="make"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Make" />
            </Row>
            
            <Row item xs>
            <Field 
                    name="model" 
                    component={textField} 
                    label="Model" />

            
            </Row>
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="color"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Color" />
            </Row>
            <Row item xs>
            <Field 
                    name="body"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Body" />
            </Row>
        </Row>
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="tarns"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Trans" />
            </Row>
            <Row item xs>
            <Field 
                    name="engine"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Engine " />
            </Row>
        </Row>
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
            <Field 
                    name="year"  
                    id ="year"
                    validate={[validation.required]}  
                    component={ReactSelect} 
                    suggestions ={ years()}
                    label="Year" />
            </Row>
            <Row item xs>
            <Field 
                    name="unit"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Unit" />
                
            </Row>
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
            <Field 
                    name="driver"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Driver" />
            </Row>
            <Row item xs>
            <Field 
                    name="ac"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="A/C " />
            </Row>
        </Row>
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs ={12}>
            <Field 
                    name="notes" 
                    rows ={3} 
                    component={textField} 
                    label="Notes" />
            </Row>
        </Row>

        <Row container  direction="row" spacing = { 1 } justify="center"  alignItems="flex-start" className ="field">
            <Row item>
                <Button  size="large" className="loading-button" disabled = { loading }  variant="contained" color="primary" type="submit">
                    {!loading && <> { props.type ==='update' ? 'Update Vehicle' : 'Submit'}  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                </Button>
            </Row>
        </Row>
        
      </form>
    )
  }
  
  AddVehicleForm = reduxForm({
    form: 'AddVehicleForm',
  })(AddVehicleForm);
  

export default  AddVehicleForm;
