import React, { Component } from 'react';
import { connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Link }  from 'react-router-dom';
// import { getCustomerTypeByValue} from '../../../../data-set/customerType';
import { StayPrimaryPortrait, Call , Mail, Person, PinDrop, Commute } from '@material-ui/icons';

class ViewCustomerCard extends  Component {
  constructor(props){
    super( props );
    this.state = {
      isMenuOpen: null,
      onElement: null
    };
    this.onMenuClose = this.onMenuClose.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
  }
  
  onMenuClose() {
    this.setState({isMenuOpen: false});
  }

  onMenuOpen(e) {
    this.setState({ onElement: e.currentTarget, isMenuOpen: true});
  }
  
  render() {
    const { data } = this.props;
    // let createdAt = moment(data.created_at).format('MMM-DD-YYYY');

    return(
      <Card className ="customer-card">
        {/* <span className ="tag-right">Member since : {createdAt} </span> */}
        {/* {data && data.customerType && <span className ="tag-right type-2">Customer Type:  { getCustomerTypeByValue(data.customerType) }</span>} */}
        <CardContent >
          <Row container  direction="row" spacing = { 4 } justify="flex-start"  alignItems="stretch">
          <Row item className ="text-center">
                <Avatar src="./images/user.png" />
                <span className= "name"> {data.name ? `${data.name.firstName} ${data.name.lastName}`: 'N/A'} </span>
                <span className= "name"> {data.company } </span>
                <small> <PinDrop/>
                { data.address ? `${data.address.street}, ${data.address.city} ${data.address.state}, ${data.address.pincode} `:'N/A'}
                </small>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Contact Details </b> </Row>
                { data.otherNumber && <Row item> <StayPrimaryPortrait/> {data.otherNumber}</Row>}
                <Row item> <Call/> {data.mobile} </Row>
                <Row item> <Mail/> {data.email} </Row>
              </Row>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Login Details </b> </Row>
                <Row item> <Person/> {data.mobile} </Row>
                <Row item> <Mail/> {data.email} </Row>
                <Row item> <span className = { data.status === 'active' ?'active' : 'active error' } > {data.status } </span> </Row>
                <Row item> <Button  onClick ={ this.props.onUpdateDetails } size="small"  variant="contained" color="primary" > Update Details </Button></Row>
              </Row>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing = { 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Vehicle Details ( {data.userVehicles  && data.userVehicles.length} ) </b> </Row>
                  {data.userVehicles && data.userVehicles.length && data.userVehicles.map( (e,i) => (<Row item key ={i}> 
                  {  e.attrs && e.attrs.make && <span><Commute/> {e.attrs.make} </span>} {e.attrs && e.attrs.model && <span> - {e.attrs.model}  </span>}
                  </Row>))}
              </Row>
              <Row item>&nbsp;</Row>
              <Row item> <Button  onClick ={ this.props.onAddVehicle } size="small"  variant="contained" color="primary" > Add Vehicle </Button></Row>

            </Row>

            <Row item  className ="action-button">
              <Row container  direction="column" spacing = { 1 }  justify="center"  alignItems="flex-start" >
                <Row item> <b> Actions </b> </Row>
                <Row item> <Button  onClick = { this.props.onAddInvoice } size="small"  variant="contained" color="primary" > Add Invoice </Button></Row>
                <Row item> <Button  onClick = { ()=> this.props.onViewInvoice ( data._id, data.mobile ) } size="small"  variant="contained" color="primary" > View Invoice </Button></Row>
                <Row item> <Button  to = { `/view-RCPA/${data.mobile}/${data._id}` } component ={ Link }  size="small"  variant="contained" color="primary" >RCPA Summary </Button></Row>
                {/* <Row item> <Button  size="small" className ="error" variant="contained" color="primary" > Dactivate </Button></Row> */}
              </Row>
            </Row>
          </Row>
        </CardContent>
      </Card>
    )
  }
}

export default connect(null, null)(ViewCustomerCard);
