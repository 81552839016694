import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Row from '@material-ui/core/Grid';
import { LocalTaxi } from '@material-ui/icons';
import { onBreadcrumbButton, doGetInvoice , doGetCustomerDetails, onResetInvoiceData, doResetInvoice } from '../../../stores/actions';
import { withRouter } from "react-router";
import { orderBy } from 'lodash';
import { CustomerCard} from '../view-RCPA/component'
import { Loading } from "../../shared/loading";
import { ViewFilter } from '../view-RCPA/component';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import './style.scss';

class ViewInvoice extends  Component {
  constructor(props) {
    super( props );
    this.state = {
      invoiceList: null,
      customer: null,
      isLoading: false,
      invoiceListLoading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let { match, type } = this.props;
    this.props.onResetInvoiceData();
    this.props.doResetInvoice();
    if( !type ) {
      let rightButton = { title: 'View Customer', path:`/view-customer/${match.params.mobile}` };
      this.props.onBreadcrumbButton(rightButton);
      this.props.doGetCustomerDetails({mobile: match.params.mobile});
      
    } 
  }

  onSubmit( e ) {
    let { match , loginUser, type} = this.props;
    let customerId =  ( type && type.isImport ) ? loginUser._id : match.params.customerId;
    this.props.doGetInvoice(`customerId=${customerId}&grouped=year&vehicleId=${e.vehicle._id}`);
  }

  componentWillUnmount() {
    this.props.onBreadcrumbButton(null);
  }

  static getDerivedStateFromProps = ( props) => ({...props});

  render () {
    let { type, loginUser} = this.props;
    let { invoiceList, customer, isLoading, invoiceListLoading } = this.state;
      if( type && type.isImport  ) {
        customer = loginUser;
      }

      if( invoiceList && invoiceList.length > 0) {
        invoiceList = orderBy(invoiceList, ['_id'], ['desc']);
        invoiceList = invoiceList.filter( e=> e.records.length > 0);
      }

    return(<>
    {/* before loading */}

    { isLoading && <Loading/>}
    {/* after loading */}
    { !isLoading && <div>
      { (customer && !type) && <CustomerCard data = { customer}/> }
      

    {customer && <ViewFilter buttonLabel ={ 'View Invoice Details'} label = {  "Select vehicle you want to view invoice detail"  } onSubmit = { this.onSubmit } loading = { invoiceListLoading } data = { customer} />}
    <div className ="view-invoice">
      { invoiceListLoading && <Loading/>}
    </div>
    
    {!isLoading &&  invoiceList && invoiceList.length > 0 && <div>
      <h1 className ="heading-info"> Invoice Summary </h1>
      { invoiceList &&  invoiceList.length> 0 && invoiceList.map( (e, i) => (
      <div className ="invoice-row" key ={ i}> 
          <div  className ="group-line"> <span> {e._id} </span> </div>
          { e && e.records && e.records.length ===0  && <div >No record found on this year</div>}
          { e && e.records && e.records.map((list,j) => (
            <Card key ={`${i}-${j}`} className ="invoice-details">
              <Row container  direction="row"  justify="flex-start"  alignItems="center">
                <Row item xs ={ 4 } className="border-right"> 
                  <Row container direction="column" justify="center" alignItems="center">
                    <Row item><LocalTaxi/></Row>
                    <Row item> {list.vehicleDetails[0].attrs.make  } { list.vehicleDetails[0].attrs.model ? `-${list.vehicleDetails[0].attrs.model}`  : ''  } </Row>
                    <Row item> Color: {list.vehicleDetails[0].attrs.color} | Engine: {list.vehicleDetails[0].attrs.engine}</Row>
                    <Row item> Year: {list.vehicleDetails[0].attrs.year} | Body: {list.vehicleDetails[0].attrs.body}</Row>
                  </Row>  
                  </Row>  
                <Row item xs ={ 8 }>
                  <Row container  direction="row" justify="flex-start"  alignItems="stretch">
                    <Row item xs className ="border-right">
                      <Row container direction="column" justify="flex-start" alignItems="flex-start" className ="spcing" >
                        <Row item> Date: {moment(list.invoiceDate).format('MMM-DD-YYYY')} </Row>
                        <Row item> Invoice No: {list.invoiceId} </Row>
                        <Row item> Mileage: {list.attrs.mileage}</Row>
                        <Row item> <b>Free Oil: { list.amount.oilChangeAmount || 0}</b></Row>
                      </Row>
                    </Row>
                    <Row item xs>
                      <Row container direction="column" justify="flex-start" alignItems="flex-start" className ="spcing">
                        <Row item> Amount: {list.amount.total + list.amount.discount} </Row>
                        <Row item> Discount: {list.amount.discount}</Row>
                        <Row item> Total: {list.amount.total}</Row>
                      </Row>
                    </Row>
                    <Row item xs ={12} className ="margin-top"> Repair Description: {list.description}</Row>
                  </Row>
                </Row>  
              </Row>
            </Card>

            ))}
          
        </div>
      ))} 
    
    </div>}
    </div>}
    
    </>)
  }
}

const mapToStateProps =  state => {
  
  return {
    isLoading: state.viewCustomer.isLoading, 
    invoiceListLoading: state.addInvoice.invoiceListLoading, 
    invoiceList: state.addInvoice.invoiceList || [],
    customer: state.viewCustomer.customerDetails,
  }
}
export default connect(mapToStateProps, { onResetInvoiceData,doResetInvoice,onBreadcrumbButton, doGetInvoice , doGetCustomerDetails})(withRouter(ViewInvoice));
