import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';

export  const CallAPI  = (data) => {
    return apiService.makeRequest({
        method: 'POST',
        url:'customers',
        data
    });
}


function* doInit(action) {
    yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: true });
console.log(action, "sdlhfdsjk");
return ;
    try {
        let response = yield call(CallAPI, action.payload);
        console.log(response);
        yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: false });

        if( response && response.success  === true ){
            yield put({type: type.SET_CHECK_EXISTING_CUSTOMER, payload:  true});
        } else {
            let error = (response.errors && Array.isArray(response.errors)) ? response.errors[0] : response.errors;
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: error } });
            yield put({type: type.SET_CHECK_EXISTING_CUSTOMER, payload: false   });
        }

       } catch (error) {
        yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: false });
        yield put({type: type.SET_CHECK_EXISTING_CUSTOMER, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_CHECK_EXISTING_CUSTOMER, doInit);
}
