import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { SlickSlider , Testimonials} from '../../shared';
import { WeOffer, OurServices } from './components'; 
import './home.scss';

class HomePage extends  Component {
  render(){
    return(<>
    <SlickSlider/>
    <OurServices/>
    <div className ="container padding-v image-section">
      <Row container   direction="row"  justify="space-between" alignItems="flex-start">
        <Row item xs ={3} className ="row-1">
          <div className ="image-group">
            <img src= "./images/brand-1.jpg" alt="brand-1"/>
          </div>
        </Row>
        <Row item xs ={ 3} className ="row-1">
          <div className ="image-group">
            <img src= "./images/b4.png" alt="brand-1"/>
          </div>
        </Row>
        <Row item xs ={ 3} className ="row-1">
          <div className ="image-group">
            <img src= "./images/brand-3.jpg" alt="brand-1"/>
          </div>
        </Row>

        <Row item xs ={ 3} className ="row-1">
          <div className ="image-group">
            <img src= "./images/login-logo.jpg" alt="brand-1"/>
          </div>
        </Row>
      
      </Row>
    </div>
    <WeOffer/>
    <Testimonials/>
    </>)
  }
}

export default HomePage ;
