export const provinces =[
    { province: 'Alberta', abbreviations: 'AB',
        city_list:[
            { city: 'Airdrie'},
            { city: 'Brooks'},
            { city: 'Calgary'},
            { city: 'Camrose'},
            { city: 'Cold Lake'},
            { city: 'Edmonton'},
            { city: 'Fort Saskatchewan'},
            { city: 'Grande Prairie'},
            { city: 'Lacombe'},
            { city: 'Leduc'},
            { city: 'Lethbridge'},
            { city: 'Lloydminster'},
            { city: 'Medicine Hat'},
            { city: 'Red Deer'},
            { city: 'Spruce Grove'},
            { city: 'St. Albert'},
            { city: 'Wetaskiwin'}
        ]
    },
    { province: 'British Columbia', abbreviations: 'BC',
    city_list: [
                {city:'Abbotsford'},
                {city:'Armstrong'},
                {city:'Burnaby'},
                {city:'Campbell River'},
                {city:'Castlegar'},
                {city:'Chilliwack'},
                {city:'Colwood'},
                {city:'Coquitlam'},
                {city:'Courtenay'},
                {city:'Cranbrook'},
                {city:'Dawson Creek	'},
                {city:'Delta'},
                {city:'Duncan'},
                {city:'Enderby'},
                {city:'Fernie'},
                {city:'Fort St. John'},
                {city:'Grand Forks'},
                {city:'Greenwood'},
                {city:'Kamloops'},
                {city:'Kelowna'},
                {city:'Kimberley'},
                {city:'Langford'},
                {city:'Langley'},
                {city:'Maple Ridge'},
                {city:'Merritt'},
                {city:'Nanaimo'},
                {city:'Nelson'},
                {city:'New Westminster'},
                {city:'North Vancouver'},
                {city:'Parksville'},
                {city:'Penticton'},
                {city:'Pitt Meadows'},
                {city:'Port Alberni'},
                {city:'Port Coquitlam'},
                {city:'Port Moody'},
                {city:'Powell River'},
                {city:'Prince George'},
                {city:'Prince Rupert'},
                {city:'Quesnel'},
                {city:'Revelstoke'},
                {city:'Richmond'},
                {city:'Rossland'},
                {city:'Salmon Arm'},
                {city:'Surrey'},
                {city:'Terrace'},
                {city:'Trail'},
                {city:'Vancouver'},
                {city:'Vernon'},
                {city:'Victoria'},
                {city:'West Kelowna'},
                {city:'White Rock'},
                {city:'Williams Lake'},
        ]
    },
    { province: 'Manitoba', abbreviations: 'MB',
    city_list:[
                {city:'Brandon'},
                {city:'Dauphin'},
                {city:'Flin Flon'},
                {city:'Morden'},
                {city:'Portage la Prairie'},
                {city:'Selkirk'},
                {city:'Steinbach'},
                {city:'Thompson'},
                {city:'Winkler'},
                {city:'Winnipeg'},
        ]
    },
    { province: 'New Brunswick', abbreviations: 'NB',
    city_list: [
            {city: 'Bathurst'},
            {city: 'Campbellton'},
            {city: 'Dieppe	ward'},
            {city: 'Edmundston	ward'},
            {city: 'Fredericton'},
            {city: 'Miramichi'},
            {city: 'Moncton'},
            {city: 'Saint John'}
        ]
    },
    { province: 'Newfoundland and Labrador', abbreviations: 'NL',
    city_list:[
                {city:"Admirals Beach"},
                {city:"Anchor Point"},
                {city:"Appleton"},
                {city:"Aquaforte"},
                {city:"Arnold's Cove"},
                {city:"Avondale"},
                {city:"Badger"},
                {city:"Baie Verte"},
                {city:"Baine Harbour"},
                {city:"Bauline"},
                {city:"Bay Bulls"},
                {city:"Bay de Verde"},
                {city:"Bay L'Argent"},
                {city:"Bay Roberts"},
                {city:"Baytona"},
                {city:"Beachside"},
                {city:"Bellburns"},
                {city:"Belleoram"},
                {city:"Birchy Bay"},
                {city:"Bird Cove"},
                {city:"Bishop's Cove"},
                {city:"Bishop's Falls"},
                {city:"Bonavista"},
                {city:"Botwood"},
                {city:"Branch"},
                {city:"Brent's Cove"},
                {city:"Brighton"},
                {city:"Brigus"},
                {city:"Bryant's Cove"},
                {city:"Buchans"},
                {city:"Burgeo"},
                {city:"Burin"},
                {city:"Burlington"},
                {city:"Burnt Islands"},
                {city:"Campbellton"},
                {city:"Cape Broyle"},
                {city:"Cape St. George"},
                {city:"Carbonear"},
                {city:"Carmanville"},
                {city:"Cartwright"},
                {city:"Centreville-Wareham-Trinity"},
                {city:"Chance Cove"},
                {city:"Change Islands"},
                {city:"Channel-Port aux Basques"},
                {city:"Chapel Arm"},
                {city:"Charlottetown"},
                {city:"Clarenville"},
                {city:"Clarke's Beach"},
                {city:"Coachman's Cove"},
                {city:"Colinet"},
                {city:"Colliers"},
                {city:"Come By Chance"},
                {city:"Comfort Cove-Newstead"},
                {city:"Conception Bay South"},
                {city:"Conception Harbour"},
                {city:"Conche"},
                {city:"Cook's Harbour"},
                {city:"Cormack"},
                {city:"Corner Brook"},
                {city:"Cottlesville"},
                {city:"Cow Head"},
                {city:"Cox's Cove"},
                {city:"Crow Head"},
                {city:"Cupids"},
                {city:"Daniel's Harbour"},
                {city:"Deer Lake"},
                {city:"Dover"},
                {city:"Duntara"},
                {city:"Eastport"},
                {city:"Elliston"},
                {city:"Embree"},
                {city:"Englee"},
                {city:"English Harbour East"},
                {city:"Fermeuse"},
                {city:"Ferryland"},
                {city:"Flatrock"},
                {city:"Fleur de Lys"},
                {city:"Flower's Cove"},
                {city:"Fogo Island"},
                {city:"Forteau"},
                {city:"Fortune"},
                {city:"Fox Cove-Mortier"},
                {city:"Fox Harbour"},
                {city:"Frenchman's Cove"},
                {city:"Gallants"},
                {city:"Gambo"},
                {city:"Gander"},
                {city:"Garnish"},
                {city:"Gaskiers-Point La Haye"},
                {city:"Gaultois"},
                {city:"Gillams"},
                {city:"Glenburnie-Birchy Head-Shoal Brook"},
                {city:"Glenwood"},
                {city:"Glovertown"},
                {city:"Goose Cove East"},
                {city:"Grand Bank"},
                {city:"Grand Falls-Windsor"},
                {city:"Grand le Pierre"},
                {city:"Greenspond"},
                {city:"Hampden"},
                {city:"Hant's Harbour"},
                {city:"Happy Adventure"},
                {city:"Happy Valley-Goose Bay"},
                {city:"Harbour Breton"},
                {city:"Harbour Grace"},
                {city:"Harbour Main-Chapel's Cove-Lakeview"},
                {city:"Hare Bay"},
                {city:"Hawke's Bay"},
                {city:"Heart's Content"},
                {city:"Heart's Delight-Islington"},
                {city:"Heart's Desire"},
                {city:"Hermitage-Sandyville"},
                {city:"Holyrood"},
                {city:"Hopedale"},
                {city:"Howley"},
                {city:"Hughes Brook"},
                {city:"Humber Arm South"},
                {city:"Indian Bay"},
                {city:"Irishtown-Summerside"},
                {city:"Isle aux Morts"},
                {city:"Jackson's Arm"},
                {city:"Keels"},
                {city:"King's Cove"},
                {city:"King's Point"},
                {city:"Kippens"},
                {city:"L'Anse-au-Clair"},
                {city:"L'Anse-au-Loup"},
                {city:"Labrador City"},
                {city:"Lamaline"},
                {city:"Lark Harbour"},
                {city:"LaScie"},
                {city:"Lawn"},
                {city:"Leading Tickles"},
                {city:"Lewin's Cove"},
                {city:"Lewisporte"},
                {city:"Little Bay"},
                {city:"Little Bay East"},
                {city:"Little Bay Islands"},
                {city:"Little Burnt Bay"},
                {city:"Logy Bay-Middle Cove-Outer Cove"},
                {city:"Long Harbour-Mount Arlington Heights"},
                {city:"Lord's Cove"},
                {city:"Lourdes"},
                {city:"Lumsden"},
                {city:"Lushes Bight-Beaumont-Beaumont North"},
                {city:"Main Brook"},
                {city:"Makkovik"},
                {city:"Mary's Harbour"},
                {city:"Marystown"},
                {city:"Massey Drive"},
                {city:"McIvers"},
                {city:"Meadows"},
                {city:"Middle Arm"},
                {city:"Miles Cove"},
                {city:"Millertown"},
                {city:"Milltown-Head of Bay d'Espoir"},
                {city:"Ming's Bight"},
                {city:"Morrisville"},
                {city:"Mount Carmel-Mitchells Brook-St. Catherines"},
                {city:"Mount Moriah"},
                {city:"Mount Pearl"},
                {city:"Musgrave Harbour"},
                {city:"Musgravetown"},
                {city:"Nain"},
                {city:"New Perlican"},
                {city:"New-Wes-Valley"},
                {city:"Nipper's Harbour"},
                {city:"Norman's Cove-Long Cove"},
                {city:"Norris Arm"},
                {city:"Norris Point"},
                {city:"North River"},
                {city:"North West River"},
                {city:"Northern Arm"},
                {city:"Old Perlican"},
                {city:"Pacquet"},
                {city:"Paradise"},
                {city:"Parkers Cove"},
                {city:"Parson's Pond"},
                {city:"Pasadena"},
                {city:"Peterview"},
                {city:"Petty Harbour-Maddox Cove"},
                {city:"Pilley's Island"},
                {city:"Pinware"},
                {city:"Placentia"},
                {city:"Point au Gaul"},
                {city:"Point Lance"},
                {city:"Point Leamington"},
                {city:"Point May"},
                {city:"Point of Bay"},
                {city:"Pool's Cove"},
                {city:"Port Anson"},
                {city:"Port au Choix"},
                {city:"Port au Port East"},
                {city:"Port au Port West-Aguathuna-Felix Cove"},
                {city:"Port Blandford"},
                {city:"Port Hope Simpson"},
                {city:"Port Kirwan"},
                {city:"Port Rexton"},
                {city:"Port Saunders"},
                {city:"Portugal Cove South"},
                {city:"Portugal Cove–St. Philip's"},
                {city:"Postville"},
                {city:"Pouch Cove"},
                {city:"Raleigh"},
                {city:"Ramea"},
                {city:"Red Bay"},
                {city:"Red Harbour"},
                {city:"Reidville"},
                {city:"Rencontre East"},
                {city:"Renews-Cappahayden"},
                {city:"Rigolet"},
                {city:"River of Ponds"},
                {city:"Riverhead"},
                {city:"Robert's Arm"},
                {city:"Rocky Harbour"},
                {city:"Roddickton-Bide Arm"},
                {city:"Rose Blanche-Harbour le Cou"},
                {city:"Rushoon"},
                {city:"Sally's Cove"},
                {city:"Salmon Cove"},
                {city:"Salvage"},
                {city:"Sandringham"},
                {city:"Sandy Cove"},
                {city:"Seal Cove (Fortune Bay)"},
                {city:"Seal Cove (White Bay)"},
                {city:"Small Point-Adam's Cove-Blackhead-Broad Cove"},
                {city:"South Brook"},
                {city:"South River"},
                {city:"Southern Harbour"},
                {city:"Spaniard's Bay"},
                {city:"Springdale"},
                {city:"St. Alban's"},
                {city:"St. Anthony"},
                {city:"St. Bernard's-Jacques Fontaine"},
                {city:"St. Brendan's"},
                {city:"St. Bride's"},
                {city:"St. George's"},
                {city:"St. Jacques-Coomb's Cove"},
                {city:"St. John's"},
                {city:"St. Joseph's"},
                {city:"St. Lawrence"},
                {city:"St. Lewis"},
                {city:"St. Lunaire-Griquet"},
                {city:"St. Mary's"},
                {city:"St. Pauls"},
                {city:"St. Shott's"},
                {city:"St. Vincent's-St. Stephen's-Peter's River"},
                {city:"Steady Brook"},
                {city:"Stephenville"},
                {city:"Stephenville Crossing"},
                {city:"Summerford"},
                {city:"Sunnyside"},
                {city:"Terra Nova"},
                {city:"Terrenceville"},
                {city:"Tilt Cove"},
                {city:"Torbay"},
                {city:"Traytown"},
                {city:"Trepassey"},
                {city:"Trinity"},
                {city:"Trinity Bay North"},
                {city:"Triton"},
                {city:"Trout River"},
                {city:"Twillingate"},
                {city:"Upper Island Cove"},
                {city:"Victoria"},
                {city:"Wabana"},
                {city:"Wabush"},
                {city:"West St. Modeste"},
                {city:"Westport"},
                {city:"Whitbourne"},
                {city:"Whiteway"},
                {city:"Winterland"},
                {city:"Winterton"},
                {city:"Witless Bay"},
                {city:"Woodstock"},
                {city:"Woody Point"},
                {city:"York Harbour"},

        ]
    },
    { province: 'Nova Scotia', abbreviations: 'NS',
    city_list:[
            {city:"Amherst"},
            {city:"Annapolis Royal"},
            {city:"Antigonish"},
            {city:"Berwick"},
            {city:"Bridgewater"},
            {city:"Clark's Harbour"},
            {city:"Digby"},
            {city:"Kentville"},
            {city:"Lockeport"},
            {city:"Lunenburg"},
            {city:"Mahone Bay"},
            {city:"Middleton"},
            {city:"Mulgrave"},
            {city:"New Glasgow"},
            {city:"Oxford"},
            {city:"Pictou"},
            {city:"Port Hawkesbury"},
            {city:"Shelburne"},
            {city:"Stellarton"},
            {city:"Stewiacke"},
            {city:"Trenton"},
            {city:"Truro"},
            {city:"Westville"},
            {city:"Windsor"},
            {city:"Wolfville"},
            {city:"Yarmouth"}

        ]
    },
    { province: 'Ontario', abbreviations: 'ON',
    city_list:[
            {city:"Barrie"},
            {city:"Belleville"},
            {city:"Brampton"},
            {city:"Brant"},
            {city:"Brantford"},
            {city:"Brockville"},
            {city:"Burlington"},
            {city:"Cambridge"},
            {city:"Clarence-Rockland"},
            {city:"Cornwall"},
            {city:"Dryden"},
            {city:"Elliot Lake"},
            {city:"Greater Sudbury"},
            {city:"Guelph"},
            {city:"Haldimand County"},
            {city:"Hamilton"},
            {city:"Kawartha Lakes"},
            {city:"Kenora"},
            {city:"Kingston"},
            {city:"Kitchener"},
            {city:"London"},
            {city:"Markham"},
            {city:"Mississauga"},
            {city:"Niagara Falls"},
            {city:"Norfolk County"},
            {city:"North Bay"},
            {city:"Orillia"},
            {city:"Oshawa"},
            {city:"Ottawa"},
            {city:"Owen Sound"},
            {city:"Pembroke"},
            {city:"Peterborough"},

        ]
    },
    { province: 'Prince Edward Island', abbreviations: 'PE',
    city_list: [
            {city:"Charlottetown"},
            {city:"Summerside"},
            {city:"Alberton"},
            {city:"Borden-Carleton"},
            {city:"Cornwall"},
            {city:"Kensington"},
            {city:"North Rustico"},
            {city:"O'Leary"},
            {city:"Souris"},
            {city:"Stratford"},
            {city:"Three Rivers"},
            {city:"Tignish"},
            {city:"Abram-Village"},
            {city:"Afton"},
            {city:"Alexandra"},
            {city:"Annandale-Little Pond-Howe Bay"},
            {city:"Bedeque and Area"},
            {city:"Belfast"},
            {city:"Bonshaw"},
            {city:"Brackley"},
            {city:"Breadalbane"},
            {city:"Central Kings"},
            {city:"Central Prince"},
            {city:"Clyde River"},
            {city:"Crapaud"},
            {city:"Darlington"},
            {city:"Eastern Kings"},
            {city:"Greenmount-Montrose"},
            {city:"Hampshire"},
            {city:"Hazelbrook"},
            {city:"Hunter River"},
            {city:"Kingston"},
            {city:"Kinkora"},
            {city:"Linkletter"},
            {city:"Lot 11 and Area"},
            {city:"Malpeque Bay"},
            {city:"Meadowbank"},
            {city:"Miltonvale Park"},
            {city:"Miminegash"},
            {city:"Miscouche"},
            {city:"Morell"},
            {city:"Mount Stewart"},
            {city:"Murray Harbour"},
            {city:"Murray River"},
            {city:"New Haven-Riverdale"},
            {city:"North Shore"},
            {city:"North Wiltshire"},
            {city:"Northport"},
            {city:"Sherbrooke"},
            {city:"Souris West"},
            {city:"St. Felix"},
            {city:"St. Louis"},
            {city:"St. Nicholas"},
            {city:"St. Peters Bay"},
            {city:"Tignish Shore"},
            {city:"Tyne Valley"},
            {city:"Union Road"},
            {city:"Victoria"},
            {city:"Warren Grove"},
            {city:"Wellington"},
            {city:"West River"},
            {city:"York"},
            {city:"Stanley Bridge, Hope River, Bayview"},
            {city:"Cavendish and North Rustico"},

        ]
    },
    { province: 'Quebec', abbreviations: 'QC',
    city_list:[
            {city:"Acton Vale"},
            {city:"Alma"},
            {city:"Amos"},
            {city:"Amqui"},
            {city:"Asbestos"},
            {city:"Baie-Comeau"},
            {city:"Baie-D'Urfé"},
            {city:"Baie-Saint-Paul"},
            {city:"Barkmere"},
            {city:"Beaconsfield"},
            {city:"Beauceville"},
            {city:"Beauharnois"},
            {city:"Beaupré"},
            {city:"Bécancour"},
            {city:"Bedford"},
            {city:"Belleterre"},
            {city:"Beloeil"},
            {city:"Berthierville"},
            {city:"Blainville"},
            {city:"Boisbriand"},
            {city:"Bois-des-Filion"},
            {city:"Bonaventure"},
            {city:"Boucherville"},
            {city:"Lac-Brome"},
            {city:"Bromont"},
            {city:"Brossard"},
            {city:"Brownsburg-Chatham"},
            {city:"Candiac"},
            {city:"Cap-Chat"},
            {city:"Cap-Santé"},
            {city:"Carignan"},
            {city:"Carleton-sur-Mer"},
            {city:"Causapscal"},
            {city:"Chambly"},
            {city:"Chandler"},
            {city:"Chapais"},
            {city:"Charlemagne"},
            {city:"Châteauguay"},
            {city:"Château-Richer"},
            {city:"Chibougamau"},
            {city:"Clermont"},
            {city:"Coaticook"},
            {city:"Contrecoeur"},
            {city:"Cookshire-Eaton"},
            {city:"Côte Saint-Luc"},
            {city:"Coteau-du-Lac"},
            {city:"Cowansville"},
            {city:"Danville"},
            {city:"Daveluyville"},
            {city:"Dégelis"},
            {city:"Delson"},
            {city:"Desbiens"},
            {city:"Deux-Montagnes"},
            {city:"Disraeli"},
            {city:"Dolbeau-Mistassini"},
            {city:"Dollard-des-Ormeaux"},
            {city:"Donnacona"},
            {city:"Dorval"},
            {city:"Drummondville"},
            {city:"Dunham"},
            {city:"Duparquet"},
            {city:"East Angus"},
            {city:"Estérel"},
            {city:"Farnham"},
            {city:"Fermont"},
            {city:"Forestville"},
            {city:"Fossambault-sur-le-Lac"},

        ]
    },
    { province: 'Saskatchewan', abbreviations: 'SK',
    city_list:[
            {city:"Estevan"},
            {city:"Flin Flon (part)"},
            {city:"Humboldt"},
            {city:"Lloydminster (part)"},
            {city:"Martensville"},
            {city:"Meadow Lake"},
            {city:"Melfort"},
            {city:"Melville"},
            {city:"Moose Jaw"},
            {city:"North Battleford"},
            {city:"Prince Albert"},
            {city:"Regina"},
            {city:"Saskatoon"},
            {city:"Swift Current"},
            {city:"Warman"},
            {city:"Weyburn"},
            {city:"Yorkton"},

        ]
    },
    { province: 'Northwest Territories', abbreviations: 'SK',
    city_list: [
            {city: "Aklavik"},
            {city: "Behchokǫ̀"},
            {city: "Deline"},
            {city: "Enterprise"},
            {city: "Fort Good Hope"},
            {city: "Fort Liard"},
            {city: "Fort McPherson"},
            {city: "Fort Providence"},
            {city: "Fort Resolution"},
            {city: "Fort Simpson"},
            {city: "Fort Smith"},
            {city: "Gamèti"},
            {city: "Hay River"},
            {city: "Inuvik"},
            {city: "Norman Wells"},
            {city: "Paulatuk"},
            {city: "Sachs Harbour"},

        ]
    },
    { province: 'Nunavut', abbreviations: 'NU',
    city_list: [
            {city: "Arctic Bay"},
            {city: "Arviat"},
            {city: "Baker Lake"},
            {city: "Cambridge Bay"},
            {city: "Cape Dorset"},
            {city: "Chesterfield Inlet"},
            {city: "Clyde River"},
            {city: "Coral Harbour"},
            {city: "Gjoa Haven"},
            {city: "Grise Fiord"},
            {city: "Hall Beach"},
            {city: "Igloolik"},
            {city: "Iqaluit"},
            {city: "Kimmirut"},
            {city: "Kugaaruk"},
            {city: "Kugluktuk"}

        ]
    },
    { province: 'Yukon', abbreviations: 'YT',
    city_list: [
            {city:"Carmacks"},
            {city:"Dawson"},
            {city:"Faro"},
            {city:"Haines Junction"},
            {city:"Mayo"},
            {city:"Teslin"},
            {city:"Watson Lake"},
            {city:"Whitehorse"}
        ]
    }
];

export const getProvinces = () => {
    return provinces.map(  e=> ({ label: `${e.province}`, provinces: `${e.abbreviations}`,  value: e.abbreviations  }));
}

export const getSelectedState = (state) => {

    let states= getProvinces();
    return states.filter ( e => (e.value === state || e.label === state) ? e: null);
}

export const getSelectedCityList = (state) => {
    return provinces.filter(e=> e.abbreviations === state ? e : null);
}
export const getSlectedCity = (state, city) => {
    let abbreviations = state ?state.abbreviations : '';
    if( state ) {
        return {label: city, provinces:`${state.provinces} (${abbreviations})`,city};
    }
}

export const getCites = (data) => {
    let cities = [];
        let city = data.city_list.map( e=> ({ label: `${e.city}`, provinces: `${data.province} (${data.abbreviations})`,  value: e.city}));
        cities.push(...city);
    return cities;

}