import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';

export  const CallAPI  = (data) => {
    return apiService.makeRequest({
        method: 'DELETE',
        url:'vehicles',
        params: {customerId: data.customerId, vehicleId:data.vehicleId}
    });
}

// SET_ADD_CUSTOMER
function* doInit(action) {

    yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: true });
    try {

        let response = yield call(CallAPI, action.payload);
        
        console.log(response);
        yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: false });

        if( response && response.success   === true ){
            let data = response && response.data && response.data[0].records; 
            yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: data   });
            if( action.payload.mobile) {
                yield put({type: type.DO_GET_CUSTOMER_DETAILS, payload: { mobile: action.payload.mobile }});
            }
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: response.message } });
        } else {
            yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: null   });
        }

       } catch (error) {
        console.log(error , "trur");
        yield put({type: type.SET_ADDED_VEHICLE_LOADING, payload: false });
        yield put({type: type.SET_ADDED_VEHICLE_DATA, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_ADD_DELETE_VEHICLE, doInit);
}
