import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import FormHelperText from '@material-ui/core/FormHelperText';
const today = new Date();

export default class DateInput extends Component {

    render() {
      const  { input, label, id,disableFuture , disablePast, meta: { touched, error } } = this.props;
      if( !input.value  ) {
        input.onChange(today);
      }
       
        return (
        <FormControl error = { (touched && error ) ? true : false } 
              className= { "text-field " + ((touched && error ) ? 'error' : '')} fullWidth>
              <MuiPickersUtilsProvider utils = { DateFnsUtils } >

              <KeyboardDatePicker
                // views = { views }
                // format="MM-dd-yyyy"
                margin="normal"
                disableFuture = { disableFuture}
                disablePast = { disablePast}
                id= { 'id-'+ id }
                label = { label }
                value = { input.value ? input.value : today }
                onChange = {(val) =>  input.onChange(val)}
                KeyboardButtonProps={{
                'aria-label': 'id-'+ id ,
                }}
              />
              </MuiPickersUtilsProvider>
            
         <FormHelperText className = { "error-field " + ((touched && error) ? 'show': 'hide')}> 
         { label } is required 
         </FormHelperText>
       </FormControl>
        )
    }
}
