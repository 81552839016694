import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';

export  const CallAPI  = (request) => {
    return apiService.makeRequest({
        method: 'POST',
        url:'users/verify',
        data: request
    });
}

function* doInit(action){

    yield put({type: type.SET_EMAIL_VERIFICATION_LOADING, payload: true });

    try {
        
        let response = yield call(CallAPI, action.payload);

        // yield put({type: type.SET_EMAIL_VERIFICATION_LOADING, payload: false });
        // if( response ) {
        //     yield put({type: type.SET_IS_CONFIRM_OTP, payload: true });
        // }


       } catch (error) {
        //    console.log(error);
        //     yield put({type: type.SET_SIGNUP_LOADING, payload: false });
        //     yield put({type: type.SET_SIGNUP_MESSAGE, 
        //         payload: { type: 'error', message: 'The OTP entered is incorrect'} });
       }
}

export default function* watchGetUserInfo() {
    yield takeLatest(type.DO_SEND_EMAIL_VERIFICATION, doInit);
}
