import React  from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

export const textField = ({disabled,  input, rows, readOnly, label, type, meta: { touched, error } }) => { 
    return (

    <FormControl className={ "field-with-icon " + ((touched && error ) ? 'error' : '')} error ={ (touched && error ) ? true : false } fullWidth>
        <Grid container spacing={1} alignItems="flex-end" >
            <Grid item xs>
                <TextField  disabled= { disabled} label= { label } fullWidth  error ={ (touched && error ) ? true : false }
                    {...input}
                    readOnly = { readOnly }
                    multiline = {rows ?  true: false }
                    rows ={ rows }
                    type={type}
                />
            </Grid>
        </Grid>
        <div className ="error-field-root">
            <FormHelperText className={ "error-field " + ((touched && error) ? 'show': 'hide')}>{error}</FormHelperText>
        </div>
      </FormControl>
 )
}
  