import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { connect} from 'react-redux';
import Row from '@material-ui/core/Grid';
import { reset } from 'redux-form';
import { withRouter } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowRightAlt} from '@material-ui/icons';
import { doSnakbar, onResetAddCustomer} from '../../../stores/actions';
// import { VehicleCard } from './vehicle-list-card';
import { AddVehicleForm , VehicleCard }from '../Vehicles';
import AlertDialog from './success-info';

class AddVehicle extends  Component {
  constructor(props){
    super( props );
    this.state = {
        vehicles: [],
        showAddForm: false,
        isLoading: false,
        isSuccess: false,
        autoFillForm: null,

    };

    this.onSubmit= this.onSubmit.bind(this);
    this.onAdd= this.onAdd.bind(this);
    this.onEdit= this.onEdit.bind(this);
    this.onRemoveVehicle= this.onRemoveVehicle.bind(this);
    this.onBack= this.onBack.bind(this);
    this.handleClose= this.handleClose.bind(this);
  }

  onSubmit( value) {
    this.props.onSubmit(value);
    // let { vehicles } = this.state;
    // vehicles =  value ;
    this.props.doSnakbar({ type:'success', message: 'Vehicle has been successfully saved'});
    this.props.reset('AddVehicleForm');
    let vehicle = [];
    vehicle.push(value);
    this.setState({ vehicles: vehicle, showAddForm: false});
  }

  onAdd() {
    this.setState({ showAddForm: true});
  }

  onRemoveVehicle(i) {
    
    let { vehicles, showAddForm } = this.state;
      vehicles.splice(i,1);
      if( vehicles && vehicles.length === 0 ) {
        showAddForm = true;
      }
      this.setState({ vehicles, showAddForm});
      this.props.onRemoveVehicle(i);

  }
  componentDidMount(){
    let { vehicles } = this.state;
    let { user } = this.props;
    let _vehicle =  user && user.vehicles;
    if( _vehicle) {
      vehicles = user.vehicles;
    }
     if( (vehicles && vehicles.length === 0) || !_vehicle) {
      //  if( !_vehicle ) {
          this.setState({ showAddForm : true});
        // } 
    } else {
      this.setState({ showAddForm : false});
    }
  }

  onBack() {
    this.props.onBack(1);
    // this.props.onFillForm();
    // this.setState({ vehicles: null});
  }
  
  onEdit(index) {
    // console.log(e, "SDfds");
    let { vehicles,  user } = this.state;
    let autoFill= null;
    if( vehicles && vehicles.length) {
      autoFill = vehicles[ index]
    }
    else {
      autoFill = user.vehicles;
      autoFill = autoFill[index];

    }
    this.setState({showAddForm: true, autoFillForm:autoFill });
  }

  handleClose(type) {
      console.log(type);
      // this.setState({isSuccess: false});
      this.props.onResetAddCustomer();
      this.props.onBack(0);
      if( type === 'redirect') {
        this.props.history.push('/customers');
      }
  }

  static getDerivedStateFromProps = ( props) => ({...props});
  
  render() {
    let { isLoading , isSuccess , vehicles, showAddForm, autoFillForm } = this.state;
    let { user} = this.props;
    if( user && user.vehicles &&  user.vehicles.length) {
      vehicles = user.vehicles;
      // showAddForm = false;
    } else {

    }
    // if( vehicles && vehicles.length) {
    //   showAddForm = false;
    // }
    // console.log(hideVehicleForm, "hideVehicleForm", showAddForm, vehicles, user)

    return(
      <>
      {isSuccess && <AlertDialog isOpen = { isSuccess } handleClose = { this.handleClose}/>}
      <Row  spacing ={ 1 } container direction="column" justify="center" alignItems="center" className ="heading-small">
          { user && <Row item> Add Vehicle for { `${user.firstName} ${user.lastName ? user.lastName: ''} (${user.mobileNumber})` } &nbsp; <Button size= "medium"  onClick= { this.onBack} >Change</Button> </Row>}
      </Row>
      { vehicles && vehicles.map( (e,i)=>(<VehicleCard  onEdit ={ this.onEdit } isLoading ={ isLoading } row ={i} onRemoveVehicle ={ () => this.onRemoveVehicle(i) } key ={i} data ={e}/>))}

      { (vehicles && vehicles.length > 0) &&
        <Row container  direction="row" spacing = { 4 } justify="center"  alignItems="center" className ="margin-top">
            {/* <Row item>
                <Button  size="medium" onClick ={ this.onAdd}  disabled = { isLoading }  variant="contained" color="primary">
                  <Add/> &nbsp;  Add More 
                </Button>
            </Row> */}

            { !showAddForm && <Row item>
                <Button onClick = {this.props.onFinalSubmit} size="medium"  disabled = { isLoading }  variant="contained" color="primary" type="submit">
                    {!isLoading && <> Submit  &nbsp; <ArrowRightAlt/> </>  }
                    {isLoading && <><CircularProgress size = { 20 } /> &nbsp; Processing..</>}
                </Button>
            </Row>}
        </Row>
      }
    
    { showAddForm && <Card>
      <CardContent>
          { showAddForm && <AddVehicleForm isPlain= { true} userVehicles = {autoFillForm} onSubmit = { this.onSubmit } loading = { isLoading }  />} 
      </CardContent>
    </Card> }
    </>)
  }
}


const mapStateToProps = state => {
  return { 
    isLoading: state.addCustomer.isLoading,
    isSuccess: state.addCustomer.customer,
   };
}
export default connect(mapStateToProps, { doSnakbar,onResetAddCustomer,  reset })(withRouter(AddVehicle));

