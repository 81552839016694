
/* user and admin login */
const DO_LOGIN_USER = "DO_LOGIN_USER";
const DO_LOGOUT_USER = "DO_LOGOUT_USER";
const SET_LOGIN_USER = "SET_LOGIN_USER";
const SET_DO_LOGIN_LOADING = "SET_DO_LOGIN_LOADING";
const SET_LOGIN_ALERT_MESSAGE = "SET_LOGIN_ALERT_MESSAGE";

/* User signup */
const DO_SIGNUP_USER = "DO_SIGNUP_USER";
const SET_SIGNUP_USER = "SET_SIGNUP_USER";
const SET_SIGNUP_LOADING = "SET_SIGNUP_LOADING";
const SET_SIGNUP_MESSAGE = "SET_SIGNUP_MESSAGE";
const SET_IS_CONFIRM_OTP = "SET_IS_CONFIRM_OTP";
const DO_CONFIRM_OTP = "DO_CONFIRM_OTP";
const DO_RESET_SIGNUP = "DO_RESET_SIGNUP";

/* BreadCrumb */
const SET_BREADCRUMB_LINK_BUTTON = "SET_BREADCRUMB_LINK_BUTTON";


/* user profile */

const DO_SEND_EMAIL_VERIFICATION  = "DO_SEND_EMAIL_VERIFICATION";
const SET_USER_PROFILE_UPADTED  = "SET_USER_PROFILE_UPADTED";
const SET_EMAIL_VERIFICATION_LOADING  = "SET_EMAIL_VERIFICATION_LOADING";
const DO_GET_USER_PROFILE  = "DO_GET_USER_PROFILE";
const SET_USER_PROFILE_LOADING  = "SET_USER_PROFILE_LOADING";
const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";
const DO_UPDATE_USER_PROFILE = "DO_UPDATE_USER_PROFILE";

/* Snakbar */
const SET_SNAKBAR_INFO  = "SET_SNAKBAR_INFO";

/* Reset Password */
const DO_SEND_OTP_FOR_RESET ="DO_SEND_OTP_FOR_RESET";
const DO_UPADTE_RESET_PASSWORD ="DO_UPADTE_RESET_PASSWORD";
const SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING ="SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING";
const SET_DATA_FOR_RESETPASSWORD ="SET_DATA_FOR_RESETPASSWORD";
const Do_CLEAR_DATA_FOR_RESETPASSWORD ="Do_CLEAR_DATA_FOR_RESETPASSWORD";

/* Add customer  */
const Do_ADD_CUSTOMER ="Do_ADD_CUSTOMER";
const SET_ADD_CUSTOMER_LOADING ="SET_ADD_CUSTOMER_LOADING";
const SET_ADD_CUSTOMER ="SET_ADD_CUSTOMER";
const DO_CHECK_EXISTING_CUSTOMER ="DO_CHECK_EXISTING_CUSTOMER";
const SET_CHECK_EXISTING_CUSTOMER ="SET_CHECK_EXISTING_CUSTOMER";

/* View Customer */

const DO_GET_CUSTOMER ="DO_GET_CUSTOMER";
const SET_GET_CUSTOMER ="SET_GET_CUSTOMER";
const SET_CUSTOMER_LOADING ="SET_CUSTOMER_LOADING";
const SET_CUSTOMER_DEATILS ="SET_CUSTOMER_DEATILS";
const DO_GET_CUSTOMER_DETAILS ="DO_GET_CUSTOMER_DETAILS";

/* Add vehicle */
const DO_ADD_VEHICLE = "DO_ADD_VEHICLE";
const DO_UPDATE_VEHICLE = "DO_UPDATE_VEHICLE";
const SET_UPDATE_VEHICLE_LOADING = "SET_UPDATE_VEHICLE_LOADING";
const SET_UPDATEED_VEHICLE_INFO = "SET_UPDATEED_VEHICLE_INFO";
const DO_ADD_DELETE_VEHICLE = "DO_ADD_DELETE_VEHICLE";
const SET_ADDED_VEHICLE_DATA = "SET_ADDED_VEHICLE_DATA";
const SET_ADDED_VEHICLE_LOADING = "SET_ADDED_VEHICLE_LOADING";
const DO_GET_VEHICLE_LIST = "DO_GET_VEHICLE_LIST";
const SET_GET_VEHICLE_LIST = "SET_GET_VEHICLE_LIST";
const SET_GET_VEHICLE_LIST_LOADING = "SET_GET_VEHICLE_LIST_LOADING";


/* Invoice */
const DO_ADD_INVOICE = "DO_ADD_INVOICE";
const SET_ADD_INVOICE_RESET = "SET_ADD_INVOICE_RESET";
const SET_ADD_INVOICE_LOADING = "SET_ADD_INVOICE_LOADING";
const SET_INVOICE_DATA = "SET_INVOICE_DATA";

/* GET INVOICE DATA */
const DO_GET_OIL_CHANGE = "DO_GET_OIL_CHANGE";
const SET_OIL_CHANGE = "SET_OIL_CHANGE";
const DO_GET_INVOICE_DATA = "DO_GET_INVOICE_DATA";
const SET_LIST_INVOICE_DATA = "SET_LIST_INVOICE_DATA";
const SET_GET_INVOICE_DATA_LOADING = "SET_GET_INVOICE_DATA_LOADING";

export default {
    SET_LOGIN_USER,
    DO_LOGOUT_USER,
    SET_DO_LOGIN_LOADING,
    DO_LOGIN_USER,
    SET_LOGIN_ALERT_MESSAGE,

    DO_SIGNUP_USER,
    SET_SIGNUP_USER,
    SET_SIGNUP_LOADING,
    SET_SIGNUP_MESSAGE,
    SET_IS_CONFIRM_OTP,
    DO_CONFIRM_OTP,
    DO_RESET_SIGNUP,

    /* BreadCrumb */
    SET_BREADCRUMB_LINK_BUTTON,

    /* user profile */
    DO_SEND_EMAIL_VERIFICATION,
    SET_EMAIL_VERIFICATION_LOADING,
    SET_USER_PROFILE_UPADTED,
    DO_GET_USER_PROFILE,
    SET_USER_PROFILE_LOADING,
    SET_USER_PROFILE_DATA,
    DO_UPDATE_USER_PROFILE,

    /* Snakbar */
    SET_SNAKBAR_INFO,

    /* Reset Password */
    DO_SEND_OTP_FOR_RESET,
    SET_DATA_FOR_RESETPASSWORD,
    SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING,
    DO_UPADTE_RESET_PASSWORD,
    Do_CLEAR_DATA_FOR_RESETPASSWORD,

    /* Add customer  */
    Do_ADD_CUSTOMER,
    SET_ADD_CUSTOMER_LOADING,
    SET_ADD_CUSTOMER,
    DO_CHECK_EXISTING_CUSTOMER,
    SET_CHECK_EXISTING_CUSTOMER,

    /* View Customer */
    DO_GET_CUSTOMER,
    SET_CUSTOMER_LOADING,
    SET_GET_CUSTOMER,
    SET_CUSTOMER_DEATILS,
    DO_GET_CUSTOMER_DETAILS,

    /* Add vehicle */
    DO_ADD_VEHICLE,
    DO_GET_VEHICLE_LIST,
    SET_ADDED_VEHICLE_DATA,
    SET_ADDED_VEHICLE_LOADING,
    SET_GET_VEHICLE_LIST,
    SET_GET_VEHICLE_LIST_LOADING,
    
    /* Update vehicle */
    DO_UPDATE_VEHICLE,
    SET_UPDATE_VEHICLE_LOADING,
    SET_UPDATEED_VEHICLE_INFO,

    /* Delete Vehicle */
    DO_ADD_DELETE_VEHICLE,

    /* Invoice */
    DO_ADD_INVOICE,
    SET_ADD_INVOICE_RESET,
    SET_ADD_INVOICE_LOADING,
    SET_INVOICE_DATA,
    DO_GET_INVOICE_DATA,
    SET_GET_INVOICE_DATA_LOADING,
    SET_LIST_INVOICE_DATA,
    DO_GET_OIL_CHANGE,
    SET_OIL_CHANGE

}