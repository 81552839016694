import axios from 'axios';
import Store from '../stores';
import { doLogout } from '../stores/actions';
/**
 *
 * parse error response
 */
function parseError (messages) {

    // error
    if (messages) {
        /* autologout */
        if(messages && messages.error === 'Unauthorized' && messages.message === 'Expired token' && messages.statusCode === 401 ) {
            Store.dispatch(doLogout());
        }
      if (messages instanceof Array) {
        return Promise.reject({ messages: messages })
      } else {
        return Promise.reject({ messages: [messages] })
      }
    } else {
      return Promise.reject({ messages: ['Timeout: Token exprire'] })
    }
  }
  
  /**
   * parse response
   */
//   function parseBody (response) {
//   //  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
//     if (response.status === 200) {    
//       return response.data.result
//     } else {
//       return this.parseError(response.data.messages)
//     }
//   }

// response parse
axios.interceptors.response.use((response) => {
    return response
  }, error => {
    let msg = (error   && error.response && error.response.status ) || 'N/A';
    console.warn('Error status',  msg )
    // return Promise.reject(error)
    if (error.response) {
      return parseError(error.response.data)
    } else {
      return Promise.reject(error)
    }
  });