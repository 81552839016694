import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Popover from '@material-ui/core/Popover';
import  UserDropDown from './userdropdown';
import { UserInfo , Avatar } from '../../../components/shared';

class LoginInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            anchorEl : null,
            loginUser: props.user,
        }
        this.onClose = this.onClose.bind(this);
        this.handlisOpeneClose = this.isOpen.bind(this);
    }

    onClose() {
        this.setState( { anchorEl: null });
    }

    isOpen(e) {
        this.setState( { anchorEl: e.currentTarget }); 
    }


    render() {
        const { anchorEl, loginUser } = this.state;
     
        return (
        <>
        { (loginUser && loginUser.firstName) && 
            <Button className ="loginUser" onClick = {(e) => this.isOpen(e)}>
            <Row container  spacing ={1} direction="row" justify="space-between" alignItems="center">
                <Row item>
                    <Avatar first = { loginUser.firstName } last ={ loginUser.lastName} />
                </Row>
                <Row item className ="hide-sm">
                    <UserInfo user ={ loginUser }/>
                </Row>
                <Row item className ="hide-sm">
                    <KeyboardArrowDown/>
                </Row>
            </Row>
        </Button>
        }

        {/* Propover on hover */}
        <Popover
            open = { Boolean(anchorEl) }
            anchorEl = {anchorEl}
            id="usermenu"
            onClose = {this.onClose}
            anchorOrigin = {{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin = {{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <UserDropDown loginUser = { loginUser } onClose  = { this.onClose }/>
      </Popover>
        </>)
    }
}


export default  LoginInfo;