import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';

export  const onCallAPI  =(request) => {
    delete request.confirmPassword;
    delete request.isLogout;

    return apiService.makeRequest({
        method: 'PUT',
        url:'users/reset-password',
        data: request
    });
}

function* onInit(action){
    let request = {};
    Object.assign(request, action.payload);
    
    if( request.password !== request.confirmPassword ) {
        yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error',message:  "Password and confirm password does not match" } });
    } else {

    yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: true });

    try {
        
        let response = yield call(onCallAPI, request );
        
        yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: false });

        if( response && response.success === false) {
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message:'The OTP entered is incorrect' } });
        } else {
        
            yield put({type: type.SET_DATA_FOR_RESETPASSWORD, payload: {...action.payload, isSuccess: true }});
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: "Password has been successfully changed " } });
           
            if( action && action.payload && action.payload.isLogout ) {
                yield put({type: type.DO_LOGOUT_USER, payload: null} );
            }
        }

       } catch (error) {
            yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload: false });
       }
    }

}

export default function* watchDoUpadteResetpassword() {
    yield takeLatest(type.DO_UPADTE_RESET_PASSWORD, onInit);
}
