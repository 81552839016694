import type from '../actionsType';

const defaultState = {
    isInvoice : null,
    isLoading : false,
    alert: null,
    invoiceList: null,
    invoiceListLoading: false,
    oilChange: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        
        case type.SET_ADD_INVOICE_RESET:
            return { 
                ...state, 
                loginUser: action.payload
            }
        case type.SET_OIL_CHANGE:
            return { 
                ...state, 
                oilChange: action.payload
            }
        case type.SET_LIST_INVOICE_DATA:
            return { 
                ...state, 
                invoiceList: action.payload
            }
        case type.SET_INVOICE_DATA:
            return { 
                ...state, 
                isInvoice: action.payload
            }
        case type.SET_ADD_INVOICE_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        case type.SET_GET_INVOICE_DATA_LOADING:
            return { 
                ...state, 
                invoiceListLoading: action.payload
            }
        default :
        return state;
    }
}