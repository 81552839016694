import type from '../actionsType';

const defaultState = {
    isLoading : false,
    customers: null,
    customerDetails: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_GET_CUSTOMER:
            return { 
                ...state, 
                customers: action.payload
            }
        case type.SET_CUSTOMER_DEATILS:
            return { 
                ...state, 
                customerDetails: action.payload
            }
        case type.SET_CUSTOMER_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        default :
        return state;
    }
}