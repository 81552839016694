import { put, takeLatest } from "redux-saga/effects";
import type from '../../actionsType';

function* doInit(action){
    try {
            yield put({type: type.SET_DATA_FOR_RESETPASSWORD, payload:  null });
            yield put({type: type.SET_SEND_OTP_FOR_RESET_PASSWORD_LOADING, payload:  false });

       } catch (error) {
           console.error(error);
       }
}

export default function* watchDoResetPasswordData() {
    yield takeLatest(type.Do_CLEAR_DATA_FOR_RESETPASSWORD, doInit);
}
