import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { Banner, PhotoCard } from '../../shared';
import { History_data } from './history_data';
import './history.scss';

class History extends  Component {
  render () {
    return (
    <>
      <Banner data = {{image: '/images/bob-banner.jpg', text: '1956 Lincoln Restoration Project'}}/>
      <div className ="container">
        <div className ="history" >
                <Row container direction="row" justify="space-between" alignItems="flex-start">
                    { History_data.map ( (e, i ) => (
                      <Row key = { i } item xs = { 6 } className ="item-card">
                          <h4 className = "heading-top"> { e.heading } </h4>
                          <div className ="circle"></div>
                          <div className ="circle-line"></div>
                          <PhotoCard data = { e}/>
                    </Row>
                    )
                    )}
                </Row>
        
        </div>
      </div>
    </>)
  }
}

export default History;
