import React, {Fragment}  from 'react';
import List from '@material-ui/core/List';
import { Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { SvgIcon } from '@material-ui/core';
import { sidebar } from '../../routing/routerList';
// console.log(RouterList, "RouterList");
// let Routes = ROUTER_INFO.filter( e=> e.skip === true ? null: e);
// console.log(ROUTER_INFO, "ROUTER_INFO");
// let Routes =[...ROUTER_INFO];
export const Sidebar = (props ) =>{ 
  // console.log(Routes, "skdbksjdgsd");
  
  return (
    <div>
      <div />
      <Divider />
      <List>
        {sidebar.map((list, index) => (<Fragment  key={index}>
          <ListItem button component ={ Link } to ={ list.path}>
            <ListItemIcon><SvgIcon component= {list.icon}/></ListItemIcon>
            <ListItemText primary={list.title} />
          </ListItem>
          <Divider />
          </Fragment>
        ))}
      </List>
      
    </div>
) };