import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { onBreadcrumbButton, doGetInvoice , doGetCustomerDetails, doResetInvoice } from '../../../stores/actions';
import { ROUTER_INFO } from '../../routing/routerList';
import Tooltip from '@material-ui/core/Tooltip';
import Row from '@material-ui/core/Grid';
import { CustomerCard , ViewFilter, RCPAReport } from './component'
import { getFinalRCPAResult, getRCPAResult, Total, TotalDiscount , TotalFreeOil } from './helpers';
// import { Loading } from '../../shared';
import './style.scss';

class ViewRCPA extends  Component {
  constructor(props) {
    super( props );
    this.state = {
      invoiceList: null,
      customer: null,
      invoiceListLoading: false,
      finalRCPA: null,
      isRCPASummary : false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.finalRcpa = this.finalRcpa.bind(this);
    this.onFinalRCPASummary = this.onFinalRCPASummary.bind(this);
  }

  finalRcpa(type) {
    let { invoiceList } = this.state;
    // this.props.doResetInvoice(null);
    // let invoiceWithAllYears  = fillYears(invoiceList);
    let finalRCPA =  getFinalRCPAResult(invoiceList, 'final');
    if( type ==='clear'){
      this.setState({ finalRCPA: null, isRCPASummary: null});
    } else {
      this.setState({ finalRCPA, isRCPASummary: null});
    }
  }
  componentDidMount() {
    
    this.props.doResetInvoice(null);
    let { match} = this.props;
    let rightButton = ROUTER_INFO.customerDetails;
    rightButton.path = `/view-customer/${match.params.mobile}`;
    this.props.onBreadcrumbButton(rightButton);
    
    if( match && match.params) {
      this.props.doGetCustomerDetails({mobile: match.params.mobile});
    }
  }

  onSubmit(val) {
    if( val ) {
    this.props.doResetInvoice(null);
    this.setState({ isRCPASummary:  null});
    let vehicleId = val.vehicle && val.vehicle._id;
    let { match } = this.props;
     if( vehicleId && match.params.id ) {
       this.props.doGetInvoice(`customerId=${match.params.id}&grouped=year&vehicleId=${vehicleId}`);
    }
   }
  }

  onFinalRCPASummary() {
    let { match } = this.props;
    this.setState({ isRCPASummary:  true});
    this.props.doGetInvoice(`customerId=${match.params.id}`);
    /* get invoice details */
  }

  componentWillUnmount() {
    this.props.onBreadcrumbButton(null);
  }

  static getDerivedStateFromProps = ( props) => ({...props});

  render () {
   
    let { invoiceList, customer, invoiceListLoading ,isRCPASummary, finalRCPA} = this.state;
    // let invoiceWithAllYears  = fillYears(invoiceList);
    // console.log(invoiceWithAllYears, "invoiceList")
    let RCPA_RESULT = getRCPAResult(invoiceList);
    let { title, total, totalRCPA , RCPA } = RCPA_RESULT;
    let totalByVehicle, totalByYear, subTotalByYear =0 ,subTotalDiscountByYear= 0, subTotalFreeOilByYear = 0;
    if( invoiceList && invoiceList.totalByVehicle) {
      totalByVehicle = invoiceList.totalByVehicle;
    }
    if( invoiceList && invoiceList.totalByYear) {
      totalByYear = invoiceList.totalByYear;
      totalByYear.forEach( e=> {
        subTotalByYear = subTotalByYear + e.Total;
        subTotalDiscountByYear = subTotalDiscountByYear + e.discount;
        subTotalFreeOilByYear = subTotalFreeOilByYear + e.freeOil;
      });
    }

    return (
    <>
    {/* {invoiceListLoading &&  <Loading/>} */}
    <div className ="container RCPA-customer">
        { customer && <CustomerCard data = { customer} />}
        { customer && <ViewFilter isFinalRCPA ={ true } onFinalRCPASummary = { this.onFinalRCPASummary} buttonLabel = { 'View RCPA Summary'}  buttonLabel1 = { 'RCPA Summary Report'}  label ={  "Select Vehicle you want to generate RCPA report"  } onSubmit = { this.onSubmit } loading = { invoiceListLoading } data = { customer} />}
        
        { !isRCPASummary && <div className ="rcpa-report">
          <Row container  direction="row" spacing = { 3} justify="space-between"  alignItems="flex-start">
            <Row item xs= { finalRCPA ? 6 : 12}>
              { invoiceList && <RCPAReport finalRcpa = { this.finalRcpa } title= {title } data = { RCPA } total = { total} totalRCPA = { totalRCPA}/>}
            </Row>

            { finalRCPA && finalRCPA.title && <Row item xs = {6}>
              <RCPAReport  title= {finalRCPA.title } data = { finalRCPA.RCPA } total = { finalRCPA.total} totalRCPA = { finalRCPA.totalRCPA}/>
            </Row>}
          </Row>
          { Boolean(total) && <div className ="btn-full">
          
          <Tooltip title="For the customer who has not visited in the last 2 years">
              <Button  onClick = {() =>  this.finalRcpa((finalRCPA && finalRCPA.total) ? 'clear': 'cal') } size ="large"  variant="contained" color="primary" > 
                {(finalRCPA && finalRCPA.total) ?  'Clear Final RCPA Summary ': 'View Final RCPA Summary' }
              </Button>
          </Tooltip>
        </div> }

        </div>}

        { isRCPASummary && <div className ="rcpa-report">
          <Row container  direction="row" spacing = { 3} justify="space-between"  alignItems="flex-start">
            <Row item xs ={ 6}>
            { totalByVehicle && totalByVehicle.map( (e,i)=> (<div key ={i}>
                <div className ="heading-line">
                  <strong>
                  {(e.vehicleDetails && e.vehicleDetails.length > 0 && e.vehicleDetails[0].attrs.make) || 'N/A'} 
                 { e.vehicleDetails && e.vehicleDetails.length > 0 && e.vehicleDetails[0].attrs.model && <span> - {e.vehicleDetails[0].attrs.model} </span>}
                 </strong>
                 </div>
                 <Row container  direction="row" spacing = { 3} justify="space-between"  alignItems="flex-start">
                   <Row item xs ={ 12 }> 

                   <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell> Years</TableCell>
                                <TableCell align="right"> Discount</TableCell>
                                <TableCell align="right"> Free Oil</TableCell>
                                <TableCell align="right"> Total </TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                { e && e.records.map( (e, i) => (<TableRow key = { i }>
                                    <TableCell>{e.year}</TableCell>
                                    <TableCell align="right"> ${TotalDiscount(e.records) || 0}</TableCell>
                                    <TableCell align="right"> ${TotalFreeOil(e.records) || 0}</TableCell>
                                    <TableCell align="right"> ${Total(e.records) || 0}</TableCell>
                                 
                                </TableRow>))}

                                <TableRow className ="table-footer">
                                    <TableCell>Sub Total </TableCell>
                                    <TableCell align="right">${e.discount || 0} </TableCell>
                                    <TableCell align="right">${e.freeOil || 0} </TableCell>
                                    <TableCell align="right">${e.total || 0} </TableCell>
                                   
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer>
                   </Row>
                  </Row>

            </div>))}
            </Row>
            { Boolean(subTotalByYear) && <Row item xs = {6} className ="push-top">
                <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell> Years</TableCell>
                                <TableCell align="right"> Total </TableCell>
                                <TableCell align="right"> Total Discount</TableCell>
                                <TableCell align="right"> Total Free Oil</TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                { totalByYear && totalByYear.map( (e, i) => (<TableRow key = { i }>
                                    <TableCell>{e.year}</TableCell>
                                    <TableCell align="right"> ${Total(e.records)}</TableCell>
                                    <TableCell align="right"> ${TotalDiscount(e.records)}</TableCell>
                                    <TableCell align="right"> ${TotalFreeOil(e.records)}</TableCell>
                                </TableRow>))}

                                <TableRow className ="table-footer">
                                    <TableCell>Sub Total </TableCell>
                                    <TableCell align="right">${ subTotalByYear } </TableCell>
                                    <TableCell align="right">${ subTotalDiscountByYear } </TableCell>
                                    <TableCell align="right">${ subTotalFreeOilByYear } </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer>
            </Row>}
          </Row>
        </div>}

    </div>
    </>)
  }
}

const mapToStateProps =  state => {
  return {
    invoiceList: state.addInvoice.invoiceList ,
    invoiceListLoading: state.addInvoice.invoiceListLoading,
    customer: state.viewCustomer.customerDetails,
  }
}
export default connect(mapToStateProps, { doGetInvoice, onBreadcrumbButton, doGetCustomerDetails, doResetInvoice })(ViewRCPA);
