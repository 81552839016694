import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';
import { modifyRequest } from '../modifyRequest';

export  const CallAPI  = (request) => {
    let data = modifyRequest (request);
    
    return apiService.makeRequest({
        method: 'POST',
        url:'customers',
        data
    });
}
// SET_ADD_CUSTOMER
function* doInit(action) {
    yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: true });
    try {
        let response = yield call(CallAPI, action.payload);
        yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: false });

        if( response && response.success  === true ){
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'success', message: response.message } });
            yield put({type: type.SET_ADD_CUSTOMER, payload: true   });
        } else {
            let error = (response.errors && Array.isArray(response.errors)) ? response.errors[0] : response.errors;
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: error } });
            yield put({type: type.SET_ADD_CUSTOMER, payload: false   });
        }


       } catch (error) {
        yield put({type: type.SET_ADD_CUSTOMER_LOADING, payload: false });
        yield put({type: type.SET_ADD_CUSTOMER, payload: false   });
       }
}

export default function* watchGetUserInfo() {
    yield takeLatest(type.Do_ADD_CUSTOMER, doInit);
}
