import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { config } from '../../../helpers';
import { LocalPhone, Email } from '@material-ui/icons';
import { Banner } from '../../shared';
import './contracts.scss';

class Contracts extends  Component {
  render() {
    return (
    <>
      <Banner data = {{image: '/images/bob-banner.jpg', text: 'Contact Us'}}/>
      <div className ="section contact-us">
            <div className ="container">
                <div>&nbsp;</div>
                <Row container spacing ={5} direction="row"  justify="space-between" alignItems="stretch">
                    <Row item xs = { 12} sm = {12} md >
                        <Row container spacing ={ 2} direction="column" justify="space-between" alignItems="flex-start">
                            <Row item><Typography variant="h3" className ="heading"> Location </Typography></Row>
                            <Row item> { config.address }</Row>
                        </Row>
                    </Row>

                    <Row item xs = { 12} sm = {12} md  className ="border-left">
                        <Row container spacing ={ 2} direction="column" justify="space-between" alignItems="flex-start">
                            <Row item><Typography variant="h3" className ="heading"> Get In Touch </Typography></Row>
                            <Row item> <Button color="primary"> <LocalPhone/>&nbsp; {config.phone} </Button> <Button color="primary"> <Email/> &nbsp;{config.email}</Button></Row>
                            <Row item > Call us anytime between 8:00 am - 5:00 pm<br/> to ask a question or book an appointment.</Row>
                        
                        </Row>
                    </Row>
                </Row>
                {/* location map  */}
                <div className ="location-map">
                    <iframe title = {config.address} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5711.7157135941725!2d-78.324394!3d44.292312!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d58ce3a0bdf957%3A0xf97b5a9af27962e3!2s20%20Park%20St%20S%2C%20Peterborough%2C%20ON%20K9J%203R4!5e0!3m2!1sen!2sca!4v1580299202790!5m2!1sen!2sca" ></iframe>
                </div>

            </div>
      </div>
    </>)
  }
}

export default Contracts;
