import watchDoLogin from './login/doLogin';
import watchDoLogout from './login/doLogout';
import watchDoSignup from './signup/doSignup';
import watchDoResetSignup from './signup/resetSignup';
import watchDoConfirmOtp from './signup/doConfirmOtp';
import watchDoSendEmailVerification from './user-profile/send-email-verification';
import watchGetUserInfo from './user-profile/getUserProfile';
import watchUpdateUserInfo from './user-profile/updateUserProfile';
import watchDoResetpassword from './reset-password/resetPassword';
import watchDoUpadteResetpassword from './reset-password/upadatePassword';
import watchDoResetPasswordData from './reset-password/clearDataResetPassword';
import watchDoAddCustomer from './customer/add-customer/addCustomer';
import watchDoCheckExistingCustomer from './customer/add-customer/checkExistingCustomer';
import watchDoGetCustomer from './customer/view-customer/getCustomer';
import watchDoGetCustomerDetails from './customer/view-customer/getCustomerDetails';
import watchAddNewVehicle from './customer/add-vehicle/add-vehicle';
import watchAddInvoice from './customer/add-invoice/addInvoice';
import watchGetInvoice from './customer/add-invoice/getInvoiceList';
import watchViewVehicle from './customer/add-vehicle/view-vehicle';
import watchUpdateVehicle from './customer/add-vehicle/updateVehicle';
import watchDeleteVehicle from './customer/add-vehicle/deleteVehicle';
import watchGetOilChange from './customer/add-invoice/getOilList';

export default function* rootSaga() {

  yield* watchDoLogin();
  yield* watchDoLogout();
  yield* watchDoSignup();
  yield* watchDoResetSignup();
  yield* watchDoConfirmOtp();
  yield* watchDoSendEmailVerification();
  yield* watchGetUserInfo();
  yield* watchUpdateUserInfo();
  yield* watchDoResetpassword();
  yield* watchDoUpadteResetpassword();
  yield* watchDoResetPasswordData();
  yield* watchDoAddCustomer();
  yield* watchDoCheckExistingCustomer();
  yield* watchDoGetCustomer();
  yield* watchDoGetCustomerDetails();
  yield* watchAddNewVehicle();
  yield* watchAddInvoice();
  yield* watchGetInvoice();
  yield* watchViewVehicle();
  yield* watchUpdateVehicle();
  yield* watchDeleteVehicle();
  yield* watchGetOilChange();

  
}
