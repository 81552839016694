export const config = {
    phone :'705-743-8800',
    email:'Netarf@bobnursemotors.ca',
    address: '20 Park Street South, Peterborough Ontario, K9J 3R5',


    socialProfile: {
        facebook: { image: '/images/facebook.svg',  title : "Facebook", url: 'https://www.facebook.com/BobNurseMotors/' },
        // google:  { image: '/images/google-plus.svg', title : "Google+",  url: 'https://plus.google.com/+BobNurseMotorsPeterborough/about' },
    }
}