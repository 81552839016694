import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { validation, ReactSelect, textField } from '../../../formFields';
import { customerTypeList ,OilCountPerCustomer, customerTypeListCheck } from '../../../data-set/customerType';

const vehicleList = (list) =>{
    if( list) {
        return list.map( e=> ({...e, label:  (e && e.attrs ? `${e.attrs.make} - ${ e.attrs.model ? e.attrs.model : '' }`: null ), value: e.plateNumber}));
    }
} 

let AddInvoiceForm = props => {
    const [ isOilChange, setisOilChange ] = React.useState(false);
    const { handleSubmit , loading, customer } = props;
    let vehicle = customer &&  customer.userVehicles;
    let vehicleLists = vehicleList(vehicle);
    
    let customerTypeLists = customerTypeList;
    const onOilChange = (e) => {
        if( e && (e.value === '5')){
            setisOilChange(true);
        } else {
            setisOilChange(false);
        }
    }

    /* default selected dropdrown */
    if( props.oilChange ) {
        let oilChange = OilCountPerCustomer(props.oilChange);
        if( oilChange ) {
            customerTypeLists = customerTypeListCheck(oilChange.value);
        }

    }
        return (
      <form onSubmit = { handleSubmit }  noValidate className ="border-box">
          <Field 
            name="vehicle"  
            id ="vehicle"
            // onChange ={ onChangeVehicleList}
            validate = {[validation.required]}  
            component = {ReactSelect} 
            suggestions = { vehicleLists  }
            label = "Vehicle List" />

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs = {12}>
                <Field 
                    name = "invoiceDate"  
                    id ={'date-1'}
                    validate = {[validation.required]}  
                    component = { textField } 
                    label = "Date ( MM-DD-YYYY )" />
            </Row>

        </Row>
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="invoiceId"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Invoice Number" />
            </Row>
            <Row xs item>
                <Field 
                    name="mileage"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Mileage" />
            </Row>
           
        </Row>

        
        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs = {12}>
                <Field 
                    name="description" 
                    validate = {[validation.required]}  
                    component = {textField} 
                    rows = { 3 }
                    label="Repair Description" />
            </Row>

            
        </Row>

        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            { props.invoiceListLoading &&  <div className ="field-loading"><CircularProgress size={20} /> </div> }
            <Row item xs ={ isOilChange ? 6 : 12}>
                <Field 
                    name="hasOilChanged"  
                    id ="hasOilChanged"
                    component = { ReactSelect } 
                    onChange = { onOilChange}
                    suggestions = { customerTypeLists }
                    label="Oil Change" />
            </Row>
            { isOilChange && 
            <Row item xs ={ 6} className ="margin-top-6">
                <Field 
                    name="oilChangeAmount"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Oil Change Price" />
            </Row>
            }
        </Row>


        <Row  spacing ={ 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs>
                <Field 
                    name="cost"  
                    validate={[validation.required]}  
                    component={textField} 
                    label="Amount" />
            </Row>
            <Row item xs>
            <Field 
                    name="discount"  
                    component={textField} 
                    label="Discount" />
            </Row>
        </Row>

        <Row  spacing = { 5 } container direction="row" justify="space-between" alignItems="flex-start" className ="field">
            <Row item xs ={ 12}>
                <Field 
                    name="total"  
                    id ="total"
                    validate={[validation.required]}  
                    component = { textField } 
                    readOnly = { true }
                    label="Total Amount" />
            </Row>
        </Row>
        
        
        <Row container  direction="row" spacing = { 1 } justify="center"  alignItems="flex-start" className ="field">
            <Row item>
                <Button  size="large" className="loading-button" disabled = { loading }  variant="contained" color="primary" type="submit">
                    {!loading && <> Add Invoice  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                </Button>
            </Row>
        </Row>
        
      </form>
    )
  }
  
  AddInvoiceForm = reduxForm({
    form: 'AddInvoiceForm',
  })(AddInvoiceForm);
  
  const mapStateToProps= (state) => {
    return({
        invoiceListLoading: state.addInvoice.invoiceListLoading || false,
        oilChange: state.addInvoice.oilChange,
    })
}
export default connect(mapStateToProps, {})(AddInvoiceForm);

