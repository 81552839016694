import React, { Component } from 'react';
import { connect}  from 'react-redux';
import Snackbars from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { doSnakbar } from '../../../stores/actions';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

class Snackbar extends  Component {
    constructor( props) {
        super(props);
        this.state ={
            open: false,
            snakbar: null
        }
        this.handleClose= this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({open: false});
        this.props.doSnakbar(null);

    }
    
    static getDerivedStateFromProps( props) {
        return {...props};
    }

  render() {

    const { open, snakbar } = this.state;
    return(
        <>
        { open && 
        <Snackbars anchorOrigin = { { vertical: 'bottom', horizontal: 'right' } } open={open} autoHideDuration={7000} onClose={this.handleClose}>
            <Alert onClose={ this.handleClose } severity= { snakbar.type}> { snakbar.message } </Alert>
        </Snackbars>
        }
        </>)
  }
}
const mapStateToProps = state => {
        return ({
            snakbar : state.snakbar,
            open: (state.snakbar && state.snakbar.type) ? true: false
        });
}
export default connect(mapStateToProps, { doSnakbar })(Snackbar) ;
