import type from '../actionsType';

const doLogin = (payload) => ({
    type: type.DO_LOGIN_USER,
    payload
});

const doLogout = () => ({type: type.DO_LOGOUT_USER, payload : null});

export {
    doLogin,
    doLogout
}