import React, { Component } from 'react';
import './styles.scss';

class Dashbaord extends  Component {
  constructor(props){
    super( props );
    this.state = {
    };
  }

  render(){
    return(
    <>
    <h4 className= "heading">Admin can add customer by filling the form in 3 steps:</h4>
    <p>1. Verify customer</p>
    <p>2. Add customer</p>
    <p>3. Add vehicle</p>

<p><b>In Verify customer –</b> Admin should put valid mobile number as it will be used during Login.</p>

<p><b>Add Customer –</b> Admin must fill the customer registration form. Here, Email id should also be filled correctly because a confirmation mail will be sent from Bob Nurse Motors.</p>

<p><b>Add vehicle –</b> Admin will fill the vehicle registration form. Then all details will be saved.</p>

<h4 className= "heading">This way a customer and his vehicle will be added in the database.</h4>

<p>Admin can view all the customers.</p>

<p><b>By clicking on Action button –</b> we can select (add vehicle, add invoice, view invoice and RCPA summary)</p>

<p><b>Add vehicle –</b> We can add multiple vehicles one by one.</p>

<p><b>Add invoice –</b> We can select the vehicle from list and create their invoice.</p>

<p><b>View invoice –</b> We can select vehicle from the list and can view the invoices of that vehicle.</p>

<p><b>RCPA summary button- </b>We can view the RCPA of the vehicles.</p>

< p><b>RCPA summary report button –</b> By this we can view all the discounts and free oil that customer received.</p>
     </>
    )
  }
}

export default Dashbaord ;
