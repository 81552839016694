import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';
import { modifyCustomerRequest } from '../customer/modifyRequest';
import history from '../../../config/history';
export  const CallAPI  = (data) => {
    let _data = modifyCustomerRequest(data);
    return apiService.makeRequest({
        method: 'PUT',
        url:'customers',
        data: _data
    });
}

function* doInit(action){
    yield put({type: type.SET_USER_PROFILE_LOADING, payload: true });
    try {
        console.log(action.payload);
        let response = yield call(CallAPI, action.payload);
        let request = action.payload;
        
        yield put({type: type.SET_USER_PROFILE_LOADING, payload: false });
        console.log(response, "response")
        if( response && response.success  === true) {
            // let _data = response && response.data;
           
            yield put({type: type.SET_USER_PROFILE_UPADTED, payload:  true });

            if( request && request.role ==='admin') {
                history.push(`/view-customer/${request.mobile}`);
                yield put({type: type.DO_GET_CUSTOMER_DETAILS, payload: { mobile: request.mobile }});
                yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'success', message: "Customer details has been successfully update"}});
            } else {

                yield put({type: type.DO_GET_USER_PROFILE});
                yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'success', message: "Your account details has been successfully updated"}});
            }
        }
        else {
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'error', message: "Please try again"}});
        }

       } catch (error) {
            yield put({type: type.SET_USER_PROFILE_LOADING, payload: false });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'error', message: "Please try again"}});
       }
}

export default function* watchDoInit() {
    yield takeLatest(type.DO_UPDATE_USER_PROFILE, doInit);
}
