import React  from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ArrowRightAlt, Lock} from '@material-ui/icons';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { validation , textField } from '../../../formFields';
import { connect}  from 'react-redux';
// pristine, submitting
let ResetPasswordForm = props => {
    const { handleSubmit , loading , onClose, initialValues} = props;
    
    return (
      <form onSubmit = { handleSubmit } noValidate >
                <DialogTitle style= {{ cursor: 'move' }} id="draggable-dialog-title">
                <div  className ="info">
                        <Lock/>
                        <h2> Reset Password  </h2>
                    </div>
                </DialogTitle>
                <DialogContent> 
                    <Field 
                    name="mobile"
                    disabled ={  initialValues && initialValues.mobile ? true: false}
                    validate={[validation.required]}  
                    component={textField} 
                    label="Enter your registered Mobile number" />
                
                </DialogContent>
                
                <DialogActions>
                    <Button   className="loading-button" disabled={loading}  variant="contained" color="primary" type="submit">
                    {!loading && <> Submit  &nbsp; <ArrowRightAlt/> </>  }
                    {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>}
                    </Button>
                    <Button autoFocus onClick={ onClose } color="primary" variant="contained"> Close </Button>
                </DialogActions>
            </form>
    )
  }
  
  ResetPasswordForm = reduxForm({
    form: 'ResetPasswordForm',
  })(ResetPasswordForm);
  
  // You have to connect() to any reducers that you wish to connect to yourself
  ResetPasswordForm = connect(
  state => ({
    initialValues: { mobile: (state.loginUser && state.loginUser.loginUser ) ? state.loginUser.loginUser.mobile : null} // pull initial values from account reducer
  })
)(ResetPasswordForm)

export default  ResetPasswordForm;
