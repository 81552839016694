import React, { Component } from 'react';
import BreadcrumLinks from '@material-ui/core/Breadcrumbs';
import Item from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { SvgIcon } from '@material-ui/core';
import { withRouter } from "react-router";
// import { findByPath } from '../../routing/routerList';

class BreadcrumbList extends  Component {
  constructor(props){
    super( props );
    this.state = {
      links: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.onGenerateLinks = this.onGenerateLinks.bind(this);
  }

  handleClick() {
  }

  onGenerateLinks() {
    // let { history} = this.props;
    // if( history ) {
    //   let links = findByPath(history.location.pathname);
    //   links =   links.filter( e=> {
    //    console.log(e);
    //    if( e.path.indexOf(':')) {
    //      return false;
    //    } else {
    //      return true;
    //    }
    //   })
     let links = [ { title: 'Dashbaord', path: '/'}];
      this.setState({ links});
    // }
  }
  componentDidMount() {
    
    this.props.history.listen(this.onGenerateLinks);
    this.onGenerateLinks();
  }
  /* Check props and update state */
  static getDerivedStateFromProps = ( props) => ({...props});

  render(){
    const { links } = this.state;
    return(
          <>
            { links && <BreadcrumLinks className ="link-list"  aria-label="breadcrumb">
                { links.map( (e, i) => 
                (<Item component ={ Link } key ={ i } color="inherit" to ={ e.path} > 
                { e.icon && <SvgIcon component= {e.icon}/>} &nbsp;{e.title}</Item>))}
            </BreadcrumLinks>}
            </>
    )
  }
}

const mapStateToProps = ( state ) => {
  return { loginUser: state.loginUser.loginUser}
}

export default connect(mapStateToProps)(withRouter(BreadcrumbList));
