import React, { Component } from 'react';
import { clientTestimonials } from '../../../helpers';
import Slider from "react-slick";
import Typography from '@material-ui/core/Typography';
import { NavigateNext, NavigateBefore} from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import  './slider.scss';

const NextArrow = ( props) => {
    const { onClick } = props;
    return (
        <Fab  color="primary" className ="next-btn" size="small" onClick = { onClick }> <NavigateNext fontSize="large"/> </Fab>
    )
}

const PrevArrow = ( props) => {
    const { onClick } = props;
    return (
        <Fab  color="primary" className ="prev-btn"  size="small" onClick = { onClick }> <NavigateBefore fontSize="large"/> </Fab>
    )
}
class Testimonials extends  Component {
  render() {
    let settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed:7000,
        speed: 500,
        slidesToShow:3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
         
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            }
          ]
      };

    return(
        
        <div className ="client-testimonials">
            <div className ="container">
                <Typography variant="h2" className ="heading">What our client says</Typography>
                <Slider {...settings}>
                    { clientTestimonials && clientTestimonials.map( (item ,i) => (
                        <div key = { i }>
                            <div className ="client-panel">
                                <div className ="panel-body">
                                <img src= { item.image} alt ={ item.name}/>
                                <p>{item.text}</p>
                                <div className ="client-info">
                                    <strong>{item.name}</strong>
                                    <small>{item.location}</small>
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
  }
}

export default Testimonials ;
