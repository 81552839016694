import { put, takeLatest } from "redux-saga/effects";
import type from '../../actionsType';

function* doResetWatch(action){
    try {
            yield put({type: type.SET_SIGNUP_USER, payload:  null });
            yield put({type: type.SET_SIGNUP_MESSAGE, payload:  null });
            yield put({type: type.SET_SIGNUP_LOADING, payload:  false });
            yield put({type: type.SET_IS_CONFIRM_OTP, payload:  null });

       } catch (error) {
           console.error(error);
       }
}

export default function* watchDoResetSignup() {
    yield takeLatest(type.DO_RESET_SIGNUP, doResetWatch);
}
