import React, { Component } from 'react';
import { connect} from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { Link }  from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { AddVeicleDaiglog } from '../../Vehicles';
import { AddInvoiceDaiglog } from '../../add-invoice';
import { doSnakbar ,onResetAddedVehicle, onResetInvoiceData } from '../../../../stores/actions';

class ActionMenu extends  Component {
  constructor(props) {
    super(props);

    this.state = { 
      isOpen : false,
      isInvoice : false,
      isOpenAddedVehicle: false,
      isOpenAddedInvoice: false
    }

    this.onOpenAddVehicle = this.onOpenAddVehicle.bind(this);
    this.onOpenInvoice = this.onOpenInvoice.bind(this);
    this.onToggleVehicle = this.onToggleVehicle.bind(this);
    this.onOpenViewInvoice = this.onOpenViewInvoice.bind(this);
  }

  onToggleVehicle() {
    this.setState({isOpen: false,isInvoice : false });
  }

  onOpenAddVehicle() {
    this.props.onResetAddedVehicle();
    this.props.onMenuClose();
    this.setState({isOpen: true});
  }
  onOpenViewInvoice() {
    this.props.onMenuClose();
  }
  
  onOpenInvoice() {
    this.props.onMenuClose();
    this.props.onResetInvoiceData();
    let { activeCustomer } = this.props;
    let vehicle = activeCustomer &&  activeCustomer.userVehicles;
    if( vehicle && Array.isArray(vehicle) && vehicle.length ===  0) {
        this.props.doSnakbar({type: 'error', message : "You don't have added any vehicle"});
    } else {
      this.setState({isInvoice: true});
    }
  }
  
  static getDerivedStateFromProps( props) {
    return {...props};
  }

  render() {
    let { isMenuOpen, onElement , onMenuClose, activeCustomer } = this.props;
    let { isOpenAddedVehicle, isOpen, isInvoice, isOpenAddedInvoice } = this.state;
    if( activeCustomer && Object.keys(activeCustomer).length) {
      activeCustomer.page = 'listing';
    }
    
    if( isOpenAddedVehicle) {
      isOpen = false;
    }
    if( isOpenAddedInvoice ) {
      isInvoice = false;
    }
    return(
    <>
      { (activeCustomer && activeCustomer._id && this.state.isInvoice) && <AddInvoiceDaiglog customer = { activeCustomer } isOpen = { isInvoice } onClose = { this.onToggleVehicle } /> }
      
      {  (activeCustomer && activeCustomer._id  && isOpen) && <AddVeicleDaiglog customer = { activeCustomer} isOpen = { isOpen }  onClose = { this.onToggleVehicle } />}

      {/* menu list on action */}
    
     { isMenuOpen &&  <Popper placement = { 'bottom-end' } open = { isMenuOpen } anchorEl = { onElement } role = { undefined } transition >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={onMenuClose}>
                  <MenuList autoFocusItem={isMenuOpen} id="menu-list-grow" >
                    <MenuItem onClick={this.onOpenAddVehicle}> Add Vehicle </MenuItem>
                    <MenuItem onClick={this.onOpenInvoice}> Add Invoice </MenuItem>
                    <MenuItem onClick={this.onOpenViewInvoice} component ={ Link } to= {`/view-invoice/${activeCustomer._id}/${activeCustomer.mobile}`}> View Invoice </MenuItem>
                    {/* { activeCustomer &&  activeCustomer.status &&  <MenuItem onClick = {onMenuClose}> { activeCustomer.status === "active" ?  'Deactivate': 'Active'}</MenuItem>} */}
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> }

    </>
    )
  }
}

const mapStateToProps= (state) => ({
  isOpenAddedInvoice:  state.addInvoice.isInvoice,
  isLoading: state.addVehicle.isLoading,
  isOpenAddedVehicle: state.addVehicle.addedVehicle
})
export default connect(mapStateToProps , {doSnakbar, onResetAddedVehicle, onResetInvoiceData})(ActionMenu);
