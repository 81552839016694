import { getSelectedState, getSlectedCity } from '../../data-set/canada-provinces';



export const getVehicleRequest = (userVehicles) => {
    let state = getSelectedState(userVehicles.address.state);
    let city =null;
    if( state ) {
        state = state ? state[0]: null;
        city = getSlectedCity(state, userVehicles.address.city);
    }
    return {
        plateNumber:  userVehicles.plateNumber,
        labRate: userVehicles.labRate,
        vin: userVehicles.vin,
        odometer: userVehicles.odometer,
        make: userVehicles.attrs.make,
        model: userVehicles.attrs.model,
        color: userVehicles.attrs.color,
        body: userVehicles.attrs.body,
        tarns: userVehicles.attrs.tarns,
        engine: userVehicles.attrs.engine,
        ac: userVehicles.attrs.ac,
        unit: userVehicles.unit,
        driver: userVehicles.driver,
        notes: userVehicles.notes,
        province: state,
        city:city? city : null,
        year: { 
            value: userVehicles.attrs.year, 
            label: userVehicles.attrs.year 
        }
    }
}