import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import type from '../../actionsType';

export  const CallAPI  = () => {
    return apiService.makeRequest({
        method: 'GET',
        url:'users/profile',
    });
}

function* doInit(action){

    yield put({type: type.SET_USER_PROFILE_LOADING, payload: true });

    try {
        
        let response = yield call(CallAPI, action.payload);

        yield put({type: type.SET_USER_PROFILE_LOADING, payload: false });
        if( response && response.success  === true) {
            let _data = response && response.data;
            // _data = {..._data };

            // if( _data && _data.name ) {
            //     let _name = _data.name.split(' ');
            //     _data.firstName = _name[0];
            //     _data.lastName = _name[1] || null;
            // }

            yield put({type: type.SET_USER_PROFILE_DATA, payload: _data});
        }
        else {
            yield put({type: type.SET_USER_PROFILE_LOADING, payload: false });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'error', message: "Please try again"}});
        }

       } catch (error) {
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'error', message: "Please try again"}});
       }
}

export default function* watchDoInit() {
    yield takeLatest(type.DO_GET_USER_PROFILE, doInit);
}
