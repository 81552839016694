import { 
    Dashbaord,
    AddCustomer,
    Customers,
    ViewCustomerDetails,
    ViewInvoice,ViewRCPA
} from '../components';

import { Inbox , GroupAdd, People } from '@material-ui/icons';
export const sidebar =[
    { title: 'Dashbaord', path: '/', icon: Inbox , skip: true  },
    { title: 'Add Customer', path: '/add-customer',   icon: GroupAdd },
    { title: 'Customers', path: '/customers', icon: People },
    

]
export const ROUTER_INFO = {
    customers:   { title: 'Customers', path: '/customers', icon: People },
    customerDetails: { title: 'View Customer', path: '/view-customer/:id', icon: People, skip: true },
    customerInvoice: { title: 'View Customer', path: '/view-invoice/:customerId/:mobile', icon: People, skip: true },
    viewRCPA: { title: 'View RCPA', path: '/view-RCPA/:mobile/:id', icon: People, skip: true },
    addcustomer: { title: 'Add Customer', path: '/add-customer',   icon: GroupAdd },
    dashbaord:   { title: 'Dashbaord', path: '/', icon: Inbox , skip: true  }
}

export const RouterList = [
    { ...ROUTER_INFO.dashbaord , component: Dashbaord },
    { ...ROUTER_INFO.addcustomer,  component: AddCustomer},
    { ...ROUTER_INFO.customers, component: Customers },
    { ...ROUTER_INFO.customerDetails, component : ViewCustomerDetails },
    { ...ROUTER_INFO.customerInvoice, component : ViewInvoice },
    { ...ROUTER_INFO.viewRCPA, component : ViewRCPA }
];

export const findByPath = ( path ) => {
    let obj;
    let __path = path;
    let _path =path.split('/');
    _path = _path.filter(e=> e? e:  null);
    if( _path && _path.length > 1) {
        __path = _path[0];
    } 

    Object.keys(ROUTER_INFO).forEach( key=> {
        let item = ROUTER_INFO[key];
        
        if( item.path.indexOf( __path) >= 0  ) {
            obj = item;
            // obj.path = path;
        }
    });

    let arr= [];
    if(path !== '/' ) {
        arr.push(ROUTER_INFO.dashbaord);
    }
    if( obj && Object.keys(obj).length) {
        arr.push(obj);
    }
    return arr;
}