import React  from 'react';
import Row from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
let style = {
    fontStyle: 'italic',
    fontSize: '13px'
}

export const Loading = (props ) => (
    <Row  spacing ={ 2 }   container direction="row" justify="center"  alignItems="center">
        <Row item><CircularProgress size = { 20 }  color="primary" /> </Row>
        <Row item style ={style }>Loading...</Row>
    </Row>
  );