import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';

export  const CallAPI  = (data) => {
    // let _data = {};
    // Object.assign(_data, data);
    // delete _data.page;
    // delete _data.currentCustomer;

    return apiService.makeRequest({
        method: 'GET',
        url:'vehicles',
        // data: _data
    });
}

// SET_ADD_CUSTOMER
function* doInit(action) {
    yield put({type: type.SET_GET_VEHICLE_LIST_LOADING, payload: true });
    try {
        
        let response = yield call(CallAPI, action.payload);
        // let request = action.payload;
        
        yield put({type: type.SET_GET_VEHICLE_LIST_LOADING, payload: false });
        
        if( response && response.success   === true ){
            let __data = response.data && response.data[0] && response.data[0].records;
            yield put({type: type.SET_GET_VEHICLE_LIST, payload: __data });

        } else {
            yield put({type: type.SET_GET_VEHICLE_LIST, payload: null   });
        }


       } catch (error) {
        yield put({type: type.SET_GET_VEHICLE_LIST_LOADING, payload: false });
        yield put({type: type.SET_GET_VEHICLE_LIST, payload: false   });
       }
}

export default function* watchInit() {
    yield takeLatest(type.DO_GET_VEHICLE_LIST, doInit);
}
