import { put, takeLatest } from "redux-saga/effects";
import type from '../../actionsType';
import cookies from '../../../services/cookies';
import  history  from '../../../config/history';
function* doLogoutWatch(action){
    try {
            cookies.remove();
            yield put({type: type.SET_USER_PROFILE_UPADTED, payload:  null });
            yield put({type: type.SET_USER_PROFILE_DATA, payload:  null });
            yield put({type: type.SET_LOGIN_USER, payload:  null });
            yield put({type: type.SET_LOGIN_ALERT_MESSAGE, payload:  null });
            yield put({type: type.SET_DO_LOGIN_LOADING, payload:  false });
            yield put({type: type.SET_SNAKBAR_INFO, payload:  { type: 'info', message: "You have successfully logged out of your account"}});
            history.push('/');

       } catch (error) {
           console.error(error);
       }
}

export default function* watchDoLogout() {
    yield takeLatest(type.DO_LOGOUT_USER, doLogoutWatch);
}
