import type from '../actionsType';

const defaultState = {
    isLoading : false,
    addedVehicle: false,
    vehicleList: null,
    vehicleListLoading: false,
    vehicleUpdateLoading: false,
    updateVehicle: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_ADDED_VEHICLE_DATA:
            return { 
                ...state, 
                addedVehicle: action.payload
            }
        case type.SET_GET_VEHICLE_LIST:
            return { 
                ...state, 
                vehicleList: action.payload
            }
        case type.SET_UPDATEED_VEHICLE_INFO:
            return { 
                ...state, 
                updateVehicle: action.payload
            }

        case type.SET_ADDED_VEHICLE_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        case type.SET_UPDATE_VEHICLE_LOADING:
            return { 
                ...state, 
                vehicleUpdateLoading: action.payload
        }
        case type.SET_GET_VEHICLE_LIST_LOADING:
            return { 
                ...state, 
                vehicleListLoading: action.payload
            }
        default :
        return state;
    }
}