import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onBreadcrumbButton, doGetCustomerDetails,onResetAddInvoice, onResetInvoiceData, onResetUpdateVehicle, doResetUserUpdate, doDeleteVehicle, onResetAddedVehicle } from '../../../stores/actions';
import { ROUTER_INFO } from '../../routing/routerList';
import { ViewCustomerCard, VehicleCard ,AlertDialog} from './components';
import { AddVeicleDaiglog , UpdateVeicleDaiglog} from '../Vehicles'
import  AddInvoiceDaiglog  from '../add-invoice/add-invoice';
import { withRouter } from "react-router";
import { Loading } from '../../shared/loading';
import  UpdateDetailForm  from '../../../components/user-profile/components/user-basic-details/updatedetails';
import './style.scss';


class ViewCustomerDetails extends  Component {
  constructor(props) {
    super( props );
    this.state = {
      customer: null,
      loading: false,
      isOpen : false,
      isOpenInvoice: false,
      isOpenAddedVehicle: false, 
      isOpenAddedInvoice: false,
      isOpenUpdateVehicle: false,
      isDelete: false,
      isUpdateCustomer: false,
      isCustomerUpdated: false,
      isUpdatedVehicle: false,
      currentUserVehicle : null
    };

    this.onToggleVehicle = this.onToggleVehicle.bind(this);
    this.onToggleAddInvoice = this.onToggleAddInvoice.bind(this);
    this.onViewInvoice = this.onViewInvoice.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onOpenUpdateVehicle = this.onOpenUpdateVehicle.bind(this);
    this.onHandleClose = this.onHandleClose.bind(this);
    this.onDeleteVehicle = this.onDeleteVehicle.bind(this);
    this.onRemoveVehicle = this.onRemoveVehicle.bind(this);
    this.onUpdateDetails = this.onUpdateDetails.bind(this);
    this.onCustomerClose = this.onCustomerClose.bind(this);
  }

  onUpdateDetails() {
    this.props.doResetUserUpdate();
    this.setState({isUpdateCustomer: true});
  }
  onCustomerClose() {
    this.setState({isUpdateCustomer: false});
  }
  onHandleClose() {
    this.setState({isDelete: false})
  }
  onDeleteVehicle() {
    let { isDelete, customer} = this.state;
    if( isDelete) {
      let { userVehicles} = customer;
      userVehicles.splice(isDelete.index, 1 );
      this.setState({customer,isDelete: false });
      this.props.doDeleteVehicle({mobile:customer.mobile, customerId: isDelete.customerId, vehicleId:isDelete.vehicleId})
    }
    
  }
  onRemoveVehicle(index) {
    let { userVehicles} = this.state.customer;
    let req ={index, mobile:this.state.customer.mobile, customerId: this.state.customer._id , vehicleId: userVehicles[index]._id};
    this.setState({isDelete: req})
  }

  onEdit(index) {
    console.log(index, "index")
    let { customer } = this.state;
    let currentUserVehicle = customer && customer.userVehicles && customer.userVehicles[index];
    this.props.onResetUpdateVehicle();
    this.setState({isOpenUpdateVehicle: true, currentUserVehicle});
  }
  onOpenUpdateVehicle() {
    this.setState({isOpenUpdateVehicle: false});
  }

  onViewInvoice(id, mobile) {
    this.props.history.push(`/view-invoice/${id}/${mobile}`);
  }

  componentDidMount(){
    this.props.onBreadcrumbButton(ROUTER_INFO.customers);
    let { match } = this.props;

    if( match && match.params) {
      this.props.doGetCustomerDetails({mobile: match.params.id});
    }
  }
  
  onToggleVehicle() {
    let { isOpen } = this.state;
    this.setState({ isOpen: !isOpen});
    this.props.onResetAddedVehicle();
    
  }

  onToggleAddInvoice() {
    this.props.onResetInvoiceData();
    let { isOpenInvoice } = this.state;
    if( isOpenInvoice === true ) {
      // send reset common 
    }
    this.setState({ isOpenInvoice: !isOpenInvoice});
  }

  componentWillUnmount() {
    this.props.onBreadcrumbButton(null);
  }

  static getDerivedStateFromProps = ( props) => ({...props});

  render () {
    let { customer, currentUserVehicle,  isUpdatedVehicle, isCustomerUpdated, isUpdateCustomer,loading, isDelete,isOpen, isOpenUpdateVehicle,  isOpenInvoice , isOpenAddedVehicle, isOpenAddedInvoice } = this.state;
    
    if( customer  ) {
     customer.page ='detail';
    }

    if( isOpenAddedVehicle ) {
      isOpen = false;
    }

    if( isOpenAddedInvoice ) {
      isOpenInvoice = false;
    }
    // console.log(customer, "customer");
    let autoFill = {};
    if(customer && Object.keys(customer).length ) {
        Object.assign(autoFill, customer);
        delete autoFill.userVehicles;
        // console.log(autoFill, "autoFill");
    }
    if( isCustomerUpdated ) {
      isUpdateCustomer = false;
    }
    if( isUpdatedVehicle) {
      isOpenUpdateVehicle = false;
    }
  
    
   
    return( <>
      { (Boolean(isUpdateCustomer)) && <UpdateDetailForm autoFill ={ autoFill } title = { `Update Details`} isOpen = { isUpdateCustomer }  onClose = {this.onCustomerClose}/>}
      { Boolean(isDelete) && <AlertDialog  open = { Boolean(isDelete) } handleClose ={ this.onHandleClose}  doYes = { this.onDeleteVehicle} />}
      { customer && <UpdateVeicleDaiglog customer = {customer} currentUserVehicle = { currentUserVehicle } isOpen = { isOpenUpdateVehicle }  onClose = { this.onOpenUpdateVehicle } />}
      { customer && <AddVeicleDaiglog customer = { customer } isOpen = { isOpen }  onClose = { this.onToggleVehicle } />}
      { customer && customer._id && <AddInvoiceDaiglog customer ={customer} isOpen = { isOpenInvoice }  onClose = { this.onToggleAddInvoice } />}
      { loading && <Loading /> }
      { !loading && <>
      { !customer && <p>No Detail found!</p>}

        {customer && <> 
          <ViewCustomerCard onUpdateDetails ={ this.onUpdateDetails } onViewInvoice ={ this.onViewInvoice } data = { customer } onAddVehicle = { this.onToggleVehicle } onAddInvoice = { this.onToggleAddInvoice } />
          { (customer && customer.userVehicles )&& <h3 className="heading-with-line"> <span> Vehicles </span></h3>}
          { customer && Array.isArray(customer.userVehicles) && customer.userVehicles.map((e,i) => (<VehicleCard  key ={i} row ={i} onRemoveVehicle ={this.onRemoveVehicle }  data = { e } onEdit = { this.onEdit} />))}
          { (customer && customer.userVehicles && customer.userVehicles.length ===0) ? 'No Vehicle added yet!' : ''}
        </>}
      </>}
    </>)
  }
}

const mapToStateProps =  state => {
  return {
    isOpenAddedInvoice:  state.addInvoice.isInvoice,
    isCustomerUpdated: state.userProfile.isUpdated ,
    customer: state.viewCustomer.customerDetails,
    loading: state.viewCustomer.isLoading,
    isOpenAddedVehicle: state.addVehicle.addedVehicle,
    isUpdatedVehicle: state.addVehicle.updateVehicle
  }
}
export default connect(mapToStateProps, 
    { 
      onBreadcrumbButton, 
      doGetCustomerDetails, 
      doResetUserUpdate, 
      doDeleteVehicle, 
      onResetAddInvoice, 
      onResetAddedVehicle ,
      onResetUpdateVehicle,
      onResetInvoiceData
    })(withRouter(ViewCustomerDetails));
