import type from '../actionsType';
import cookies from '../../services/cookies';

const defaultState = {
    loginUser : cookies.get(),
    isLoading : false,
    alert: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_LOGIN_USER:
            return { 
                ...state, 
                loginUser: action.payload
            }
        case type.SET_LOGIN_ALERT_MESSAGE:
            return { 
                ...state, 
                alert: action.payload
            }
        case type.SET_DO_LOGIN_LOADING:
            return { 
                ...state, 
                isLoading: action.payload
            }
        default :
        return state;
    }
}