export const abouts =[
    {
        image1: 'images/gallery/about-1.png',
        heading: "Robert Nurse I",
        text: ['Robert Nurse I settled in Fowlers Corners, Ontario and opened a black smith shop in 1890. He had two sons; Smithson and Ken. As the horse and carriage was replaced with the automobile, Robert evolved his business to an aftermarket garage.'],
        image2: 'images/gallery/about-2.jpg',
    },
    {
        image1: 'images/gallery/about-3.jpg',
        heading: "Smithson Nurse",
        style: { backgroundPositionY:'-100px'},
        text: ['When Ken was old enough to take over the family business, Smithson ventured out on his own opening his own aftermarket general repair garage down the road in Omemee, Ontario. After he was burned out in 1929 he rebuild a new garage out of concrete blocks.'],
        image2: 'images/gallery/about-4.jpg',
    },
    {
        image1: 'images/gallery/about-5.jpg',
        heading: "Robert Nurse II",
        isParagrap : true,
        text: [
            'Smithson had one daughter and two sons. One of Smithson’s sons; Bill, opened up Nurse Chev Cadillac in Whitby ON, and the other Robert, continued the Nurse name in the aftermarket sector of the automotive industry. Robert Nurse II took the family business to Peterborough, Ontario opening Bob Nurse Motors as a service station including pumps',
            "As for Smithson's brother Ken, he had two daughters which neither had any interest of continuing in the family business. Fowlers Corners is now home to a self-serve gas station and coffee shop."
    ],
        image2: 'images/gallery/about-6.jpg',
    },
    {
        image1: 'images/gallery/about-7.jpg',
        heading: "Robert Nurse III",
        isParagrap : true,
        text: ["Robert Nurse III opened Nurse's Emission Testing & Repair Facility Inc. within his father business, Bob Nurse Motors in 2002. In 2007 he took over all facets of Bob Nurse Motors.",
        'Robert (middle in picture) and brother-in-law, Peter Siddall (far rt. in picture) started their careers in the eighties working on carbureted fuel systems, and point condenser distributor ignition systems. Both Mechanics evolved into Automotive Technicians with the evolution of fuel injection and distributor-less ignition systems in the nineties.',
        "The new millennium brought another transition with mandatory provincial regulated emission testing and repairs. Since the year 2000 we have endured enhanced electronic computer engine management systems, along with manufacture reflashing and reprogramming of vehicle's control modules.",
        'Throughout our careers we have seen advancements in anti-lock brake systems, electronic suspension and steering, supplemental restraint systems, variable valve timing, and gasoline direct injection just to name a few items we continually have to be trained on.',
        'In our horizon we are seeing hybrid vehicles (HV), full electric vehicles (EVs), Automatic Drivability Assist Systems (ADAS), and the unimaginable fully autonomous vehicle approaching quickly.',
    ],
        image2: 'images/gallery/about-8.jpg',
    },
   
]