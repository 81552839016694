import type from '../actionsType';

const defaultState = {
    linkButton : null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_BREADCRUMB_LINK_BUTTON:
            return { 
                ...state, 
                linkButton: action.payload
            }
        default :
        return state;
    }
}