import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Row from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { Logo } from '../../../components/shared/header/helpers';
import LoginInfo from './loginInfo';

class Header extends  Component {
  constructor(props){
    super( props );
    this.state = {
      loginUser: null
    };
  }
  
  /* Check props and update state */
  static getDerivedStateFromProps = ( props) => ({...props});

  render(){
    const { toggleSidebar } = this.props;
    const { loginUser } = this.state;
   
    return(
        <AppBar position="fixed" >
          <Toolbar>
          <Row container direction="row" justify="space-between" alignItems="center">
            <Row item>
              <Row spacing ={1} container direction="row" justify="flex-start" alignItems="center">
                  <Row item> <Logo url ={'/'}/> </Row>
                  <Hidden smUp implementation="css">
                    <Row item>
                      <IconButton color="inherit" aria-label="open drawer"  onClick = { toggleSidebar } > <MenuIcon /> </IconButton>
                    </Row>
                  </Hidden>
              </Row>
            </Row>
            <Row item>
              <LoginInfo user = {loginUser}/>
            </Row>
          </Row>
          
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps= (state) => {
  return { loginUser: state.loginUser.loginUser}
}
export default connect(mapStateToProps)(Header);
