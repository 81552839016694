import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Row from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { StayPrimaryPortrait, Call , Mail, Person, PinDrop } from '@material-ui/icons';

export const CustomerCard = (props) => {
    let { data } = props;
    // let createdAt = moment(data.createdAt).format('MM-DD-YYYY');
    return (<Card className ="customer-card rcpa-card">
    {/* <span className ="tag-right">Member since : {createdAt} </span> */}
    {/* {data && data.customerType && <span className ="tag-right type-2">Customer Type:  { getCustomerTypeByValue(data.customerType) }</span>} */}
    <CardContent >
        <h1>CUSTOMER DETAILS </h1>
      <Row container  direction="row" spacing = { 4 } justify="flex-start"  alignItems="stretch">
      <Row xs item className ="text-center">
            <Avatar src="./images/user.png" />
            <span className= "name"> {data.name ? `${data.name.firstName} ${data.name.lastName}`: 'N/A'} </span>
            <span className= "name"> {data.company } </span>
            <small> <PinDrop/>
            { data.address ? `${data.address.street}, ${data.address.city} ${data.address.state}, ${data.address.pincode} `:'N/A'}
            </small>
        </Row>

        <Row item xs>
          <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
            <Row item> <b> Contact Details </b> </Row>
            { (data.otherNumber || data.phoneNumber) && <Row item> <StayPrimaryPortrait/> {data.otherNumber || data.phoneNumber}</Row>}
            <Row item> <Call/> {data.mobile} </Row>
            <Row item> <Mail/> {data.email} </Row>
          </Row>
        </Row>

        <Row item xs>
          <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
            <Row item> <b> Login Details </b> </Row>
            <Row item> <Person/> {data.mobile} </Row>
            <Row item> <Mail/> {data.email} </Row>
            <Row item> <span className = { data.status === 'active' ?'active' : 'active error' } > Active </span> </Row>
            
          </Row>
        </Row>
      </Row>
    </CardContent>
  </Card>)
}
