import React, { Component } from 'react';
import './banner.scss';

class Banner extends  Component {
  render() {
    let { data } = this.props;

    return(
        <div className ="banner" style = {{backgroundImage: `url(${data.image}`}}>
          <div className ="overlay"></div>
          <div className ="container"> <h1>{data.text}</h1> </div>
        </div>
    )
  }
}

export default Banner ;
