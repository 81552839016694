import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../../services';
import type from '../../../actionsType';
// import  { orderBy } from 'lodash';

export const CallAPI  = (params) => {
    return apiService.makeRequest({
        method: 'GET',
        url: 'invoice/get-oil-count',
        params: { customerId: params.customerId }
    });
}

// SET_INVOICE_DATA
function* doInit(action) {
   
    yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: true });
    try {
        let response = yield call(CallAPI, action.payload);
        yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: false });

        if( response && response.success  === true ) {
            let _data = response.data;
            yield put({type: type.SET_OIL_CHANGE, payload: _data});
        }
        
       } catch (error) {
        yield put({type: type.SET_GET_INVOICE_DATA_LOADING, payload: false });
        yield put({type: type.SET_INVOICE_DATA, payload: null   });
       }
}

export default function* watchG() {
    yield takeLatest(type.DO_GET_OIL_CHANGE, doInit);
}
