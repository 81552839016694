import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import loginUser from './loginReducer';
import signupUser from './signupReducer';
import breadcrumb from './breadcrumbReducer';
import userProfile from './userProfileReducer';
import snakbar from './snakbarReducer';
import resetPassword from './resetpasswordReducer';
import addCustomer from './addCustomer';
import viewCustomer from './viewCustomer';
import addVehicle from './addVehicle';
import addInvoice from './invoiceReducer';

export default combineReducers({
    form: formReducer,
    snakbar,
    resetPassword,
    loginUser,
    signupUser,
    breadcrumb,
    userProfile,
    addCustomer,
    viewCustomer,
    addVehicle,
    addInvoice
});