import moment from 'moment-timezone/builds/moment-timezone-with-data';

export  const vehicleModifyRequest = (e) => {

    return {
        plateNumber : e.plateNumber,
        labRate: e.labRate,
        address: {
            city :e.city.value , state: e.province.value
        },
        attrs: {
            year :e.year.value,
            make : e.make,
            body :e.body,
            color : e.color,
            engine :e.engine,
            tarns : e.tarns,
            model : e.model,
            ac : e.ac
        },
        odometer: e.odometer,
        vin: e.vin,
        unit: e.unit,
        driver: e.driver,
        notes:e.notes

    };
}

export const modifyVehicleReq = data => {
    return data.map( e=>vehicleModifyRequest(e));
}


export const modifyCustomerRequest = ( data) => {
    
    let req =  {
        name: { 
            firstName :data.firstName, 
            lastName: data.lastName ? data.lastName  : ''
        },
        mobile: data.mobile,
        otherNumber:data.otherNumber,
        phoneNumber:data.phoneNumber,
        company: data.company,
        email: data.email,
        address: {
            street: data.street, 
            city: data.city.label,
            state: data.state.label,
            pincode: data.pincode
        },
        customerType: "7",
        customerId: data._id
    };
    return req;
}

export const modifyRequest = (data) => {
   let vehicles = modifyVehicleReq(data.vehicles);
   let req = {
       name: { firstName :data.firstName, lastName: data.lastName ? data.lastName  : ''},
       mobile: data.mobileNumber,
       phoneNumber: data.mobile,
       otherNumber:data.otherNumber,
       company: data.companyName,
       email: data.email,
       address: {
           street: data.street, 
           city: data.city.value,
           state: data.state.value,
           pincode: data.postalCode
       },
       customerType: "0",
       vehicles
   }
   
   return req;
}

export const modifyAddInvoiceRequest = (data) => {
    let hasOilChanged = true;
    if( data.hasOilChanged.value === '0' ) {
        hasOilChanged = false;
    }
    return  {
        userId:data.userId,
        vehicleId: data.vehicle._id,
        description: data.description,
        hasOilChanged ,
        invoiceId: data.invoiceId,
        invoiceDate:moment( data.invoiceDate).format('YYYY-MM-DD'),
        attrs: {
            mileage: data.mileage
        },
        amount: {
            cost: +data.cost,
            discount: +data.discount,
            oilChangeAmount: + (data.oilChangeAmount) || 0

        }
    }
}