import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export const RCPAReport = (props) => {
    let { data , title, total} = props;
    return (<div className ="customer-card rcpa-card-table">
        <h1> { title} </h1>
            <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell> Years</TableCell>
                                <TableCell align="right"> Total Purchase BNM </TableCell>
                                <TableCell align="right"> BNM RCPA </TableCell>
                            </TableRow>
                            </TableHead>

                            <TableBody>
                                { data && data.map( (e, i) => (<TableRow key = { i }>
                                    <TableCell>{e.year}</TableCell>
                                    <TableCell align="right"> ${e.bnm.toFixed(2)}</TableCell>
                                    <TableCell align="right"> {e.RCPA ? `$${e.RCPA}`  : '-'} </TableCell>
                                </TableRow>))}

                                <TableRow className ="table-footer">
                                    <TableCell> Total: </TableCell>
                                    <TableCell align="right">${total.toFixed(2)}</TableCell>
                                    {/* <TableCell align="right">${totalRCPA} </TableCell> */}
                                    <TableCell align="right"> &nbsp; </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer>
                                          
  </div>)
}
