import React, { Component } from 'react';
import { connect}  from 'react-redux';
import Row from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { Menu, AccountCircle } from '@material-ui/icons';
import { Link } from "react-router-dom";
import { Logo } from './helpers';
import { MenuItems } from '../../../helpers';
import MobileMenu from './mobileMenu';
import  LoginInfo  from './loginUser';
import './header.scss';

class Header extends  Component {
    constructor( props) {
        super(props);
        this.state ={
            isOpen: false,
            isLoading: false,
            loginUser: null
        }
        this.isOpenMenu = this.isOpenMenu.bind(this);
        this.onCloseMenu = this.onCloseMenu.bind(this);
    }

    isOpenMenu() {
        this.setState( { isOpen : true});
    }

    onCloseMenu() {
        this.setState( { isOpen : false });
    }
    static getDerivedStateFromProps( props) {
      return {...props};
    }

    scrollToTop() {
        window.scrollTo(0,0)
    }
    render() {
      let { isOpen, isLoading, loginUser } = this.state;
 
    return(
        <>
        { isOpen && <MobileMenu isOpen = { isOpen}  onCloseMenu = { this.onCloseMenu } /> }
        <AppBar position="fixed" className ="header">
            <div  className ="container">
                <Toolbar variant="dense">
                    <Row container direction="row" justify="space-between" alignItems="center">
                        {/* left section */}
                        <Row item>
                            <Row  container direction = "row" justify="space-between" alignItems="center">
                                <Row item> <Logo url ={'/'}/></Row>
                                <Hidden mdUp>
                                    <Row item>
                                        {/* Show only mobile */}
                                        <IconButton onClick ={()=> this.isOpenMenu() } className ="space-left" edge="start" color="inherit" aria-label="menu"> <Menu /></IconButton>
                                    </Row>
                                </Hidden>
                            </Row>
                        </Row>
                    
                        {/* Right Section */}
                        
                        <Row item >
                            <Row spacing = { 1 } container  direction="row" justify="flex-end" alignItems="center">
                                <Row item>
                                    <Hidden smDown>
                                        <Row spacing = { 1 } container  direction="row" justify="flex-end" alignItems="center">
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.home.url } component = { Link }> { MenuItems.home.text} </Button>
                                            </Row>
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.about.url } component = { Link }> { MenuItems.about.text} </Button>
                                            </Row>
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.photoGallery.url } component = { Link }> { MenuItems.photoGallery.text} </Button>
                                            </Row>
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.services.url } component = { Link }> { MenuItems.services.text} </Button>
                                            </Row>
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.ourHistory.url } component = { Link }> { MenuItems.ourHistory.text} </Button>
                                            </Row>
                                            <Row item >
                                                <Button onClick={() => this.scrollToTop()} to = { MenuItems.contacts.url } component = { Link }> { MenuItems.contacts.text} </Button>
                                            </Row>
                                        </Row>
                                    </Hidden>
                                </Row>
                                
                                <Row item>
                                { isLoading && 'Loading..'}
                                { !isLoading && <>
                                <div className='eft-border'>
                                    <a href='https://autohound.shop'>
                                        <img src="images/login-logo-1.png" alt ="login" className='login-image'/>
                                    </a>
                                    </div>
                                    {/* { loginUser ? <LoginInfo user = {loginUser}/> : 
                                    <Button className ="left-border" to = {`/${MenuItems.login.url}` } component = { Link }> 
                                        <AccountCircle/> &nbsp; { MenuItems.login.text}
                                    </Button>
                                    } */}
                                </>}
                                    
                                </Row>
                            </Row>
                            
                        </Row>
                    </Row>
                </Toolbar>
            </div>
        </AppBar>
    </>)
  }
}
const mapStateToProps = state => {
    
    return ({
    loginUser : state.loginUser.loginUser,
    isLoding: state.loginUser.isLoding
})}
export default connect(mapStateToProps)(Header) ;
