import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {  find, orderBy } from 'lodash';
import { first_month, last_month, _MONTHS } from './rcpaTable';

export const fillYears = ( data) => {
    if( data) {
    let startEle = data[0]._id;
    let endEle = data[data.length -1]._id;
    let start , end, Result =[];
        if( startEle > endEle) {
            start = endEle;
            end = startEle;
        } else {
            start = startEle;
            end = endEle;
        }
    console.log(start, end);
    for ( let i = start ; i<= end; i++) {
        let _find = find(data, function(o) { return o._id === i; });
        if( _find) {
            Result.push(_find);
        } else {
            Result.push({_id: i, records:[]});
        }
    }
    // Result =orderBy(Result, ['_id'], 'desc');
    // console.log(Result, "Result");
    return Result;
    }
}
const calRCPA = (data) => {
    let avg = 0, total_sum =0;
    
    return data.map( (e, i) => {

        if( e.start_month_info){
            avg = e.RCPAValue;
        } else {
            avg = avg + 1
        }
        
        if( e.bnm ) {
            total_sum = total_sum + e.bnm;
        }
        
        let obj = { ...e };
        /* only center */
        if( !e.start_month_info ){
            obj.RCPA = total_sum / avg;
            obj.RCPA = obj.RCPA.toFixed(3);
        } 
        /* last */
        if( e.invoice_end_month){

        }
        return obj;
    });
}
const calFinalRCPA = (data) => {
    let avg = 0, total_sum =0;
    return data.map( (e, i) => {

        if( e.start_month_info ){
            avg = e.RCPAValue;
        } 
        else if (e.last_month_info) {
            avg = e.RCPAValue;
        }
        else {
            avg = avg + 1
        }
        
        if( e.bnm ) {
            total_sum = total_sum + e.bnm;
        }
        
        let obj = { ...e };
        // let prevIndex = i - 1;
        /* only center */
        // && !e.last_month_info
        if( !e.start_month_info ){
            obj.RCPA = total_sum / avg;
            obj.RCPA = obj.RCPA.toFixed(3);
        } 
        /* last */
        if( e.last_month_info){
            let start = data[0];
            let final = (data.length - 2 ) + avg + start.RCPAValue;
            // console.log(final, "final", total_sum, "start.RCPAValue", start.RCPAValue);
           let F_RCPA = total_sum/ final
           obj.RCPA = F_RCPA.toFixed(3);

        }
        // console.log("list");
        // if( !e ) {
        //     console.log( "not first");
        // }
        // avg = 0;
        // total_sum = 0;
        // console.log(obj, "ajsbdasjdasdas---kasjbdasd asbdas sjkabas jkbaas jkbajsd jkbas jkbas jkbas jkbas ")
        return obj;
    });
}
// const calRCPA = (data) => {
//     let avg = 0, total_sum =0;
    
//     return data.map( (e, i) => {

//         if( e.start_month_info){
//             avg = e.RCPAValue;
//         } else {
//             avg = avg + 1
//         }
        
//         if( e.bnm ) {
//             total_sum = total_sum + e.bnm;
//         }
        
//         let obj = { ...e };
//         // console.log(e.invoice_start_month,e.invoice_end_month );
//         // let prevIndex = i - 1;
//         /* only center */
//         // && !e.last_month_info
//         if( !e.start_month_info ){
//             console.log(total_sum, "fff", avg);
//             obj.RCPA = total_sum / avg;
//             obj.RCPA = obj.RCPA.toFixed(3);
//         } 
//         /* last */
//         if( e.invoice_end_month){

//         }
//         // console.log("list");
//         // if( !e ) {
//         //     console.log( "not first");
//         // }
//         // avg = 0;
//         // total_sum = 0;
//         // console.log(obj, "ajsbdasjdasdas---kasjbdasd asbdas sjkabas jkbaas jkbajsd jkbas jkbas jkbas jkbas ")
//         return obj;
//     });
// }

export const getRCPA = ( data, final) => {
    
    if( data && data.length > 0 ) {
        let result = [];
        data.forEach(e => {
            
            let obj = { year: e._id };
            let sum = 0;
            let months = [];
            if( e.records) {
                e.records.forEach( (el, index) => {
                    sum = sum + el.amount.total;
                    obj.invoiceDate = moment(el.invoiceDate).format('DD-MM-YYYY');
                    months.push(+(moment(el.invoiceDate).format('MM')));
                    obj.amount =  el.amount;
                    obj.months = months;
                    obj.invoice_start_month = months[0];
                    if( months.length ===0){
                        obj.invoice_end_month = months[0];
                    }else {
                        obj.invoice_end_month = months[months.length -1 ];
                    }
                    
                    
                    obj.invoiceId =  el.invoiceId;
                });
            }
            obj.bnm = sum;
            result.push( obj);
        });
        /* sort array */
        // result = sortBy(result, [function(o) { return o.year; }]);
        
        /* first elemnt of array  */
        let firstIndex = 0;
        let first = result[firstIndex];
        let i = first.invoice_start_month >= 0  ? first.invoice_start_month - 1  :first.invoice_start_month;
        first.start_month_info = `Start Month ${_MONTHS[i ]} RCPA inital Value ${first_month[i]}`;
        first.RCPAValue = first_month[i];
        /* update */
        result.splice(firstIndex,1, first);
        /* Last element of array */
        let lastIndex =  result.length -1;
        let last = result[ lastIndex];
        let _i = first.invoice_end_month >= 0  ? last.invoice_end_month - 1  :last.invoice_end_month;
        last.last_month_info = `End Month ${_MONTHS[_i ]} RCPA last Value ${last_month[_i]}`
        last.RCPAValue = last_month[_i];
        result.splice(lastIndex,1, last);
        let RCPA_RESULT ;
        if( final ) {
            RCPA_RESULT = calFinalRCPA(result);
        } else {
            RCPA_RESULT = calRCPA(result);
        }

        // console.log(RCPA)
        return  RCPA_RESULT;
    }
}

export const getRCPAResult = ( data )  => {
    let RCPA = getRCPA(data);
    let total = 0, totalRCPA = 0, title ='RCPA SUMMARY'; 
    if( RCPA) { 
      RCPA.forEach( (e, i) => {
        total = total + e.bnm;
        if( e.RCPA ){
          totalRCPA = totalRCPA + +(e.RCPA);
        }
      });
    }
    totalRCPA = totalRCPA.toFixed(3);
    return { RCPA, total, totalRCPA, title}
}

export const getFinalRCPAResult = ( data )  => {
    let RCPA = getRCPA(data, true);
    let total = 0, totalRCPA = 0, title ='FINAL RCPA SUMMARY'; 
    if( RCPA) { 
      RCPA.forEach( (e, i) => {
        total = total + e.bnm;
        if( e.RCPA ){
          totalRCPA = totalRCPA + +(e.RCPA);
        }
      });
    }
    totalRCPA = totalRCPA.toFixed(3);
    return { RCPA, total, totalRCPA, title}
}

export const Total = (data)=> {
    let sum =0;
    if( data ) {
        data.forEach( e=> {
            sum = sum + e.amount.total;
        });
    }
    return sum;
}
export const TotalDiscount = (data)=> {
    let sum =0;
    if( data ) {
        data.forEach( e=> {
            if( e.amount.discount ) {
                sum = sum + e.amount.discount;
            }
        });
    }
    return sum;
}
export const TotalFreeOil = (data)=> {
    let sum =0;
    if( data ) {
        data.forEach( e=> {
            if( e.amount.oilChangeAmount ) {
                sum = sum + e.amount.oilChangeAmount;
            }
        });
    }
    return sum;
}