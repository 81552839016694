import {  Cookies } from 'react-cookie';
const cookies = new Cookies();
const prefix = 'loginUser';

export default class CookiesServices {
    
    constructor() {
        this.prefix = 'loginUser';
    }   

    static set(user){
        if( user && Object.keys(user).length) {
            cookies.set(prefix, user);
        }
        
    }
    static get(){
        let data = cookies.get(prefix);
        if( data) {
            return data || null;
        }
    }

    static remove() {
        cookies.remove(prefix);
    }
}