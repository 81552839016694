import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
// import history from '../../config/history';
import type from '../../actionsType';
// import cookies from '../../services/cookies';

export  const callConfirmOtp  = (request) => {
    return apiService.makeRequest({
        method: 'POST',
        url:'users/verify',
        data: request
    });
}

function* confirmOtp(action){
    yield put({type: type.SET_SIGNUP_LOADING, payload: true });

    try {
        
        let response = yield call(callConfirmOtp, action.payload);
        console.log(response);

        if( response && response.success === false) {
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message:'The OTP entered is incorrect' } });
        }
        else {
            yield put({type: type.SET_IS_CONFIRM_OTP, payload: true });
        }
        yield put({type: type.SET_SIGNUP_LOADING, payload: false });

       } catch (error) {
            yield put({type: type.SET_SIGNUP_LOADING, payload: false });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type: 'error', message: 'Network Error'} });
       }
}

export default function* watchDoConfirmOtp() {
    yield takeLatest(type.DO_CONFIRM_OTP, confirmOtp);
}
