import type from '../actionsType';

const doAddVehicle = (payload) => ({
    type: type.DO_ADD_VEHICLE,
    payload
});
const doDeleteVehicle = (payload) => ({
    type: type.DO_ADD_DELETE_VEHICLE,
    payload
});

const doUpdateVehicle = (payload) => ({
    type: type.DO_UPDATE_VEHICLE,
    payload
});

const doGetVehicleList = (payload) => ({
    type: type.DO_GET_VEHICLE_LIST,
    payload
});

const onResetAddedVehicle = () => ({
    type: type.SET_ADDED_VEHICLE_DATA,
    payload: false
});
const onResetUpdateVehicle = () => ({
    type: type.SET_UPDATEED_VEHICLE_INFO,
    payload: false
});


export {
    doAddVehicle,
    doGetVehicleList,
    doDeleteVehicle,
    onResetAddedVehicle,
    doUpdateVehicle,
    onResetUpdateVehicle
}