import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Term } from '../term-policy/term';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" >
      <Paper {...props} />
    </Draggable>
  );
}

export const  InfoDialog =  props => {
    let { isOpen, onClose , title } = props;
    return (
        <Dialog open={isOpen} onClose={  onClose } PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                { title }
            </DialogTitle>
            <DialogContent> 
                <Term/>
             </DialogContent>
            
            <DialogActions>
                <Button autoFocus onClick={ onClose } color="primary" variant="contained"> Close </Button>
                
            </DialogActions>
        </Dialog>
    )
} 