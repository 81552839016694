import React, { Component } from 'react';
import { connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Link }  from 'react-router-dom';
import { StayPrimaryPortrait, Call , Mail, Person, PinDrop, Commute } from '@material-ui/icons';
// import { getCustomerTypeByValue } from '../../../../data-set/customerType';

class CustomerCard extends  Component {
 
  render(){
    
    const { onActionMenuOpen, data } = this.props;
    
    // let createdAt = moment(data.created_at).format('MMM-DD-YYYY');
    return(
      <Card className ="customer-card">
        {/* <span className ="tag-right">Member since : {createdAt} </span> */}
        {/* <span className ="tag-right type-2">Customer Type: { getCustomerTypeByValue(data.customerType)}</span> */}
        <CardContent>
          <Row container  direction="row" spacing = { 4 } justify="flex-start"  alignItems="stretch">
            <Row item xs ={ 3} className ="text-center">
                <Avatar src="./images/user.png" />
                <span className= "name"> {data.name ? `${data.name.firstName} ${data.name.lastName}`: 'N/A'} </span>
                <span className= "name"> {data.company } </span>
                <small> <PinDrop/>
                { data.address ? `${data.address.street}, ${data.address.city} ${data.address.state}, ${data.address.pincode} `:'N/A'}
                  
                  </small>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Contact Details </b> </Row>
                <Row item> <StayPrimaryPortrait/> { data.mobile } </Row>
                { data.phoneNumber && <Row item> <Call/> {data.phoneNumber} </Row> }
                { data.otherNumber  && <Row item> <Call/> {data.otherNumber} </Row>}
                <Row item> <Mail/> { data.email }  </Row>
              </Row>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing ={ 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Login Details </b> </Row>
                <Row item> <Person/> { data.mobile } </Row>
                <Row item> <Mail/> {data.email} </Row>
                <Row item> <span className = {data.status=== "active" ? 'active': 'active error'}> {data.status} </span> </Row>
                
              </Row>
            </Row>

            <Row item xs>
              <Row container  direction="column" spacing = { 1 }  justify="center"  alignItems="flex-start">
                <Row item> <b> Vehicle Details ( {data.userVehicles  && data.userVehicles.length} ) </b> </Row>
                  {data.userVehicles && data.userVehicles.length && data.userVehicles.map( (e,i) => (<Row item key ={i}>  
                  {  e.attrs && e.attrs.make && <span><Commute/> {e.attrs.make} </span>} {e.attrs && e.attrs.model && <span> - {e.attrs.model}  </span>}
                  </Row>))}
              </Row>
            </Row>

            <Row item  className ="action-button">
              <Row container  direction="column" spacing = { 1 }  justify="center"  alignItems="flex-start" >
                {/* <Row item> <b> Actions </b> </Row> */}
                <Row item> <Button  size="small"  onClick ={(e)=> onActionMenuOpen(e, data) } variant="contained" color="primary" > Actions </Button></Row>
                <Row item> <Button  size="small"  to = { `view-RCPA/${data.mobile}/${data._id}` } component ={ Link } variant="contained" color="primary" > RCPA Summary </Button></Row>
                <Row item> <Button  size="small"  to = { `view-customer/${data.mobile}` } component ={ Link } variant="contained" color="primary" > View Details </Button></Row>
              </Row>
            </Row>

          </Row>


        </CardContent>
      </Card>
    )
  }
}

export default connect(null, null)(CustomerCard);
