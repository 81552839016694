import type from '../actionsType';

const defaultState = {
    sankbar: null
}

export default (state = defaultState, action ) => {
    switch ( action.type) {
        case type.SET_SNAKBAR_INFO:
            return {
                ...action.payload
            }
        default :
        return state;
    }
}