import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {  validation, ReactSelect } from '../../../../formFields';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Row from '@material-ui/core/Grid';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import '../style.scss';

const vehicleList = (list) =>{
    if( list) {
        return list.map( e=> ({...e, label:  (e && e.attrs ? `${e.attrs.make} - ${ e.attrs.model ? e.attrs.model : '' }`: null ), value: e.plateNumber}));
    }
} 

let  ViewFilter = (props) => {
    const { handleSubmit , loading, data, isFinalRCPA } = props;
    let vehicle ;
    if( data && Object.keys(data).length && data.userVehicles) {
        vehicle  = data &&  data.userVehicles;
    }
    return (
    <Card className ="filter-card">
        <CardContent >
        <form onSubmit = { handleSubmit }  noValidate className ="border-box">
        <Row container  direction="row" spacing = { 3 } justify="space-between"  alignItems="center" className ="field">
            <Row item xs = { isFinalRCPA ? 6 :  9}>
                <Field 
                    name="vehicle"  
                    id ="vehicle"
                    validate = {[validation.required]}  
                    component = {ReactSelect} 
                    suggestions = { vehicleList(vehicle) }
                    label =  { props.label } />

            </Row>

            <Row item >
                <Button  size="large" className="loading-button" disabled = { loading }  variant="contained" color="primary" type="submit">
                    { props.buttonLabel}  &nbsp; <ArrowRightAlt/>
                    {/* {loading && <><CircularProgress size={20} /> &nbsp; Processing..</>} */}
                </Button>
            </Row>
            { isFinalRCPA && 
            <Row item >
                <Button  size="large" onClick = { props.onFinalRCPASummary } className="loading-button" disabled = { loading }  variant="contained" color="primary">
                     <> { props.buttonLabel1}  &nbsp; <ArrowRightAlt/> </> 
                </Button>
            </Row>
            }
        </Row>

        </form>
        </CardContent>
  </Card>)
}

ViewFilter = reduxForm({
    form: 'ViewFilter',
  })(ViewFilter);
  
  export default  ViewFilter;

