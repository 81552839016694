import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Row from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { reduxForm } from 'redux-form';
import { doAddInvoice, doSnakbar , doGetOilChange} from '../../../stores/actions';
import AddInvoiceForm from "./addInvoiceForm";

const style = {    
    borderBottom: '1px #c5c5c5 solid',
    paddingBottom: '10px',
    backgroundColor: '#ececec',
    cursor: 'move'
}

function PaperComponent(props) {
  return (
    <Draggable handle="#updateDetail" >
      <Paper {...props} />
    </Draggable>
  );
}

class  addInvoice extends Component {

    constructor(props) {
       super(props);

       this.state = { 
            isLoading: false
        }

       this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        // let { customer} = this.props;
        // console.log("call oil change");
    }
    onSubmit(e) {
        /* Submit invoice form */
        let { customer} = this.props;
        this.props.doAddInvoice({...e, userId: customer._id});
    }

    static getDerivedStateFromProps( props) {
        return {...props};
    }

    render() {

    const { isOpen, onClose , customer } = this.props; 
    let { isLoading} = this.state;
    let vehicle = customer &&  customer.userVehicles;
    let isAddedVehicle =  (vehicle && Array.isArray(vehicle) && vehicle.length ===  0 && isOpen === true) ? true : false;
    if( isOpen === true) {
        this.props.doGetOilChange({customerId: customer._id});
    }
    return (
        <Dialog className ="update-details" open = { isOpen } 
            PaperComponent={ PaperComponent } aria-labelledby="updateDetail">
            
            <DialogTitle style = { style } id = "updateDetail">
                <Row container  direction="row" justify="space-between"  alignItems="center">
                    <Row item >  Generate Invoice for Customer { customer.name.firstName } </Row>
                    <Row item > 
                        <IconButton aria-label="delete" onClick = { onClose } >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Row > 
                </Row>
                    
                </DialogTitle>
                
                { isAddedVehicle && <DialogContent> 
                    You don't have added any vehicle
                </DialogContent>}
                { !isAddedVehicle && <DialogContent> 
                    <AddInvoiceForm customer = {customer} onSubmit = { this.onSubmit } loading = { isLoading } />
                </DialogContent> }
            
        </Dialog>
    )
    }
}

const AddInvoiceDaiglog = reduxForm({
    form: 'addInvoice',
})(addInvoice);

const mapDispatchToProps = { doAddInvoice , doSnakbar, doGetOilChange};

const mapStateToProps= (state) => {
    return({
        isLoading: state.addInvoice.isLoading,
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceDaiglog);
