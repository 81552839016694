import { call, put, takeLatest } from "redux-saga/effects";
import { apiService } from '../../../services';
import history from '../../../config/history';
import type from '../../actionsType';
import cookies from '../../../services/cookies';

export  const doLogin  =(request) => {
    return apiService.makeRequest({
        method: 'POST',
        url:'users/login',
        data: request
    });
}

function* doLoginWatch(action){
    yield put({type: type.SET_DO_LOGIN_LOADING, payload: true });
    yield put({type: type.SET_SNAKBAR_INFO, payload: null});

    try {
       
        let response = yield call(doLogin, action.payload);
        yield put({type: type.SET_DO_LOGIN_LOADING, payload: false });
        if( response && response.success === false) {
            yield put({type: type.SET_LOGIN_ALERT_MESSAGE, payload: { type: 'error', message: 'Invalid username or password'} });
        }
        else {
            let user = response ? response.data: null;

            if( user && user.name) {
                user = {...user, ...user.name}
            }
            yield put({type: type.SET_LOGIN_ALERT_MESSAGE, payload: { message: response.message} });
            cookies.set(user);

            if( user && user.role ==='admin') {
                history.push('/');
            } else {
                history.push('/my-profile');
            }
            
            yield put({type: type.SET_LOGIN_USER, payload:user});
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'success', message: response.message}});
        }


       } catch (error) {
            yield put({type: type.SET_DO_LOGIN_LOADING, payload: false });
            yield put({type: type.SET_SNAKBAR_INFO, payload: { type:'error', message: "Please try again"}});

       }
}

export default function* watchDoLogin() {
    yield takeLatest(type.DO_LOGIN_USER, doLoginWatch);
}