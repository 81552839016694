import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import './styles.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 12,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 11,
    color: "gray",
    fontWeight: 300
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top:45
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps ,touched,error },
  } = props;

  return (
    <FormControl error ={ (touched && error ) ? true : false } className = { "text-field " + ((touched && error ) ? 'error' : '')} fullWidth>
      
    <TextField
      fullWidth
      error = { (touched && error ) ? true : false }
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
     <FormHelperText className={ "error-field " + ((touched && error) ? 'show': 'hide')}> {TextFieldProps.label} is required </FormHelperText>
    
    </FormControl>
  );
}

function Option(props) {
  let _data = props.data;
  return (
    <MenuItem 
      disabled ={ _data.disabled }
      className="suggested"
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      <Typography component="div" noWrap>
      { _data.label } 
      { _data.provinces  &&  <span>( { _data.provinces } ) </span> } 
      </Typography>

      </MenuItem>
  );
}

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography  className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue}  {...props.innerProps}>
      { props.children }
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}  >{props.children}</div>;
}

function Menu(props) {

  return (
    <Paper  square className={props.selectProps.classes.paper + ' dropdrown-list-custom'} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}


const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export default function IntegrationReactSelect(props) {
  const {input, label, id, suggestions ,  meta: { touched, error }} = props;
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };


  return (
        <Select
        maxMenuHeight="100"
          classes={classes}
          touched = {touched}
          error = {error}
          styles={selectStyles}
          inputId={ 'react-select-'+ id }
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-'+ id,
              shrink: true
            },
          }}
          placeholder = { label }
          options={suggestions}
          components={ components }
          value={input.value}
          onChange={(value) => { 
            input.onChange(value);
            // onFilterCity(value);
            // if( input.name === 'vehicleRegNumber') {
            //     onChangeVehicleNo(value);
            // }
          }}
        />
  );
}


Placeholder.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

Menu.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.element.isRequired,
    /**
     * Props to be passed to the menu wrapper.
     */
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

SingleValue.propTypes = {
/**
 * The children to be rendered.
 */
children: PropTypes.node,
/**
 * Props passed to the wrapping element for the group.
 */
// innerProps: PropTypes.any.isRequired,
selectProps: PropTypes.object.isRequired,
};

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
      onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.any.isRequired,
      }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

inputComponent.propTypes = {
inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
    current: PropTypes.any.isRequired,
    }),
]),
};
  

// NoOptionsMessage.propTypes = {
//     /**
//      * The children to be rendered.
//      */
//     children: PropTypes.node,
//     /**
//      * Props to be passed on to the wrapper.
//      */
//     innerProps: PropTypes.object.isRequired,
//     selectProps: PropTypes.object.isRequired,
// };

ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};