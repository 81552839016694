import React, { Component } from 'react';
import Row from '@material-ui/core/Grid';
import { connect}  from 'react-redux';
import { config, MenuItems } from '../../../helpers';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { AccountCircle , ContactMail, LocalPhone, Email} from '@material-ui/icons';
import { SocialIcons } from '../../shared';
import { Link } from "react-router-dom";
import './footer.scss';

class Footer extends  Component {
  constructor( props) {
    super(props);
    this.state ={
        loginUser: null
    }
}
static getDerivedStateFromProps( props) {
  return {...props};
}

  render(){
    const { loginUser} = this.state;
    const d= new Date();
    let currentYear = d.getFullYear();

    const scrollToTop = () => {
      window.scrollTo(0,0)
    }
    return(
        <div  className ="footer">
           <div className ="container">
              <Row container  direction="row"  justify="space-between"  alignItems="flex-start">
                <Row item xs = { 12 } sm= {6} md ={ 4} >
                  <Row  container  direction="column"  justify="flex-start"  alignItems="flex-start">
                    <Row item><Typography variant="h3">Address</Typography></Row>
                    <Row item > { config.address }</Row>
                    <Row item> <Button > <LocalPhone/>&nbsp; {config.phone} </Button></Row>
                    <Row item> <Button className ="lowercase"> <Email/> &nbsp;{config.email}</Button></Row>
                    <Row item><SocialIcons type= {''} /> </Row>
                  </Row>
                </Row>

                <Row item>
                  <Row  container  direction="column"  justify="flex-start"  alignItems="flex-start">
                    <Row item><Typography variant="h3">Quick Links</Typography></Row>
                    <Row item >
                        <Button onClick ={ ()=> scrollToTop()} to = { MenuItems.home.url } component = { Link }> { MenuItems.home.text} </Button>
                    </Row>

                    <Row item >
                        <Button onClick ={ ()=> scrollToTop()} to = { MenuItems.about.url } component = { Link }> { MenuItems.about.text} </Button>
                    </Row>

                    <Row item >
                        <Button onClick ={ ()=> scrollToTop()} to = { MenuItems.services.url } component = { Link }> { MenuItems.services.text} </Button>
                    </Row>

                    <Row item >
                        <Button onClick ={ ()=> scrollToTop()} to = { MenuItems.contacts.url } component = { Link }> { MenuItems.contacts.text} </Button>
                    </Row>

                    <Row item >
                        <Button onClick ={ ()=> scrollToTop()} to = { '/restoration-project' } component = { Link }> 1956 Lincoln  </Button>
                    </Row>
                  </Row>
                </Row>

                <Row item  xs = { 12 } sm= { 6 } md ={ 4 } >
                  <Row  container  direction="column"  justify="flex-start"  alignItems="flex-start">
                    <Row item><Typography variant="h3">Hours of Operation </Typography></Row>
                    <Row item>We are conveniently open from 8:00 am to 5:00 pm <br/>Monday to Friday</Row>
                    { !loginUser && 
                    <Row item> 
                    {/* <Button to ={ '/login'} component = { Link }> <AccountCircle/>&nbsp; Login / Signup </Button> */}
                    <a href='https://autohound.shop'><img src="images/login-logo-1.png" alt ="login" className='login-footer'/></a>

                    </Row>}
                    
                  </Row>
                </Row>
               
              </Row>
             
            </div>
            <div className ="copyright">
                 <i> Copyright&copy; Bob Nurse Motors {currentYear} All rights reserved</i>
              </div>
        </div>
    )
  }
}
const mapStateToProps = state =>({
  loginUser : state.loginUser.loginUser,
})
export default connect(mapStateToProps)(Footer) ;

