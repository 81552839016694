import React, { Component } from 'react';
import { SliderItems, config  } from '../../../helpers';
import Slider from "react-slick";
import { NavigateNext, NavigateBefore , LocalPhone, Email} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Row from '@material-ui/core/Grid';
import  './slider.scss';

const NextArrow = ( props) => {
    const { onClick } = props;
    return (
        <IconButton  className ="next-btn" size="medium" onClick = { onClick }> <NavigateNext fontSize="large"/> </IconButton>
    )
}

const PrevArrow = ( props) => {
    const { onClick } = props;
    return (
        <IconButton  className ="prev-btn"  size="medium" onClick = { onClick }> <NavigateBefore fontSize="large"/> </IconButton>
    )
}
class SlickSlider extends  Component {
  render() {
    let settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed:5000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      };

    return(
        
        <div className ="slider">
            {/* <div className ="overlay"></div> */}
            <div className ="container">
                {/* <div className ="fixed-text">
                    <Row container className ="full-height" direction="row" justify="center"  alignItems="center" >
                        <Row item>
                            <h2>We offer a wide variety of services for all makes and models</h2>
                            <h3>We are conveniently open from </h3>
                            <p> 8:00AM to 5:00PM Monday to Friday </p>
                           
                        </Row>
                    </Row>
                </div> */}

                <div className ="conntact-info">
                    <Row container spacing = { 1 } direction="row" justify="flex-end"  alignItems="center"  className='xs'>
                        { config.email && <Row  sm md item className ="push-right-1"><Button> <Email/>  {config.email}</Button> </Row> }
                        { config.phone && <Row item  sm md ><Button className ="border-left"><LocalPhone/> &nbsp;  {config.phone} &nbsp;</Button> </Row> }

                    </Row>
                </div>
            </div>
            <Slider {...settings}>
                { SliderItems && SliderItems.map( (item ,i) => (
                    <div key = { i }> <img src= { item.image} alt ={ 'alt' + i} /> </div>
                ))}
            </Slider>
        </div>
    )
  }
}

export default SlickSlider ;
